/**
 * @module providers/favourite
 *
 * @description
 * This provider handles favourite items. It implements XDK Core
 * storage class to use the proper local storage for each device.
 *
 * @see https://bitbucket.org/accedo/xdk/src/develop/packages/xdk-core/src/interfaces/Storage.js
 *
 * Feel free to fill these methods with app's logic having in mind
 * to preserve names, params and return types since this service
 * can be used by hooks, high order components, or other React API.
 *
 * Also remove the helper methods to make it clean if it doesn't
 * make sense in your code.
 */

import { device } from '@accedo/xdk-core';

import MediaContent from '#/interfaces/MediaContent';
import { mapToString, stringToMap } from '#/utils/dataConverterHelper';
import buildStorageKey from '#/utils/storageManager';

const FAVORITE_ALIAS = 'vdkctv-favourite';
const STORAGE_STRATEGY = 'local';

/**
 * Helper method to get string values from storage
 * @param {string} profileIdentifier Unique favoriteKey. Identify the user in the storage.
 * @returns {Promise<Map<string, any>>} Promise - A map based on contents and it's contents Ids.
 * * Key = contentId
 * * Value = Content (Ex: Movie, Series, TVShow object)
 */
export const get = async <T extends MediaContent>(profileIdentifier: string): Promise<Map<string, T>> => {
  const { storageManager } = device;
  const storage = await storageManager.getStorage(STORAGE_STRATEGY);
  const userStorageKey: string = buildStorageKey(FAVORITE_ALIAS, profileIdentifier);
  const userFavourites: string = storage.get(userStorageKey);

  return stringToMap(userFavourites);
};

/**
 * Helper method to set a new string into storage
 * @param {string} profileIdentifier Unique favoriteKey. Identify the user in the database.
 * @param {Map<string, any>} favouritesMap A map based on contents and it's contents Ids.
 * * Key = contentId
 * * Value = Content (Ex: Movie, Series, TVShow object)
 * @returns {Promise<void>} Void
 */
export const set = async <T extends MediaContent>(profileIdentifier: string, favouritesMap: Map<string, T>) => {
  const { storageManager } = device;
  const storage = await storageManager.getStorage(STORAGE_STRATEGY);
  const userStorageKey: string = buildStorageKey(FAVORITE_ALIAS, profileIdentifier);
  const stringifiedFavourites: string = mapToString(favouritesMap);
  storage.set(userStorageKey, stringifiedFavourites);
};

/**
 * Helper method to clear the storage
 * @param {string} profileIdentifier Unique favoriteKey. Identify the user in the database.
 * @returns {Promise<void>} Void
 */
export const clear = async (profileIdentifier: string) => {
  const { storageManager } = device;
  const storage = await storageManager.getStorage(STORAGE_STRATEGY);
  const userStorageKey: string = buildStorageKey(FAVORITE_ALIAS, profileIdentifier);
  storage.unset(userStorageKey);
};
