import { CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import jwtDecode from 'jwt-decode';

import { Token } from './Token.enum';
import { CognitoUserPoolDetails } from '../types';

export function expiredToken(): boolean {
  const accessToken = localStorage.getItem(Token.AccessToken);
  if (!accessToken) {
    return true;
  }

  try {
    const decodedToken: { exp: number } = jwtDecode(accessToken);
    const currentTime = Math.floor(Date.now() / 1000);

    return decodedToken.exp < currentTime;
  } catch (error) {
    return true;
  }
}

export async function getRefreshToken({ userpoolId, clientId }: CognitoUserPoolDetails): Promise<void | Error> {
  const poolData = {
    UserPoolId: userpoolId,
    ClientId: clientId,
  };
  const userPool = new CognitoUserPool(poolData);
  const user = userPool.getCurrentUser();

  if (!user) {
    return new Error('No user');
  }

  return new Promise<void>((resolve, reject) => {
    user.getSession((error: Error | null, session: CognitoUserSession) => {
      if (error) {
        reject(new Error(error.name));
      } else if (!session.isValid()) {
        reject(new Error('Invalid session'));
      } else {
        const refreshToken = session.getRefreshToken();

        user.refreshSession(refreshToken, (err: Error | null, newSession: CognitoUserSession) => {
          if (err) {
            return reject(new Error(err.name));
          }
          const newIdToken = newSession.getIdToken().getJwtToken();
          const newAccessToken = newSession.getAccessToken().getJwtToken();
          const newRefreshToken = newSession.getRefreshToken().getToken();

          localStorage.setItem(Token.IdToken, newIdToken);
          localStorage.setItem(Token.AccessToken, newAccessToken);
          localStorage.setItem(Token.RefreshToken, newRefreshToken);

          return resolve();
        });
      }
    });
  });
}
