import algoliasearch from 'algoliasearch';

export default async function search({
  algoliaApplicationId,
  algoliaApiKey,
  query = '',
  genre,
  currentId,
  customQuery,
  genreBasedContentFilter,
  hitsPerPage,
  page,
}) {
  const client = algoliasearch(algoliaApplicationId, algoliaApiKey);
  const index = client.initIndex('collections');

  try {
    if (customQuery) {
      const q = JSON.parse(customQuery);
      const res = await index.search(q.query, {
        filters: q.filters,
        hitsPerPage: q.hitsPerPage,
        page,
      });

      return Promise.resolve(res);
    }

    if (genre || genreBasedContentFilter) {
      const currentIdCheck = currentId ? `AND NOT objectID:"${currentId}"` : '';
      const filters = genre ? `primaryGenre:${genre} ${currentIdCheck}` : genreBasedContentFilter;
      const res = await index.search(query, {
        filters,
        hitsPerPage,
        page,
      });

      return Promise.resolve(res);
    }

    if (query) {
      const res = await index.search(query, { hitsPerPage, page });

      return Promise.resolve(res);
    }

    return Promise.reject(new Error('Field "genre" or "customQuery" is required to search'));
  } catch (error) {
    return Promise.reject(new Error('error on algolia request'));
  }
}
