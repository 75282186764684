import React from 'react';

import PropTypes from 'prop-types';

import ContentGrid from '#/components/ContentGrid/ContentGrid';
import { ITEM_TYPE_TO_COMPONENT_MAP, ITEM_SIZE } from '#/components/Item/itemHelper';
import useI18n from '#/hooks/useI18n';
import { actionClickItem } from '#/services/analytics';

const getCarouselConfig = ({ itemType, config, carouselWidth }) => {
  const width = carouselWidth || 1880; // 1880 to take padding into consideration (1920-40)
  const spacing = 15;
  const itemSize = ITEM_SIZE[itemType];
  const { width: itemWidth, height: itemHeight } = {
    ...itemSize,
    ...config.itemOptions,
  };

  return {
    itemHeight,
    itemWidth,
    spacing,
    width,
  };
};

function CarouselShelf({ config = {}, carouselWidth, ds, items, itemType, nav = {}, onClick }) {
  const { currentLocale = {} } = useI18n();
  const { dir } = currentLocale;

  const carouselConfig = getCarouselConfig({
    itemType,
    config,
    carouselWidth,
  });
  const Item = ITEM_TYPE_TO_COMPONENT_MAP[itemType];
  const { headPadding = 0, tailPadding = 0 } = config;

  return (
    <ContentGrid
      data={items}
      ds={ds}
      nav={{
        ...nav,
        id: config.id || nav.id,
        useLastFocus: true,
      }}
      keyProperty="id"
      itemWidth={carouselConfig.itemWidth}
      itemHeight={carouselConfig.itemHeight}
      horizontal
      headPadding={headPadding}
      tailPadding={tailPadding}
      spacing={carouselConfig.spacing}
      width={carouselConfig.width}
      onClick={(item) => {
        actionClickItem(item.data, config.displayText);
        return onClick(item.data);
      }}
      DisplayComponent={Item}
      displayComponentProps={{ type: itemType }}
      initialState={{
        // id: items?.[0]?.id,
        position: headPadding,
      }}
      // useInternalArrows={useInternalArrows}
      dir={dir}
      buffer={6}
    />
  );
}

CarouselShelf.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  config: PropTypes.object,
  carouselWidth: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  ds: PropTypes.object,
  itemType: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  items: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  nav: PropTypes.object,
  onClick: PropTypes.func,
};

export default CarouselShelf;
