import React, { useEffect, useCallback } from 'react';

import { focusManager } from '@accedo/vdkweb-navigation';
import { FocusDiv } from '@accedo/vdkweb-tv-ui';
import { vKey } from '@accedo/xdk-virtual-key';
import T from 'prop-types';

import useKeyHandler from '#/hooks/useKeyHandler';
import usePage from '#/hooks/usePage';
import { screenView } from '#/services/analytics';
import { navIdMap as NAV_IDS } from '#/utils/navigationHelper';

/**
 * Page component which uses a custom hook for updating the information
 * of the current focused page.
 */

let isGoingBack = false;
const MENU_NAV = NAV_IDS.MENU.HEADER.MENU_ITEM;

function Page({
  autoFocus = true,
  className,
  children,
  id,
  nav,
  onBackwardDidMount,
  onBackwardWillUnmount,
  onForwardDidMount,
  onForwardWillUnmount,
  restoreLastFocusedElementOnMount,
  saveFocusedElementOnUnmount,
  analyticsProps,
}) {
  const { getPageLatestStateById, setPageLatestStateById, setCurrentPageId } = usePage();

  const keyHandler = useCallback(({ id: keyId }) => {
    const { BACK } = vKey;

    if (keyId === BACK.id) {
      isGoingBack = true;
    }
  }, []);

  useKeyHandler(keyHandler);

  /**
   * Default function to save which element was focused when the Page is unmounted from the DOM.
   *
   * If the item focused is in a container, it will mark the container it self as
   * last focused to avoid issues when item are no longer present
   */
  const saveCurrentFocusedElement = useCallback(() => {
    const currentTrail = focusManager.getTrail();
    const containerLayoutIndex = currentTrail.findIndex((item) => item === NAV_IDS.PAGE.CONTAINER.LAYOUT);
    const indextoSetBack = containerLayoutIndex > 0 ? containerLayoutIndex - 1 : 0;
    const currentFocus = currentTrail[indextoSetBack];
    const previousHistoryState = {};

    previousHistoryState.focusedElement = currentFocus;

    setPageLatestStateById(id, previousHistoryState);
  }, [setPageLatestStateById, id]);

  /**
   * Default function to restore the last element focussed
   * when the Page is mounted back with a back navigation.
   */
  const restoreLastFocusedElement = useCallback(
    (latestState) => {
      if (
        latestState?.focusedElement &&
        // to avoid the focus is on a menu button when we go back between views
        !latestState?.focusedElement.includes(`${MENU_NAV}`)
      ) {
        focusManager.changeFocus(latestState.focusedElement);
      } else {
        focusManager.changeFocus(id);
      }
    },
    [id],
  );

  // we only want to execute it on the first and last render
  useEffect(() => {
    if (autoFocus) {
      focusManager.changeFocus(id);
    }
    // we need the latest update of isGoingBack, we cannot store the valeue
    if (isGoingBack) {
      // onBackwardDidMount
      const latestState = getPageLatestStateById(id);
      if (restoreLastFocusedElementOnMount) {
        restoreLastFocusedElement(latestState);
      }
      // eslint-disable-next-line no-unused-expressions -- TODO: Automatically surpressed error. Resolve when you encounter this file!
      onBackwardDidMount && onBackwardDidMount(latestState);
    } else {
      // eslint-disable-next-line no-unused-expressions -- TODO: Automatically surpressed error. Resolve when you encounter this file!
      onForwardDidMount && onForwardDidMount();
    }
    setCurrentPageId(id);
    isGoingBack = false;
    return () => {
      // Page will be unmount from dom
      // we need the latest update
      if (isGoingBack) {
        // onBackwardDidMount
        // eslint-disable-next-line no-unused-expressions -- TODO: Automatically surpressed error. Resolve when you encounter this file!
        onBackwardWillUnmount && onBackwardWillUnmount(id);
      } else {
        if (saveFocusedElementOnUnmount) {
          saveCurrentFocusedElement();
        }
        // eslint-disable-next-line no-unused-expressions -- TODO: Automatically surpressed error. Resolve when you encounter this file!
        onForwardWillUnmount && onForwardWillUnmount(id);
      }
      setCurrentPageId(null);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (analyticsProps?.screenTitle) {
      screenView(analyticsProps);
    }
  }, [analyticsProps]);

  Page.propTypes = {
    autoFocus: T.bool,
    className: T.string,
    children: T.node,
    id: T.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
    nav: T.object,
    /** Callback function to be called on backward navigation mount */
    onBackwardDidMount: T.func,

    /** Callback function to be called on backward navigation unmount */
    onBackwardWillUnmount: T.func,

    /** Callback function to be called on forward navigation mount */
    onForwardDidMount: T.func,

    /** Callback function to be called on forward navigation unmount */
    onForwardWillUnmount: T.func,

    /** To restore the last focused element on backward mount */
    restoreLastFocusedElementOnMount: T.bool,

    /** To save the latest focused elemnt on unmount */
    saveFocusedElementOnUnmount: T.bool,

    analyticsProps: T.shape({
      /** Analytics screenTitle prop: Title of the page */
      screenTitle: T.string,
      /** Analytics screenType prop: Type of the page */
      screenType: T.string,
      /** Asset Properties */
      contentId: T.string,
      title: T.string,
      description: T.string,
      publishDate: T.string,
      seasonNumber: T.number,
      episodeNumber: T.number,
      genre: T.string,
      duration: T.string,
    }),
  };

  return (
    <FocusDiv className={className} nav={nav}>
      {children}
    </FocusDiv>
  );
}

export default Page;
