import dayjs from 'dayjs';
import { DateTime } from 'luxon';
import cookie from 'react-cookie';

import { EPG } from '#/config/constants';
import { SESSION_KEY } from '#/providers/shared/control/config';
import { getTranslation } from '#/services/i18n';

export const getChannelData = async () => {
  const data = await fetch(`${process.env.REACT_APP_CONTROL_DOMAIN}/content/entries?typeAlias=live-channels`, {
    headers: { 'X-Session': cookie.load(SESSION_KEY) },
  });

  return data.json();
};

const createEmptyProgram = (startTime, endTime, channelData) => ({
  title: getTranslation('epgProgramNoListingTitle'),
  startTime,
  endTime,
  channelId: channelData.bcChannelId,
  synopsis: channelData.isPopup
    ? getTranslation('epgPopupNoProgramListingDescription')
    : getTranslation('epgNoProgramListingDescription'),
  isNoProgramListing: true,
  id: startTime + endTime + channelData.bcChannelId,
});

export const getPromises = ({ channels, offset, count, startFormatted, endFormatted, epgApiEndpoint }) =>
  channels.entries
    .filter((entry, index) => index >= offset && index < offset + count)
    .map((entry) => {
      const { bcChannelId, epgApiChannelSlug: slug } = entry;
      const encodedStartDate = encodeURIComponent(startFormatted);
      const encodedEndDate = encodeURIComponent(endFormatted);
      const apiUrl = `${epgApiEndpoint}${slug.toLowerCase()}?limit=100&offset=0&startTime=${encodedStartDate}&endTime=${encodedEndDate}`;

      return fetch(apiUrl)
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`API error: ${response.status} - ${response.statusText}`);
          }
          const d = await response.json();

          return d.entries;
        })
        .then((apiData) => ({ programs: apiData, bcChannelId }))
        .catch((error) => {
          console.error(`Error fetching data for slug ${slug}:`, error);
          const updatedEntry = { ...entry, programs: [] };

          return updatedEntry;
        });
    });

export const getPrograms = ({ result, channelData, startTime, endTime, endFormatted }) => {
  let currentStartTime = startTime;
  let closingEndTime;
  const arrayOfNoProgramListings = [];
  const resultsArr = result.programs
    .filter((p) => {
      const programStartTimeMilliseconds = DateTime.fromISO(p.startTime).toMillis();
      const programEndTimeMilliseconds = DateTime.fromISO(p.endTime).toMillis();

      if (programStartTimeMilliseconds < startTime && programEndTimeMilliseconds > startTime) {
        currentStartTime = programEndTimeMilliseconds;
      }

      if (programEndTimeMilliseconds < startTime || programStartTimeMilliseconds > endTime) {
        return false;
      }
      return (
        dayjs(programStartTimeMilliseconds).day() === dayjs(startTime).day() ||
        (p.endTime <= endFormatted && p.startTime < endFormatted)
      );
    })
    .map((p, index, arr) => {
      let updatedEndTime;
      const programStartTimeMilliseconds = DateTime.fromISO(p.startTime).toMillis();
      const programEndTimeMilliseconds = DateTime.fromISO(p.endTime).toMillis();
      // only do this for first item
      if (index === 0 && programStartTimeMilliseconds > currentStartTime + EPG.emptyProgramThreshold) {
        closingEndTime = programStartTimeMilliseconds;
        while (currentStartTime < closingEndTime) {
          const noProgramListingEndDate = Math.min(currentStartTime + EPG.maxNoProgramListingDuration, closingEndTime);
          const emptyProgram = createEmptyProgram(currentStartTime, noProgramListingEndDate, channelData);
          arrayOfNoProgramListings.push(emptyProgram);
          currentStartTime = noProgramListingEndDate;
        }
      }
      const nextIndex = index + 1;
      if (nextIndex < arr.length) {
        const nextProgram = arr[nextIndex];
        const timeDifference = DateTime.fromISO(nextProgram.startTime).diff(DateTime.fromISO(p.endTime));
        if (timeDifference.as('minutes') < 15) {
          updatedEndTime = nextProgram.startTime;
        }
        if (timeDifference.as('minutes') > 15) {
          const nextProgramStarTimeMilli = DateTime.fromISO(nextProgram.startTime).toMillis();
          const emptyProgram = createEmptyProgram(programEndTimeMilliseconds, nextProgramStarTimeMilli, channelData);
          arrayOfNoProgramListings.push(emptyProgram);
        }
      }
      if (index === arr.length - 1) {
        if (programEndTimeMilliseconds < endTime) {
          const timeDifference = (endTime - programEndTimeMilliseconds) / 60000;
          if (timeDifference < 15) {
            updatedEndTime = endFormatted;
          }
          if (timeDifference > 15) {
            const emptyProgram = createEmptyProgram(programEndTimeMilliseconds, endTime, channelData);
            arrayOfNoProgramListings.push(emptyProgram);
          }
        }
      }

      return {
        ...p,
        title: p.title,
        startTime: DateTime.fromISO(p.startTime).toMillis(),
        endTime: updatedEndTime ? DateTime.fromISO(updatedEndTime).toMillis() : DateTime.fromISO(p.endTime).toMillis(),
        type: '',
        mediaId: '',
        channelId: channelData.bcChannelId,
        id: programStartTimeMilliseconds + programEndTimeMilliseconds + channelData.bcChannelId,
      };
    });

  if (!resultsArr.length) {
    const emptyProgram = createEmptyProgram(currentStartTime, endTime, channelData);
    arrayOfNoProgramListings.push(emptyProgram);
  }
  const programsArray = [...arrayOfNoProgramListings, ...resultsArr];

  return programsArray.sort((a, b) => a.startTime - b.endTime);
};
