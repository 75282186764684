import { environment, device } from '@accedo/xdk-core';

import config from '#/config';

const getResolution = () => {
  let SCREEN_RESOLUTION = config.app.defaultScreenDimension;

  const { system } = device;

  if (system) {
    const { width, height } = system.getAppResolution();

    if (width && height) {
      return { width, height };
    }
  }

  if (typeof global.screen !== 'undefined' && global.screen.width && global.screen.height) {
    SCREEN_RESOLUTION = {
      height: global.screen.height,
      width: global.screen.width,
    };
  }
  return SCREEN_RESOLUTION;
};

environment.addEventListener(environment.DEVICE.ONLOAD, () => getResolution);

export default getResolution;
