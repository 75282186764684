import React, { useCallback } from 'react';

import { FocusButton, FocusDiv } from '@accedo/vdkweb-tv-ui';
import { vKey } from '@accedo/xdk-virtual-key';
import PropTypes from 'prop-types';

import useI18n from '#/hooks/useI18n';
import useKeyHandler from '#/hooks/useKeyHandler';
import { getTranslation } from '#/services/i18n';
import isRTL from '#/utils/isRTL';
import { navIdMap } from '#/utils/navigationHelper';

import styles from './popup.scss';

const NAV_IDS = navIdMap.POPUP;

/**
 * Global Popup Component. With title, content and up to 2 buttons for Accept and Cancel
 *
 * @param {Object} props The object properties
 * @param {Object} props.nav The navigation object
 * @param {Object} props.theme The CSS theme for the component
 * @returns {React.ReactComponentElement} Component
 */
function Popup({ nav, theme, acceptText, cancelText, onAccept, onCancel, isOpen, ...props }) {
  const { currentLocale = {} } = useI18n();
  const { dir } = currentLocale;
  const rtl = isRTL(dir);
  const containerStyle = theme?.errorPopupContainer || styles.container;
  const innerStyle = theme?.errorPopupInner || styles.inner;
  const isAcceptVisible = !!onAccept;
  const isCancelVisible = !!onCancel;

  const navAcceptBtnId = `${nav.id}_${NAV_IDS.OK_POPUP}`;
  const navCancelBtnId = `${nav.id}_${NAV_IDS.CANCEL_POPUP}`;

  const acceptBtnText = acceptText || getTranslation('okButton').toUpperCase();
  const cancelBtnText = cancelText || getTranslation('cancelButton').toUpperCase();

  const keyHandler = useCallback(
    ({ id }, event) => {
      const { BACK } = vKey;

      if (id === BACK.id && isOpen) {
        event.stopImmediatePropagation();
        if (onCancel) {
          onCancel();
        }
      }
    },
    [isOpen, onCancel],
  );

  useKeyHandler(keyHandler);

  return (
    <FocusDiv
      className={containerStyle}
      nav={{
        id: nav.id,
        parent: nav.parent,
        forwardFocus: navAcceptBtnId,
      }}
      style={{ display: isOpen ? 'block' : 'none' }}
    >
      <div className={innerStyle} dir={dir}>
        <div className={styles.title} dir={dir}>
          <b>{props.title}</b>
        </div>

        <div className={styles.content} dir={dir}>
          {props.content}
        </div>

        <div className={styles.buttonsContainer} dir={dir}>
          <FocusButton
            nav={{
              id: navAcceptBtnId,
              parent: nav.id,
              nextup: navAcceptBtnId,
              nextdown: navAcceptBtnId,
              nextleft: rtl && isCancelVisible ? navCancelBtnId : navAcceptBtnId,
              nextright: !rtl && isCancelVisible ? navCancelBtnId : navAcceptBtnId,
            }}
            theme={{
              buttonContainer: styles.focusButtonContainer,
              button: styles.focusButton,
              buttonFocused: styles.focusButtonFocused,
              buttonActive: styles.focusButtonActive,
            }}
            style={{ display: isAcceptVisible ? 'block' : 'none' }}
            onClick={onAccept}
          >
            {acceptBtnText}
          </FocusButton>

          <FocusButton
            nav={{
              id: navCancelBtnId,
              parent: nav.id,
              nextup: navCancelBtnId,
              nextdown: navCancelBtnId,
              nextleft: !rtl ? navAcceptBtnId : navCancelBtnId,
              nextright: rtl ? navAcceptBtnId : navCancelBtnId,
            }}
            theme={{
              buttonContainer: styles.focusButtonContainer,
              button: styles.focusButton,
              buttonFocused: styles.focusButtonFocused,
              buttonActive: styles.focusButtonActive,
            }}
            style={{ display: isCancelVisible ? 'block' : 'none' }}
            onClick={onCancel}
          >
            {cancelBtnText}
          </FocusButton>
        </div>
      </div>
    </FocusDiv>
  );
}

Popup.propTypes = {
  /** nav object from @accedo/vdkweb-navigation */
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  nav: PropTypes.object,
  /** Title of the Popup, a React object is allowed so it can be styled */
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /** Content of the Popup, a React object is allowed so it can be styled */
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /** Function to execute when Accept button is actioned */
  onAccept: PropTypes.func,
  /** Function to execute when Cancel button is actioned. If it's not present, there will be no Cancel Button */
  onCancel: PropTypes.func,
  /** whether tje popup is opened or not */
  isOpen: PropTypes.bool,
  /** CSS module theme */
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  theme: PropTypes.object,
  /** Text for the Accept button */
  acceptText: PropTypes.string,
  /** Text for the Cancel button if onCancel is included */
  cancelText: PropTypes.string,
};

export default Popup;
