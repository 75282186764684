/**
 * @module models/CMS
 */

/**
 * This model represents the Theme.
 *
 * vikiElevateTheme representation
 * @typedef {Object} VikiElevateTheme
 * @property {String} accent focus color
 * @property {String} alternativeBackground skeletons, other backgrounds
 * @property {String} background page background
 * @property {String} colorOnAccent text color on focus
 * @property {String} colorOnBackground main color for regular text
 * @property {String} colorOnAccent error text color
 * @property {String} overlay overlay color
 */

/**
 * VikiElevateTheme Model
 * @param {Object} rawTheme Theme
 * @returns {VikiElevateTheme} theme - The {@link VikiElevateTheme} representation
 */
const theme = (rawTheme) => {
  const {
    accent,
    alternativeBackground,
    background,
    colorOnAccent,
    colorOnBackground,
    error,
    primary,
    textPrimary,
    customBadgeColourBlue,
    customBadgeColourGreen,
    customBadgeColourRed,
    customBadgeColourWhite,
    cobGreyHigh,
    cobGreyMedium,
    cobGreyLow,
    overlay,
  } = rawTheme;

  const getAcent = () => accent;

  const getAlternativeBackground = () => alternativeBackground;

  const getBackground = () => background;

  const getColorOnAccent = () => colorOnAccent;

  const getColorOnBackground = () => colorOnBackground;

  const getError = () => error;

  const getPrimary = () => primary;

  const getTextPrimary = () => textPrimary;

  const getCustomBadgeColourBlue = () => customBadgeColourBlue;

  const getCustomBadgeColourGreen = () => customBadgeColourGreen;

  const getCustomBadgeColourRed = () => customBadgeColourRed;

  const getCustomBadgeColourWhite = () => customBadgeColourWhite;

  const getCobGreyHigh = () => cobGreyHigh;

  const getCobGreyMedium = () => cobGreyMedium;

  const getCobGreyLow = () => cobGreyLow;

  const getOverlay = () => overlay;

  return {
    accent: getAcent(),
    alternativeBackground: getAlternativeBackground(),
    background: getBackground(),
    colorOnAccent: getColorOnAccent(),
    colorOnBackground: getColorOnBackground(),
    error: getError(),
    primary: getPrimary(),
    textPrimary: getTextPrimary(),
    customBadgeColourBlue: getCustomBadgeColourBlue(),
    customBadgeColourGreen: getCustomBadgeColourGreen(),
    customBadgeColourRed: getCustomBadgeColourRed(),
    customBadgeColourWhite: getCustomBadgeColourWhite(),
    cobGreyHigh: getCobGreyHigh(),
    cobGreyMedium: getCobGreyMedium(),
    cobGreyLow: getCobGreyLow(),
    overlay: getOverlay(),
  };
};

export default theme;
