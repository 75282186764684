/**
 * @module models/OVP
 */

/**
 * @typedef {Object} Genre
 * @property {String} id genre ID
 * @property {String} title genre Title
 */

/**
 * Genre Model
 * @param {Object} rawGenre Genre
 * @returns {Genre} Genre
 */
// eslint-disable-next-line consistent-return -- TODO: Automatically surpressed error. Resolve when you encounter this file!
const Genre = (rawGenres) => {
  if (!rawGenres?.entries.length) {
    return [];
  }
  const genres = rawGenres.entries.map((entry) => ({
    id: entry._meta.id,
    title: entry.title,
  }));

  return genres;
};

export default Genre;
