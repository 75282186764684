import cms from '#/providers/cms';

import fetcher from '../../helpers/fetcher';

/**
 * @module services/cms
 * @description
 * Service to handle the fetch of cms live data
 */

const getTvListings = async ({ startTime, endTime, count, offset, epgApiEndpoint }) => {
  const data = await fetcher({
    fetchFn: () => cms.getTvListings({ startTime, endTime, count, offset, epgApiEndpoint }),
  });
  data.entries = data.entries.map((entry) => ({
    ...entry,
    title: entry.channelName,
    id: entry.bcChannelId,
    channelId: entry.bcChannelId,
    images: [{ type: 'logo', url: entry.thumbnailImage.fileUrl }],
  }));

  return data;
};

// eslint-disable-next-line import/prefer-default-export
export { getTvListings };
