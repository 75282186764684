import { device } from '@accedo/xdk-core';

import { Settingskeys, Setting } from '#/models/setting/setting';

const STORAGE_STRATEGY = 'local';

/**
 * @module services/settings
 * @description
 * Service to handle the settings methods
 * @returns {any} storageManager instance
 */

const getStorage = async () => {
  const { storageManager } = device;

  return storageManager.getStorage(STORAGE_STRATEGY);
};

/**
 * Get the stored value for an specific key
 * @param {String} key string key to get the value from
 * @returns {any} the stored value
 */
export const get = async <T extends Setting>(key: Settingskeys): Promise<T | null> => {
  try {
    const storage = await getStorage();
    const value = storage.get(key);

    if (!value) {
      return null;
    }

    const parsedValue: T = JSON.parse(value);

    return parsedValue;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

/**
 * Set a value for an specific key
 * @param {String} key string key to get the value from
 * @param {any} value any value to store
 * @returns {void}
 */
// eslint-disable-next-line consistent-return -- TODO: Automatically surpressed error. Resolve when you encounter this file!
export const set = async <T extends Setting>(key: Settingskeys, value: T) => {
  try {
    const storage = await getStorage();
    storage.set(key, JSON.stringify(value));
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};
