/**
 * @module models/CMS
 */
/**
 * This model represents the Menu.
 *
 * vikiElevateContainer representation
 * @typedef {Object} vikiElevateContainer
 * @property {String} title container's title
 * @property {String} id identifier of the current container
 * @property {String} displaytext container text property
 * @property {String} items container items
 * @property {String} query container's query to retrieve items
 * @property {String} template container template
 * @property {String} subtitle container's subtitle
 */

/**
 * vikiElevateContainer Model
 * @param {Object} rawContainer Container
 * @returns {vikiElevateContainer} Container - The {@link vikiElevateContainer} representation
 */
const container = (rawContainer) => {
  const {
    displayText,
    items,
    id,
    query,
    template,
    title,
    subtitle,
    advanceAlgoliaQuery,
    genreBasedContent,
    maxNumberOfContentToShow,
  } = rawContainer;

  const getTitle = () => title;

  const getDisplayText = () => displayText;

  const getItems = () =>
    // return items.map(item => containerItem(item)); // todo
    items;

  const getId = () => id; // to add it into the docs

  const getQuery = () => query;

  const getTemplate = () => template;

  const getSubtitle = () => subtitle;

  const getAdvanceAlgoliaQuery = () => advanceAlgoliaQuery;

  const getGenreBasedContent = () => genreBasedContent;

  const getMaxNumberOfContentToShow = () => maxNumberOfContentToShow;

  return {
    displayText: getDisplayText(),
    id: getId(),
    items: getItems(),
    query: getQuery(),
    template: getTemplate(),
    title: getTitle(),
    subtitle: getSubtitle(),
    advanceAlgoliaQuery: getAdvanceAlgoliaQuery(),
    genreBasedContent: getGenreBasedContent(),
    maxNumberOfContentToShow: getMaxNumberOfContentToShow(),
  };
};

export default container;
