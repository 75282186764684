import cssVars from 'css-vars-ponyfill';

/**
 * Parsing the theme to get the values for CSS variables.
 *
 * @param {Object} theme The theme object from the CMS.
 * @returns {Object} The variables to be passed to the Page component.
 */
const setCssVariables = (theme) => {
  /**
   * Legacy browsers do not accept css variables references withing the code. They cannot update the
   * value for var(--color) at runtime.
   * So we cannot use that apporach
   * Object.keys(style).forEach(key => {
   *  r.style.setProperty(key, style[key]);
   * });
   * We use the cssVars ponyfill to explicit update the color in the style
   * instead of using a variable reference
   *
   */
  cssVars({
    onlyLegacy: true, // default
    variables: {
      '--accent': theme.accent, // focus color
      '--alternativebackground': theme.alternativeBackground, // skeletons, other backgrounds
      '--background': theme.background, // page background
      '--coloronaccent': theme.colorOnAccent, // text color on focus
      '--coloronbackground': theme.colorOnBackground, // main color for regular text
      '--error': theme.colorOnAccent, // error text color
      '--overlay': theme.overlay, // overlay color
      '--cobgreyhigh': theme.cobGreyHigh,
      '--cobgreymedium': theme.cobGreyMedium,
      '--cobgreylow': theme.cobGreyLow,
    },
  });
};

export default setCssVariables;
