import React, { useState, createContext, useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';

const GenreContext = createContext();

function GenreProvider({ children }) {
  const [genres, setGenres] = useState([]);

  const setGenresList = useCallback((genresList) => {
    setGenres(genresList);
  }, []);

  const genreContextValue = useMemo(
    () => ({
      setGenresList,
      genres,
    }),
    [setGenresList, genres],
  );

  return <GenreContext.Provider value={genreContextValue}>{children}</GenreContext.Provider>;
}

GenreProvider.propTypes = {
  children: PropTypes.node,
};

export { GenreContext, GenreProvider };
