/**
 * @module services/menu
 * @description
 * Service to handle the fetch of menu items
 */

import { PROVIDER_TYPE } from '#/config/constants';
import menuModel from '#/models/menu';
import cms from '#/providers/cms';

import fetcher from '../../helpers/fetcher';

const getMenu = async ({ items, entryId, isAuthenticated, locale, kidsMode }) => {
  let rawMenu;
  if (items) {
    rawMenu = { items };
  } else if (!rawMenu) {
    const cacheLocale = locale ? `-${locale}` : '';
    rawMenu = await fetcher({
      cacheId: kidsMode ? '' : `${PROVIDER_TYPE.cms}-${entryId}${cacheLocale}`,
      fetchFn: () => cms.getMenu({ id: entryId, options: { locale }, kidsMode }),
    });
  }
  const menu = menuModel(rawMenu);

  // return only usable items according to the user state
  const menuItems = menu.items.filter(
    (item) => !(item.hiddenWhenAuth && isAuthenticated) && !(item.requireAuthentication && !isAuthenticated),
  );

  const primaryItems = [];
  const secondaryItems = [];
  menuItems.forEach((item) => {
    /** The default itemGroup is the 'primary' group */
    // eslint-disable-next-line no-unused-expressions -- TODO: Automatically surpressed error. Resolve when you encounter this file!
    item.itemGroup !== 'secondary' ? primaryItems.push(item) : secondaryItems.push(item);
  });

  return { primaryItems, secondaryItems, title: menu.displayText };
};

// eslint-disable-next-line import/prefer-default-export -- TODO: Automatically surpressed error. Resolve when you encounter this file!
export { getMenu };
