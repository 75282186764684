import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import ContainerComp from '#/components/Container/Container';
import Shelf from '#/components/Shelf';

function Container(props) {
  const {
    contextData,
    items,
    query,
    template,
    nav,
    moreItems,
    index,
    id,
    title,
    displayText,
    gridConfig,
    removeEmptyContainers,
  } = props;
  const hasItems = items && items.length > 0;

  const removeContainer = useCallback(() => {
    removeEmptyContainers(id);

    console.warn(`[debug] Empty container in the index ${index}`);
  }, [removeEmptyContainers, index, id]);

  if (!hasItems && !query) {
    return null;
  }

  const shelfProps = {
    contextData,
    config: {
      displayText,
      index,
      id,
      moreItems,
      query,
      title,
      template,
      prefix: `prefix-${id}`,
      ...gridConfig,
    },
    nav,
    template,
    removeEmptyContainers: removeContainer,
  };

  return (
    <ContainerComp {...props}>
      <Shelf {...shelfProps} items={items} query={query} />
    </ContainerComp>
  );
}

Container.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  contextData: PropTypes.object,
  gridConfig: PropTypes.shape({
    useInternalArrows: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
    gridRef: PropTypes.object,
    onChange: PropTypes.func,
  }),
  id: PropTypes.string,
  index: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  items: PropTypes.array,
  moreItems: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  nav: PropTypes.object,
  query: PropTypes.string,
  title: PropTypes.string,
  displayText: PropTypes.string,
  template: PropTypes.string,
  removeEmptyContainers: PropTypes.func,
};

export default Container;
