// Polyfill for XDK4
import 'core-js';
// eslint-disable-next-line import/no-extraneous-dependencies -- TODO: Automatically surpressed error. Resolve when you encounter this file!
import 'regenerator-runtime/runtime';

// XDK4 Common Config Store
import xdkConfigStore from '@accedo/xdk-config';

// XDK4 Configuration for this app
import config from '#/config/xdk.config';

xdkConfigStore.load(config);
