/**
 * @module services/auth
 *
 * @description
   This template service is designed to handle the authentication flow.

   It provides two login functions, a logout and other helpful functions:

  *  `loginByCredentials`: Regular username/passworkd
  *  `loginByPairingCode`: Associate a code with an user
  *  `logout`: Logout the current logged in user
  *  `validateUser`: Validate an user based on a token
  *  `pairingCode`: Retrieves a code to be shown on a device

 * Keep in mind this service is used by AuthContext Context API.
 */

import { device } from '@accedo/xdk-core';

import User from '#/models/user/user';
import idpProvider from '#/providers/idp';
import { userLoggedIn, userLogInError } from '#/services/analytics/analytics';

/**
 * Gets the current user info
 * @returns {Promise<User>} A Promise with the User returned
 */
const getUser = async () => {
  const rawUser = await idpProvider.getUser();
  if (!rawUser) {
    return;
  }
  const user = User(rawUser);

  // eslint-disable-next-line consistent-return -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  return user;
};

/**
 * Credentials Login Service.
 * @param {String} username Username
 * @param {String} password Password
 * @returns {Promise<User>} Loggedin User
 */
const loginByCredentials = async (username, password) => {
  let response;
  let user;
  try {
    response = await idpProvider.login(username, password);
  } catch (e) {
    userLogInError({ username });
    throw username;
  }

  try {
    user = User({
      ...response,
      username,
    });
  } catch (e) {
    console.error('[ERROR] Unable to create user data on IdP Service');
    // userLogInError({ username });
    throw username;
  }
  userLoggedIn(user);
  return user;
};

/**
 * Pairing Code Login Service.
 * @param {String} deviceId Device to pair
 * @param {AbortController} [abortController] Controller to abort a request if needed
 * @returns {Promise<void>} Void
 */
const loginByPairingCode = async (deviceId, abortController = null) => {
  let response;
  try {
    response = await idpProvider.pair(deviceId, abortController);
  } catch (e) {
    console.error('[ERROR] Pair has failed', e);
    throw e;
  }
  const storage = await device.storageManager.getStorage('local');
  if (response.user) {
    const user = User(response.user);
    storage.set('authData', JSON.stringify(user));
    return Promise.resolve(user);
  }
  return Promise.resolve(null);
};

/**
 * Pairing Code Service.
 * @param {String} deviceId Device to pair
 * @returns {Promise<String>} Pairing code
 */
const getPairingCode = async (deviceId) => idpProvider.getPairingCode(deviceId);

/**
 * Validade User Service.
 * @returns {Promise<User>|null} Loggedin User
 */
const validateUser = async () => {
  const user = await getUser();
  if (!user || !user.token) {
    return null;
  }

  try {
    return idpProvider.validateUser(user);
  } catch (e) {
    console.error('[ERROR] Unable to validate user');
    throw e;
  }
};

/**
 * Logout Service.
 * @returns {Promise<Boolean>} Logout success
 */
const logout = async () => {
  const user = await getUser();
  const { token } = user;

  if (token) {
    return idpProvider.logout(token);
  }
  return false;
};

/**
 * Forgot Password Service.
 * @param {String} username Username
 * @returns {Promise<String>} Password restore
 */
const forgotPassword = async (username) => idpProvider.forgotPassword(username);

export { loginByCredentials, loginByPairingCode, validateUser, logout, getPairingCode, getUser, forgotPassword };
