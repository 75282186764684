import React, { useCallback, useState } from 'react';

import { focusManager } from '@accedo/vdkweb-navigation';
import { FocusDiv } from '@accedo/vdkweb-tv-ui';
import { vKey } from '@accedo/xdk-virtual-key';
import PropTypes from 'prop-types';

import HorizontalLayout from '#/components/Layout/HorizontalLayout';
import useI18n from '#/hooks/useI18n';
import useKeyHandler from '#/hooks/useKeyHandler';
import { navIdMap } from '#/utils/navigationHelper';

import FilterComponent from './Filter';
import filterableShelfTheme from './filterableShelf.scss';

const TYPES = {
  filter: 'filter',
  sort: 'sort',
};

const sortItems = [
  {
    id: 'titleAsc',
    param: 'title|asc',
    label: 'Title ASC',
  },
  {
    id: 'titleDesc',
    param: 'title|desc',
    label: 'Title DESC',
  },
];

const FILTER_ITEMS_QUERY = 'movieCategories';

const NAV_IDS = navIdMap.PAGE.LIST;

const navFilterButtons = {
  filter: {
    buttonId: NAV_IDS.FILTER_BUTTON,
    selectorListId: NAV_IDS.SELECTOR_LIST,
  },
  sort: {
    buttonId: NAV_IDS.SORT_BUTTON,
    selectorListId: NAV_IDS.SELECTOR_LIST,
  },
};

function FilterableShelf(props) {
  const { currentLocale = {} } = useI18n();
  const { dir } = currentLocale;
  const { items, nav } = props;

  const LAYOUT_ID = `${nav.id}_LAYOUT_ID`;

  const shelfNav = {
    forwardFocus: LAYOUT_ID,
    ...nav,
  };

  const layoutNav = {
    id: LAYOUT_ID,
    parent: nav.id,
  };

  const { BACK } = vKey;

  const [selectorListTypeVisible, setSelectorListTypeVisible] = useState({
    filter: false,
    sort: false,
  });

  const onItemClick = useCallback((type) => {
    setSelectorListTypeVisible((selectorList) => ({
      ...selectorList,
      [type]: true,
    }));
    focusManager.changeFocus(NAV_IDS.SELECTOR_LIST);
  }, []);

  const onOptionSelected = () => {
    setSelectorListTypeVisible({
      filter: false,
      sort: false,
    });
  };

  const getFilterItems = (type) => {
    if (type === TYPES.sort) {
      return sortItems;
    }
    return null;
  };

  const getFilterItemsQuery = (type) => {
    if (type === TYPES.filter) {
      return FILTER_ITEMS_QUERY;
    }
    return '';
  };

  const getItemProps = useCallback(
    (item, index) => ({
      item,
      index,
      key: item.template,
      nav: {
        id: item.id,
        parent: LAYOUT_ID,
        ...navFilterButtons[item.template],
      },
      onClick: onItemClick,
      onOptionSelected,
      isSelectorVisible: selectorListTypeVisible[item.template],
      items: getFilterItems(item.template),
      query: getFilterItemsQuery(item.template),
      type: item.template,
    }),
    [selectorListTypeVisible, LAYOUT_ID, onItemClick],
  );

  // Components for HorizontalLayout
  const getComponents = () =>
    items.map((item, index) => ({
      Component: FilterComponent,
      props: getItemProps(item, index),
    }));

  const shelfItems = getComponents();

  const keyHandler = useCallback(
    ({ id }, event) => {
      if (id === BACK.id) {
        if (selectorListTypeVisible.filter || selectorListTypeVisible.sort) {
          event.stopImmediatePropagation();
          setSelectorListTypeVisible({
            filter: false,
            sort: false,
          });
          focusManager.changeFocus(NAV_IDS.FILTER_BUTTON);
        }
      }
    },
    [BACK.id, selectorListTypeVisible],
  );

  useKeyHandler(keyHandler);

  return (
    <FocusDiv nav={shelfNav}>
      <div className={filterableShelfTheme.container} dir={dir}>
        <HorizontalLayout components={shelfItems} nav={layoutNav} />
      </div>
    </FocusDiv>
  );
}

FilterableShelf.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  items: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  nav: PropTypes.object,
};

export default FilterableShelf;
