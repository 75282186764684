const getProtocolRelativePathUrl = (src = '') => {
  if (src === null || typeof src !== 'string') {
    return;
  }
  const relativePathSeparator = '//';
  const pathSeparatorIndex = src.indexOf(relativePathSeparator);

  if (pathSeparatorIndex > -1) {
    // eslint-disable-next-line consistent-return -- TODO: Automatically surpressed error. Resolve when you encounter this file!
    return src.slice(pathSeparatorIndex);
  }
  // eslint-disable-next-line consistent-return -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  return src;
};

// eslint-disable-next-line consistent-return -- TODO: Automatically surpressed error. Resolve when you encounter this file!
const getQueryStringParameters = (url) => {
  if (url.split('?').length > 1) {
    const query = url.split('?')[1];

    return query.split('&').reduce((params, param) => {
      const [key, value] = param.split('=');
      // eslint-disable-next-line no-param-reassign -- TODO: Automatically surpressed error. Resolve when you encounter this file!
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
      return params;
    }, {});
  }
};

export { getProtocolRelativePathUrl, getQueryStringParameters };
