/**
 * @module models/CMS
 */

/**
 * This model represents the Page.
 *
 * vikiElevatePage representation
 * @typedef {Object} VikiElevatePage
 * @property {VikiElevateContainer[]} containers structure for the page layout
 * @property {String} displaytext page text property
 * @property {VikiElevateMenu} menu custom menu for page
 * @property {String} template page template
 * @property {String} title page's title
 * @property {VikiElevateTheme[]} theme page's theme
 */

import containerModel from '#/models/container';

/**
 * VikiElevatePage Model
 * @param {Object} rawPage Page
 * @returns {VikiElevatePage} menu - The {@link VikiElevatePage} representation
 */
const page = (rawPage) => {
  const { id, containers = [], displayText, template, title } = rawPage;

  const getPageId = () => id;

  // eslint-disable-next-line consistent-return -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  const getContainers = () => {
    if (containers) {
      return containers.map((container) => containerModel(container));
    }
  };

  const getDisplayText = () => displayText;

  const getTemplate = () => template;

  const getTitle = () => title;

  return {
    id: getPageId(),
    containers: getContainers(),
    displayText: getDisplayText(),
    template: getTemplate(),
    title: getTitle(),
  };
};

export default page;
