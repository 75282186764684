import { PROVIDER_TYPE } from '#/config/constants';
import ovp from '#/providers/ovp';
import { validDateEntry } from '#/utils/helpers';

import fetcher from '../../helpers/fetcher';

const getPlaylist = async ({ seasonId, watchLatest, showId, brightcoveProxyUrl }) => {
  const response = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-get-episodes-${seasonId}`,
    fetchFn: () => ovp.getPlaylist(seasonId, brightcoveProxyUrl),
  });

  if (response) {
    const filteredSortedResponse = response
      .filter((episode) => validDateEntry(episode.schedule).valid && episode.state !== 'INACTIVE')
      .sort((a, b) => {
        if (a.custom_fields?.episode_number === undefined) return 1;
        if (b.custom_fields?.episode_number === undefined) return -1;
        const sortOrder = watchLatest ? -1 : 1;

        return sortOrder * (a.custom_fields.episode_number - b.custom_fields.episode_number);
      });

    return filteredSortedResponse.map((item) => ({
      ...item,
      playlistId: seasonId,
      showId,
      watchLatest,
    }));
  }

  return [];
};

// eslint-disable-next-line import/prefer-default-export
export { getPlaylist };
