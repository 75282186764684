import React, { useState, createContext, useCallback } from 'react';

import PropTypes from 'prop-types';

/**
 *
 */
const ConfigContext = createContext();
const ConfigSetcontext = createContext();

function ConfigProvider({ children }) {
  const [state, setState] = useState({
    menuVisible: true,
    config: null,
    status: null,
    kidsMode: false,
  });

  const updateConfig = useCallback((config) => {
    setState((s) => ({
      ...s,
      ...config,
    }));
  }, []);

  return (
    <ConfigContext.Provider value={state}>
      <ConfigSetcontext.Provider value={updateConfig}>{children}</ConfigSetcontext.Provider>
    </ConfigContext.Provider>
  );
}

ConfigProvider.propTypes = {
  children: PropTypes.node,
};

export { ConfigContext, ConfigSetcontext, ConfigProvider };
