/**
 * @module models/analytics
 */

/**
 * @typedef {Object} AnalyticsAsset
 * @property {String} contentId Item ID
 * @property {String} title Item Tile
 * @property {String} description Item Description
 * @property {String} publishDate Publication date
 * @property {String} seasonNumber Season number
 * @property {String} episodeNumber Episode number
 * @property {String} genre Genres/Categories
 * @property {String} duration duration of the content
 */

/**
 * AnalyticsAsset Model
 * @param {Object} Item Item: Episode/Movie
 * @returns {AnalyticsAsset} AnalyticsAsset
 */
const AnalyticsAsset = (Item) => {
  const {
    id: contentId,
    title,
    description,
    duration,
    custom_fields: customFields,
    synopsis,
    name,
    contentType,
    id,
    primaryGenre,
    brightcoveId,
  } = Item;

  /**
   * Get the Item's id
   * @returns {String} ID
   */
  const getContentId = () => contentId || id;

  /**
   * Get the Item's title
   * @returns {String} Title of the content
   */
  const getTitle = () => name || title;

  /**
   * Get the Item's description
   * @returns {String} Description of the content
   */
  const getDescription = () => description || synopsis;

  /**
   * Get the Item's genre
   * @returns {String} Genres/categories
   */
  const getGenres = () => primaryGenre;

  /**
   * Get the Item's duration
   * @returns {String} Duration
   */
  const getDuration = () => duration;

  /**
   * Get the Item's seasonNumber if any
   * @returns {String} Duration
   */
  const getSeasonNumber = () => customFields?.season_number;

  /**
   * Get the Item's episodeNumber if any
   * @returns {String} Duration
   */
  const getEpisodeNumber = () => customFields?.episode_number;

  const getContentType = () => contentType;

  const getBrightcoveId = () => brightcoveId;

  return {
    contentId: getContentId(),
    title: getTitle(),
    description: getDescription(),
    seasonNumber: getSeasonNumber(),
    episodeNumber: getEpisodeNumber(),
    duration: getDuration(),
    genre: getGenres(),
    contentType: getContentType(),
    brightcoveId: getBrightcoveId(),
  };
};

export default AnalyticsAsset;
