/**
 * @module services/theme
 * @description
 * Service to handle the fetch of theme file from the CMS.
 * @return {VikiElevateTheme} The modelised theme file.
 */

import { PROVIDER_TYPE } from '#/config/constants';
import themeModel from '#/models/theme';
import cms from '#/providers/cms';

import fetcher from '../../helpers/fetcher';

const getDefaultTheme = async () => {
  const theme = await fetcher({
    cacheId: `${PROVIDER_TYPE.cms}-theme`,
    fetchFn: () => cms.getDefaultTheme(),
  });

  return theme && themeModel(theme);
};

const getThemeByPage = async ({ id, locale }) => {
  const defaultTheme = await getDefaultTheme();
  const theme = await fetcher({
    cacheId: `${PROVIDER_TYPE.cms}-${id}-theme`,
    fetchFn: () => cms.getThemeByPage({ id, locale }),
  });

  return theme ? themeModel(theme) : defaultTheme;
};

export { getThemeByPage, getDefaultTheme };
