/**
 * number of columns in vertical grid
 * number of rows in horizontal grid
 * @param {Number} spanSize - grid width or height depending on orientation
 * @param {Number} itemSize - item width or height depending on orientation
 * @param {Number} spacing - spacing between items
 * @returns {Number} span count
 */
const calculateSpanCount = (spanSize, itemSize, spacing) => Math.floor((spanSize + spacing) / (itemSize + spacing));

const getTotalSlides = (spanSize, itemSize, spacing, totalNumberItems) => {
  let itemsPerSpan = Math.floor(spanSize / (itemSize + spacing));
  if (itemsPerSpan === 0) {
    // spacing should be 0
    itemsPerSpan = 1;
  }
  return Math.floor(totalNumberItems / itemsPerSpan);
};

/**
 * unique id for grid item, used for
 * focusing and for react key inside loop
 * @param {String|Number} index - item global index in the datasource
 * @param {String} parent - parent nav id
 * @returns {String} nav id value
 */
const getItemNavId = (index, parent) => `grid-item-${parent}-${index}`;

/**
 * arbitrary id used to identify the
 * state of a grid.
 */
let instanceId = 0;
const increaseInstance = () => {
  instanceId += 1;
  return instanceId;
};

/**
 * Create structure used to render items
 * item structure:
 *  data: itemData,
 *  x,
 *  y,
 *  nav: {
 *    parent,
 *    nextup,
 *    nextdown,
 *    nextleft,
 *    nextright,
 *    id: itemId
 *  }
 * @returns {Object} item render structure from data
 */
const getStructuredItems = ({
  rtl,
  parent,
  width,
  height,
  spacing,
  vertical,
  itemHeight,
  itemWidth,
  items: data,
  firstIndex,
  total,
}) => {
  const items = [];

  const itemHeightWithSpacing = itemHeight + spacing;
  const itemWidthWithSpacing = itemWidth + spacing;

  let index = firstIndex;

  const spanCount = calculateSpanCount(vertical ? width : height, vertical ? itemWidth : itemHeight, spacing);

  const itemCount = data.length;
  for (let i = 0; i < itemCount; i += 1, index += 1) {
    let x;
    let y;
    if (vertical) {
      x = (i % spanCount) * itemWidthWithSpacing;
      y = Math.floor((firstIndex + i) / spanCount) * itemHeightWithSpacing;
    } else {
      x = Math.floor((firstIndex + i) / spanCount) * itemWidthWithSpacing;
      y = (i % spanCount) * itemHeightWithSpacing;
    }
    const item = {
      data: data[i],
      x,
      y,
      nav: {
        parent,
        id: getItemNavId(index, parent),
      },
    };

    if (firstIndex + i < spanCount) {
      /**
       * item is considered to be at head position
       * when it is an item placed at the very
       * beginning of the grid. so all items on
       * the first row of a vertical grid and all
       * items on the first column of a horizontal
       * grid.
       */
      item.head = true;
    }
    if (Math.floor((firstIndex + i) / spanCount) === Math.floor((total - 1) / spanCount)) {
      /**
       * item is considered to be at tail position
       * when it is an item placed at the very
       * end of the grid. so all items on the last
       * row of a vertical grid and all items on
       * the last column of a horizontal grid.
       */
      item.tail = true;
    }

    /**
     * calculate navigation for each item
     */
    if (i % spanCount < spanCount - 1 && data[i + 1]) {
      let direction;
      if (vertical) {
        if (rtl) {
          direction = 'nextleft';
        } else {
          direction = 'nextright';
        }
      } else {
        direction = 'nextdown';
      }
      item.nav[direction] = getItemNavId(index + 1, parent);
    }
    if (i % spanCount > 0 && data[i - 1]) {
      let direction;
      if (vertical) {
        if (rtl) {
          direction = 'nextright';
        } else {
          direction = 'nextleft';
        }
      } else {
        direction = 'nextup';
      }
      item.nav[direction] = getItemNavId(index - 1, parent);
    }
    if (i >= spanCount && data[i - spanCount]) {
      let direction;
      if (vertical) {
        direction = 'nextup';
      } else if (rtl) {
        direction = 'nextright';
      } else {
        direction = 'nextleft';
      }
      item.nav[direction] = getItemNavId(index - spanCount, parent);
    }
    if (i < itemCount - spanCount && data[i + spanCount]) {
      let direction;
      if (vertical) {
        direction = 'nextdown';
      } else if (rtl) {
        direction = 'nextleft';
      } else {
        direction = 'nextright';
      }
      item.nav[direction] = getItemNavId(index + spanCount, parent);
    } // if the last span is not completely filled, navigation from the previous span will focus the closest item on the last span
    else if (i >= itemCount - spanCount && itemCount % spanCount > 0 && i < itemCount - (itemCount % spanCount)) {
      item.nav[vertical ? 'nextdown' : 'nextright'] = getItemNavId(itemCount - 1, parent);
    }
    items.push(item);
  }

  return items;
};

/**
 * position and size values are used to calculate
 * the visible area of the grid and find what
 * data items to load.
 * spread is used to define the area where items
 * will render. when the grid is animating,
 * spread will increase to ensure that items are
 * rendered correctly during animation. when
 * animation has completed, spread is reset and
 * items are recalculated so any items that are
 * no longer needed are removed.
 * @returns {Object} values for data span
 */
const calculateItems = ({ spread, width, buffer, vertical, height, itemWidth, spacing, itemHeight }) => {
  // spread is used to find first position
  const position = Math.max.apply(undefined, spread);
  // spread is applied to length to find the last position
  const lengthSize = (vertical ? height : width) + Math.abs(spread[1] - spread[0]);
  const itemSizeWithSpacing = (vertical ? itemHeight : itemWidth) + spacing;
  // area before position that is not visible
  const overflowArea = -position;
  const spanCount = calculateSpanCount(vertical ? width : height, vertical ? itemWidth : itemHeight, spacing);
  // how many items before position that are not visible
  const overflowItems = Math.floor((overflowArea + spacing) / itemSizeWithSpacing);
  // first index that is visible
  let firstIndex = overflowItems * spanCount;
  // last index that is visible
  let lastIndex =
    firstIndex +
    Math.floor(
      (lengthSize - ((overflowItems * itemSizeWithSpacing) % itemSizeWithSpacing) + spacing) / itemSizeWithSpacing,
    ) *
      spanCount +
    spanCount -
    1;
  // apply buffer
  firstIndex = Math.max(0, firstIndex - spanCount * buffer);
  lastIndex += spanCount * buffer;

  return { firstIndex, lastIndex };
};

/**
 * It calculates the page to request from the datasource based on:
 * @param {Number} lastIndex last index of the current grid displayable 'window'
 * @param {Number} pageSize the size of the page (ds.pageSize)
 * @param {Number} limitToRequest the limit defined for the datasource to make a requests, calculated in calculateLimit
 *
 * @returns {Number} page to request
 */
const getMaxPageToRequest = ({ lastIndex, pageSize, limitToRequest = 0 }) => {
  const maxPageToRequest = Math.ceil((lastIndex + 1 + limitToRequest) / pageSize);

  return maxPageToRequest;
};

/**
 * It calculates the page to request from the datasource based on:
 * @param {Number} lastIndex last index of the current grid displayable 'window'
 * @param {Number} pageSize the size of the page (ds.pageSize)
 * @param {Number} limitToRequest the limit defined for the datasource to make a requests, calculated in calculateLimit
 * @param {Number} maxRequestedPage max index of page previously requested
 *
 * @returns {Number} page to request
 */
const getPageToRequest = ({ lastIndex, pageSize, limitToRequest = 0, maxRequestedPage = 0 }) => {
  const pageToRequest = getMaxPageToRequest({
    lastIndex,
    pageSize,
    limitToRequest,
  });

  return pageToRequest > maxRequestedPage ? maxRequestedPage + 1 : maxRequestedPage;
};

/**
 * It calculates the limit, taking into account the totalItems previously fetched
 * @param {Number} totalItems Total Number of items in the Datasource
 * @param {Number} fetchItemLimit the limit defined for the datasource to make a requests from (ds.fetchItemLimit)
 *
 * @returns {Number} calculated limit
 */
const calculateLimit = ({ totalItems, fetchItemLimit = 0 }) => (totalItems < fetchItemLimit ? 0 : fetchItemLimit);

export {
  calculateItems,
  calculateSpanCount,
  getItemNavId,
  getStructuredItems,
  increaseInstance,
  getTotalSlides,
  getMaxPageToRequest,
  getPageToRequest,
  calculateLimit,
};
