/**
 * This is an example implementation of Brightcove. It needs the AccountID,
 * Client ID and the Client Secret.
 */

// eslint-disable-next-line import/no-extraneous-dependencies -- TODO: Automatically surpressed error. Resolve when you encounter this file!
import fetch from '@accedo/vdkweb-fetch';

const convertModel = (bcMovieData) =>
  bcMovieData.map((entry) => {
    const newEntry = { ...entry };

    if (entry.images) {
      // grab highest res image
      const sources = entry?.images?.poster?.sources;

      if (sources) {
        let bestIdx = 0;
        let maxWidth = 0;
        for (let j = 0; j < sources.length; j += 1) {
          if (maxWidth < sources[j]?.width) {
            maxWidth = sources[j]?.width;
            bestIdx = j;
          }

          newEntry.images = [
            {
              url: (sources[bestIdx].src || '').replace(/^http:/, 'https:'),
              type: 'poster',
            },
          ];
        }
      }
    }

    return {
      ...newEntry,
      displayText: entry.name,
      title: entry.name,
      type: 'movie',
    };
  });

const getBrightCoveCmsData = async (api, opts) => {
  const params = opts?.params || null;
  const options = {};
  let url = `${api}`;
  if (params) {
    url += `?${params}`;
  }

  if (opts) {
    options.method = opts.method;
    options.body = JSON.stringify(opts.body);
  }

  const response = await fetch(url, options);
  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response.json();
};

const getMovieData = async (tag = 'movies') => {
  const data = await getBrightCoveCmsData('videos', {
    params: `q=tags:${tag}`,
  });

  return convertModel(data);
};

const getMovieById = async (id) => {
  const data = await getBrightCoveCmsData(`videos/${id}`);

  return convertModel(data);
};

const getAllPlaylistItems = async (id, playlistCount, limit, brightcoveProxyUrl) => {
  let isMoreContentAvailable = true;
  const fetchPromises = [];
  let offset = 0;
  while (isMoreContentAvailable) {
    const opts = {
      params: `limit=${limit}&offset=${offset}`,
    };
    const fetchPromise = getBrightCoveCmsData(`${brightcoveProxyUrl}/playlists/${id}/videos`, opts);
    fetchPromises.push(fetchPromise);
    offset += limit;
    if (offset >= playlistCount) {
      isMoreContentAvailable = false;
    }
  }
  const fetchedResults = await Promise.all(fetchPromises);
  const items = fetchedResults.flat();

  return items;
};

const getPlaylist = async (id, brightcoveProxyUrl) => {
  const limit = 100;
  const { count: playlistCount } = await getBrightCoveCmsData(`${brightcoveProxyUrl}/playlists/${id}/count`, {});
  if (playlistCount < limit) {
    const data = await getBrightCoveCmsData(`${brightcoveProxyUrl}/playlists/${id}/videos`, {});

    return data;
  }
  const data = await getAllPlaylistItems(id, playlistCount, limit, brightcoveProxyUrl);

  return data;
};

const getMovie = async (id, brightcoveProxyUrl) => {
  const data = await getBrightCoveCmsData(`${brightcoveProxyUrl}/videos/${id}`, {});

  return data;
};

const getTvShowData = () => {};

const getTvShowsByCategory = () => Promise.resolve();

const getMovieCategories = () => {};

const getMoviesByCategory = (cat) => getMovieData(cat);

const signOut = () => {
  // loginData = {};
};

const getChannelData = () => {};

const getTvListings = () => Promise.resolve();

const getPlayback = async (id) => {
  const options = {
    headers: {
      Accept: `application/json;pk=${process.env.REACT_APP_BRIGHTCOVE_PLAYBACK_POLICYKEY}`,
    },
  };
  const url = `${process.env.REACT_APP_BRIGHTCOVE_PLAYBACK_BASE_URL}/${process.env.REACT_APP_BRIGHTCOVE_ACCOUNT_ID}/videos/${id}`;

  const response = await fetch(url, options);
  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response.json();
};

const getPlaylists = () => getBrightCoveCmsData('playlists');

const getPlaylistVideos = async (id) => {
  const data = await getBrightCoveCmsData(`playlists/${id}/videos`);

  return convertModel(data);
};

const getPlaylistVideosByName = async (name) => {
  const data = await getBrightCoveCmsData('playlists');
  const res = data.find((item) => item.reference_id === name);
  if (res) {
    return getPlaylistVideos(res.id);
  }

  return null;
};

const createOrUpdatePlaylist = ({ plId, name, description, refId, favorite, videoIds }) => {
  const payload = {
    description,
    favorite,
    name,
    reference_id: refId,
  };

  // Only explicit playlists have video ids defined
  if (videoIds && videoIds.length > 0) {
    payload.type = 'EXPLICIT';
    payload.video_ids = videoIds;
  }

  let path = 'playlists';
  let method = 'POST';
  if (plId) {
    path += `/${plId}`;
    method = 'PATCH';
  }

  return getBrightCoveCmsData(path, { body: payload, method });
};

const createOrUpdateVideo = ({
  cuePoints,
  description,
  drm,
  economics,
  geo,
  longDescription,
  name,
  refId,
  schedule,
  state,
  tags,
  videoId,
}) => {
  const payload = {
    cue_points: cuePoints,
    description,
    drm_disabled: drm,
    economics,
    geo,
    long_description: longDescription,
    name,
    reference_id: refId,
    schedule,
    state,
    tags,
  };

  let path = 'videos';
  let method = 'POST';
  if (videoId) {
    path += `/${videoId}`;
    method = 'PATCH';
  }

  return getBrightCoveCmsData(path, { body: payload, method });
};

export default {
  getChannelData,
  getMovieById,
  getMovieCategories,
  getMovieData,
  getMoviesByCategory,
  getTvListings,
  getTvShowData,
  getTvShowsByCategory,
  getPlaylists,
  getPlaylistVideos,
  getPlaylistVideosByName,
  createOrUpdatePlaylist,
  createOrUpdateVideo,
  signOut,
  getPlaylist,
  getPlayback,
  getMovie,
};
