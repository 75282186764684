import { useContext } from 'react';

import { APIErrorContext } from '#/context/ApiErrorContext';

function useApiError() {
  const { error, addError, removeError, errorDetails } = useContext(APIErrorContext);

  return { error, addError, removeError, errorDetails };
}

export default useApiError;
