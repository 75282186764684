import React, { useCallback, useContext, useState, useRef, useMemo, useEffect } from 'react';

import { focusManager } from '@accedo/vdkweb-navigation';
import PropTypes from 'prop-types';

import AssetBackground from '#/components/AssetBackground/AssetBackground';
import VerticalLayout from '#/components/Layout/VerticalLayout';
import Page from '#/components/Page/Page';
import VerticalScroll from '#/components/VerticalScroll/VerticalScroll';
import { ACCEDO_CONTROL_CONTAINER_TEMPLATES, ANALYTICS_SCREEN_TYPES } from '#/config/constants';
import Container from '#/containers/Container/Container';
import { AuthContext } from '#/context/AuthContext';
import useApiError from '#/hooks/useApiError';
import useBookmarks from '#/hooks/useBookmarks';
import useMenu from '#/hooks/useMenu';
import { R720p } from '#/theme/variables/breakpoints';
import getResolution from '#/utils/getResolution';
import { navIdMap, getIds, changeItemFocused } from '#/utils/navigationHelper';
import vw from '#/utils/vw';

const NOOP_OBJECT = {};

// container array used for generating nav id
let navFilterContainers = [];

// here the elements in the view that should not be scrolled in the grid
const NOT_IN_GRID_ELEMENTS = ['elevate-filter-sort'];
const { width } = getResolution();
const DEFAULT_CENTER_MARGIN = width > R720p ? -250 : -160;

let gridInView = false;

const getAvailableContainers = ({ containers, bookmarks, isAuthenticated }) => {
  gridInView = containers.some((item) => item.template?.includes('grid'));
  return containers.filter((container) => {
    const isContinueWatching = Boolean(container.template === ACCEDO_CONTROL_CONTAINER_TEMPLATES.continueWatching);
    const isJumpRecommendation = Boolean(
      container.template === ACCEDO_CONTROL_CONTAINER_TEMPLATES.carouselJumpRecommendation,
    );

    if (isContinueWatching && bookmarks) {
      // TODO: continue watching items must be required by query
      // eslint-disable-next-line no-param-reassign -- TODO: Automatically surpressed error. Resolve when you encounter this file!
      container.items = bookmarks;
    }

    // The 'Jump' recommendation containers only will be visible when the user is logged
    if (!isAuthenticated && isJumpRecommendation) {
      return false;
    }

    return (
      container.component ||
      container.query ||
      container.items.length ||
      container.advanceAlgoliaQuery ||
      container.genreBasedContent
    );
  });
};

const pageNav = ({ menuVisible: isMenuVisible, pageProps }) => ({
  id: pageProps.id || navIdMap.PAGE.CONTAINER.PAGE,
  nextup: isMenuVisible ? navIdMap.MENU.HEADER.CONTAINER : null,
  forwardFocus: navIdMap.PAGE.CONTAINER.LAYOUT,
});

const layoutNav = ({ id: pageId }) => ({
  id: navIdMap.PAGE.CONTAINER.LAYOUT,
  parent: pageId,
});

const getLayoutComponents = ({
  availableContainers,
  contextData,
  onGridChange,
  gridRef,
  removeEmptyContainers,
  emptyContainers,
  addError,
}) => {
  const containers = [];
  navFilterContainers = [...availableContainers];
  // eslint-disable-next-line no-unused-expressions -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  availableContainers?.forEach((container, index) => {
    if (emptyContainers.includes(container.id)) {
      navFilterContainers.splice(index, 1);
    } else {
      let useInternalArrows;
      if (gridInView) {
        // for those pages with elements + grid
        gridInView = true;
        useInternalArrows = false;
      }
      const containerProps = container.props || { ...container };
      const containerParsed = {
        Component: container.component || Container,
        props: {
          ...containerProps,
          contextData, // used by shelfs to interpolate queries if needed
          index,
          nav: {
            id: container.id,
            ...containerProps.nav,
          },
          moreItems: true,
          gridConfig: {
            useInternalArrows,
            gridRef,
            onChange: onGridChange,
            tailPadding: 300,
          },
          removeEmptyContainers,
        },
      };
      containers.push(containerParsed);
    }
  }) || [];

  const hasComponents = Boolean(containers.length);

  if (availableContainers.length && !hasComponents) {
    addError();
  }

  return [...containers];
};

function ContainerPage({
  analyticsProps: providedAnalyticsProps,
  backgroundUrl,
  displayText,
  containers,
  contextData,
  menuVisible = true,
  pageProps = NOOP_OBJECT,
  scrollMargin = DEFAULT_CENTER_MARGIN,
  forceScrollMargin,
  pageTitle,
}) {
  const { showMenu, hideMenu } = useMenu({ menuVisible });
  const authContext = useContext(AuthContext);
  const userName = authContext?.getUser()?.username;
  const { userBookmarks } = useBookmarks({
    isAuthenticated: true, // TomK: For bookmarks we don't care
    userName,
  });
  const [containersData, setContainersData] = useState([]);
  const [emptyContainers, setEmptyContainers] = useState([]);

  const availableContainers = getAvailableContainers({
    containers: containersData,
    bookmarks: userBookmarks,
    isAuthenticated: authContext.isAuthenticated,
  });
  const gridRef = useRef({});
  const verticalPositionRef = useRef({});
  const [currentGridState, setCurrentGridState] = useState({});
  const [verticalPosition, setVerticalPosition] = useState(0);

  useEffect(() => setContainersData(containers), [containers]);

  const { addError } = useApiError();

  const analyticsProps = useMemo(
    () => ({
      screenTitle: displayText || pageTitle,
      screenType: ANALYTICS_SCREEN_TYPES.page,
      ...providedAnalyticsProps,
    }),
    [displayText, pageTitle, providedAnalyticsProps],
  );

  verticalPositionRef.current = verticalPosition;

  const containerIds = getIds(navFilterContainers);

  const nonScrollableContainer = availableContainers.filter((container) =>
    NOT_IN_GRID_ELEMENTS.includes(container.template),
  );

  const onGridChange = useCallback((state) => {
    setCurrentGridState({
      currentHead: state?.edge?.head,
      currentTail: state?.edge?.tail,
    });
  }, []);

  const pageTopFunction = (navId) => {
    let margin = 0;
    const currentFocusContainer = document.getElementById(navId);

    // if the target element is not the non scrollable element
    // we apply some margin.
    if (
      forceScrollMargin ||
      containers.length !== 1 ||
      (nonScrollableContainer.length && nonScrollableContainer[0].id !== navId)
    ) {
      margin = scrollMargin;
    }
    const top =
      currentFocusContainer && currentFocusContainer.offsetTop > 0 ? -currentFocusContainer.offsetTop - margin : 0;
    setVerticalPosition(top);
    return {
      position: top,
    };
  };

  const manualScrollUp = useCallback(() => {
    if (!gridInView) {
      return false;
    }
    let handle = true;
    if (currentGridState.currentHead) {
      handle = false;
    } else {
      gridRef.current.page(-1);
    }
    return handle;
  }, [currentGridState.currentHead]);

  const manualScrollDown = React.useCallback(() => {
    if (!gridInView) {
      return false;
    }
    let handle = true;
    if (parseInt(verticalPositionRef.current, 10) === 0) {
      handle = false;
    } else {
      gridRef.current.page(1);
    }
    return handle;
  }, []);

  const enableUpArrow = () => {
    if (gridInView) {
      return !currentGridState.currentHead || verticalPosition !== 0;
    }
    return undefined; // relies on vertical scroll component
  };

  const enableDownArrow = () => {
    if (gridInView) {
      return !currentGridState.currentTail;
    }
    return undefined; // relies on vertical scroll component
  };

  const onForwardDidMount = useCallback(() => {
    focusManager.changeFocus(pageNav({ menuVisible, pageProps }).id);
    // eslint-disable-next-line no-unused-expressions -- TODO: Automatically surpressed error. Resolve when you encounter this file!
    pageProps.onForwardDidMount && pageProps.onForwardDidMount();
  }, [menuVisible, pageProps]);

  const onBackwardDidMount = useCallback(
    (latestState) => {
      // eslint-disable-next-line no-unused-expressions -- TODO: Automatically surpressed error. Resolve when you encounter this file!
      pageProps.onBackwardDidMount && pageProps.onBackwardDidMount(latestState);
    },
    [pageProps],
  );

  const onForwardWillUnmount = useCallback(
    (id) => {
      // eslint-disable-next-line no-unused-expressions -- TODO: Automatically surpressed error. Resolve when you encounter this file!
      pageProps.onForwardWillUnmount && pageProps.onForwardWillUnmount(id);
    },
    [pageProps],
  );

  const onBackwardWillUnmount = useCallback(
    (id) => {
      // eslint-disable-next-line no-unused-expressions -- TODO: Automatically surpressed error. Resolve when you encounter this file!
      pageProps.onForwardWillUnmount && pageProps.onBackwardWillUnmount(id);
    },
    [pageProps],
  );

  const navIds = menuVisible ? [navIdMap.MENU.HEADER.CONTAINER, ...containerIds] : [...containerIds];

  const addEmptyContainers = useCallback(
    (id) => {
      setEmptyContainers((oldEmptyContainers) => {
        if (!oldEmptyContainers.includes(id)) {
          return [...oldEmptyContainers, id];
        }
        return oldEmptyContainers;
      });
    },
    [setEmptyContainers],
  );

  const removeEmptyContainers = useCallback(
    (id) => {
      addEmptyContainers(id);
    },
    [addEmptyContainers],
  );

  const components = getLayoutComponents({
    availableContainers,
    contextData,
    onGridChange,
    gridRef,
    removeEmptyContainers,
    emptyContainers,
    addError,
  });

  // pageConfig
  const pageNavObject = pageNav({ menuVisible, pageProps });
  const saveFocusedElementOnUnmount =
    pageProps.saveFocusedElementOnUnmount !== undefined ? pageProps.saveFocusedElementOnUnmount : true;
  const restoreLastFocusedElementOnMount =
    pageProps.restoreLastFocusedElementOnMount !== undefined ? pageProps.restoreLastFocusedElementOnMount : true;

  useEffect(() => {
    if (emptyContainers?.length > 0) {
      // It puts the focus on the first available element after the containers array has changed
      const firstElementFocusedID = changeItemFocused(
        containersData,
        emptyContainers,
        authContext,
        userBookmarks,
        ACCEDO_CONTROL_CONTAINER_TEMPLATES.continueWatching,
      );

      focusManager.changeFocus(`${firstElementFocusedID}`);
    }
    // The "containersData" is changing all the time, for that,
    // we can not the focus change dependent on the "containersData"
    // If we add "containersData" as another dependency, in the Detail view,
    // the vertical scroll does not work
    // eslint-disable-next-line prettier/prettier -- TODO: Automatically surpressed error. Resolve when you encounter this file!
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  }, [emptyContainers, authContext, userBookmarks]);

  return (
    <>
      {backgroundUrl && <AssetBackground backgroundUrl={backgroundUrl} />}
      {displayText && <div style={{ marginTop: vw(150), marginLeft: vw(60), fontSize: vw(30) }}>{displayText}</div>}

      <VerticalScroll
        parent={pageNavObject.id}
        pageTopFunction={pageTopFunction}
        navIds={navIds}
        hideMenu={hideMenu}
        menuVisible={menuVisible}
        showMenu={showMenu}
        manualScrollDown={manualScrollDown}
        manualScrollUp={manualScrollUp}
        enableDownArrow={enableDownArrow()}
        enableUpArrow={enableUpArrow()}
        forceScrollMargin={forceScrollMargin}
      >
        <Page
          className={pageProps.className}
          id={pageNavObject.id}
          analyticsProps={analyticsProps}
          nav={pageNavObject}
          onBackwardDidMount={onBackwardDidMount}
          onForwardDidMount={onForwardDidMount}
          onForwardWillUnmount={onForwardWillUnmount}
          onBackwardWillUnmount={onBackwardWillUnmount}
          saveFocusedElementOnUnmount={saveFocusedElementOnUnmount}
          restoreLastFocusedElementOnMount={restoreLastFocusedElementOnMount}
        >
          <VerticalLayout components={components} nav={layoutNav(pageProps)} />
        </Page>
      </VerticalScroll>
    </>
  );
}

ContainerPage.propTypes = {
  analyticsProps: PropTypes.shape({
    /** Analytics screenTitle prop: Title of the page */
    screenTitle: PropTypes.string,
    /** Analytics screenType prop: Type of the page */
    screenType: PropTypes.string,
    /** Asset Properties */
    contentId: PropTypes.string,
    description: PropTypes.string,
    duration: PropTypes.string,
    episodeNumber: PropTypes.number,
    publishDate: PropTypes.string,
    genre: PropTypes.string,
    seasonNumber: PropTypes.number,
    title: PropTypes.string,
  }),
  backgroundUrl: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  contextData: PropTypes.object,
  containers: PropTypes.arrayOf(
    PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
      items: PropTypes.array,
      id: PropTypes.string,
      template: PropTypes.string,
      query: PropTypes.string,
      /* list of nav ids for the container */
      containerNavIds: PropTypes.arrayOf(PropTypes.string),
      /* inner props for the Component (nav, ...) */
      // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
      props: PropTypes.object,
    }),
  ),
  displayText: PropTypes.string,
  menuVisible: PropTypes.bool,
  pageProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    restoreLastFocusedElementOnMount: PropTypes.bool,
    saveFocusedElementOnUnmount: PropTypes.bool,
    onBackwardDidMount: PropTypes.func,
    onForwardDidMount: PropTypes.func,
    onForwardWillUnmount: PropTypes.func,
    onBackwardWillUnmount: PropTypes.func,
  }).isRequired,
  scrollMargin: PropTypes.number,
  forceScrollMargin: PropTypes.bool,
  pageTitle: PropTypes.string,
};

export default ContainerPage;
