/**
 * @module providers/i18n/local
 */
import dictionaries from './dictionaries.mock';
import locales from './locales.mock.json';

export const defaultLocale = 'en';

/**
 * Returns the default locale code of the application, when no selected
 *
 * @returns {Promise<String>} default locale code
 */
const getDefaultLocaleCode = async () => Promise.resolve(defaultLocale);

/**
 * Returns all the available dictionaries/locales of the application
 *
 * @returns {Promise<String[]>} All the application local codes
 */
const getLocales = async () => locales;

/**
 * Returns an specific dictionary based on a locale code
 *
 * @param {String} localeCode locale code to get the Locale/Dictionary from
 *
 * @returns {Promise<Object>} locale associated
 */
const getLocale = async (localeCode) => dictionaries[localeCode];

export default { getDefaultLocaleCode, getLocales, getLocale };
export { locales, dictionaries };
