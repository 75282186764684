/**
 * @module services/movie
 * @description
 * Service to handle the fetch of movies
 */

import { PROVIDER_TYPE } from '#/config/constants';
import ovp from '#/providers/ovp';

import fetcher from '../../helpers/fetcher';

const getMovie = async (id, brightcoveProxyUrl) => {
  const response = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-movie-${id}`,
    fetchFn: () => ovp.getMovie(id, brightcoveProxyUrl),
  });

  return response;
};
// eslint-disable-next-line import/prefer-default-export -- TODO: Automatically surpressed error. Resolve when you encounter this file!
export { getMovie };
