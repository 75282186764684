import app from './app';
import env from './env';

/*
 * Exporting the aggregated configurations.
 */
export default {
  app,
  env,
};
