import React, { useEffect } from 'react';

import { focusManager } from '@accedo/vdkweb-navigation';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import config from '#/config';
import TVAppContent from '#/containers/App/TVAppContent';
import { init as initAnalytics, exit } from '#/services/analytics';

const { title, titleTemplate, meta } = config.app.head;
let lastValidFocus = '';

function restoreLastFocus() {
  if (lastValidFocus && !focusManager.isValidFocusId(focusManager.currentFocus)) {
    focusManager.changeFocus(lastValidFocus);
  }
}

// An application shell for listening to mount
// events and trigger appropriate lifecycle
// actions for your app. These would typically
// be used for analytics and similar.
function AppShell(props) {
  useEffect(() => {
    initAnalytics(); // analytics

    focusManager.listenToFocusChanged((data) => {
      if (focusManager.isValidFocusId(data.currentFocus)) {
        lastValidFocus = data.currentFocus;
      }
    });

    return () => {
      exit(); // analytics
    };
  }, []);

  const helmet = (
    <Helmet
      defaultTitle={title}
      titleTemplate={titleTemplate}
      meta={meta}
      style={[
        {
          type: 'text/css',
          cssText: `
                  body, html {
                      width: 100%;
                      height: 100%;
                  }
              `,
        },
      ]}
    />
  );

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events -- TODO: Automatically surpressed error. Resolve when you encounter this file!
    <div onMouseOut={restoreLastFocus}>
      {helmet}
      <TVAppContent {...props} />
    </div>
  );
}

AppShell.propTypes = {
  dispatch: PropTypes.func,
};

export default AppShell;
