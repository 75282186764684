import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';

import { LoginFields, CognitoUserPoolDetails } from './types';
import { Token } from './utils/Token.enum';
import { expiredToken, getRefreshToken } from './utils/helpers';

export async function getSession({ userpoolId, clientId }: CognitoUserPoolDetails): Promise<boolean> {
  const poolData = {
    UserPoolId: userpoolId,
    ClientId: clientId,
  };
  const idToken = localStorage.getItem(Token.IdToken);
  const accessToken = localStorage.getItem(Token.AccessToken);
  const refreshToken = localStorage.getItem(Token.RefreshToken);

  if (!idToken || !accessToken || !refreshToken) {
    return Promise.resolve(false);
  }

  const userPool = new CognitoUserPool(poolData);
  const user = userPool.getCurrentUser();
  const userData = { Username: user?.getUsername() as string, Pool: userPool };
  const cognitoUser = new CognitoUser(userData);

  const sessionData = {
    IdToken: new CognitoIdToken({ IdToken: idToken }),
    AccessToken: new CognitoAccessToken({ AccessToken: accessToken }),
    RefreshToken: new CognitoRefreshToken({ RefreshToken: refreshToken }),
  };

  const cognitoUserSession = new CognitoUserSession(sessionData);
  cognitoUser.setSignInUserSession(cognitoUserSession);

  return new Promise((resolve, reject) => {
    if (cognitoUserSession.isValid()) {
      resolve(true);
    } else {
      reject();
    }
  });
}

export async function loginCognito(
  { email, password }: LoginFields,
  { userpoolId, clientId }: CognitoUserPoolDetails,
): Promise<CognitoUserSession> {
  const poolData = {
    UserPoolId: userpoolId,
    ClientId: clientId,
  };

  const userPool = new CognitoUserPool(poolData);
  const authenticationData = { Username: email, Password: password };
  const userData = { Username: email, Pool: userPool };
  const authenticationDetails = new AuthenticationDetails(authenticationData);
  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (session) => {
        const idToken = session.getIdToken().getJwtToken();
        const accessToken = session.getAccessToken().getJwtToken();
        const refreshToken = session.getRefreshToken().getToken();
        localStorage.setItem(Token.IdToken, idToken);
        localStorage.setItem(Token.AccessToken, accessToken);
        localStorage.setItem(Token.RefreshToken, refreshToken);

        resolve(session);
      },
      onFailure: (error) => {
        reject(error);
      },
    });
  });
}

export async function logoutCognito({ userpoolId, clientId }: CognitoUserPoolDetails) {
  const poolData = {
    UserPoolId: userpoolId,
    ClientId: clientId,
  };
  const userPool = new CognitoUserPool(poolData);
  const currentUser = userPool.getCurrentUser();

  localStorage.removeItem(Token.IdToken);
  localStorage.removeItem(Token.AccessToken);
  localStorage.removeItem(Token.RefreshToken);

  currentUser?.signOut();
}

type AccountInfo = { email: string; given_name: string };

export async function accountInfo({ userpoolId, clientId }: CognitoUserPoolDetails): Promise<AccountInfo | false> {
  let idToken;
  let accessToken;
  let refreshToken;
  idToken = localStorage.getItem(Token.IdToken);
  accessToken = localStorage.getItem(Token.AccessToken);
  refreshToken = localStorage.getItem(Token.RefreshToken);

  const expired = expiredToken();

  if (expired) {
    await getRefreshToken({ userpoolId, clientId });

    idToken = localStorage.getItem(Token.IdToken);
    accessToken = localStorage.getItem(Token.AccessToken);
    refreshToken = localStorage.getItem(Token.RefreshToken);
  }

  if (!idToken || !accessToken || !refreshToken) {
    return Promise.resolve(false);
  }

  const poolData = {
    UserPoolId: userpoolId,
    ClientId: clientId,
  };

  const userPool = new CognitoUserPool(poolData);
  const user = userPool.getCurrentUser();
  const userData = { Username: user?.getUsername() as string, Pool: userPool };
  const cognitoUser = new CognitoUser(userData);

  const sessionData = {
    IdToken: new CognitoIdToken({ IdToken: idToken }),
    AccessToken: new CognitoAccessToken({ AccessToken: accessToken }),
    RefreshToken: new CognitoRefreshToken({ RefreshToken: refreshToken }),
  };

  const cognitoUserSession = new CognitoUserSession(sessionData);
  cognitoUser.setSignInUserSession(cognitoUserSession);

  return new Promise((resolve, reject) => {
    cognitoUser.getUserAttributes((error, attributes) => {
      if (error) {
        reject(error);
      } else {
        const account: AccountInfo = { email: '', given_name: '' };

        attributes?.forEach((attribute) => {
          const { Name, Value } = attribute;

          switch (Name) {
            case 'email':
              account.email = Value || '';
              break;
            case 'given_name':
              account.given_name = Value || '';
              break;
            default:
              break;
          }
        });

        resolve(account);
      }
    });
  });
}
