import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import useAlgoliaContainerItems from '#/hooks/useAlgoliaContainerItems';

import ContainerPage from './ContainerPage';

function AlgoliaPageDataWrapper({ containers, ...otherProps }) {
  const [containersUpdated, setContainersUpdated] = useState(false);
  const modifiedContainers = useAlgoliaContainerItems(containers);

  useEffect(() => {
    if (modifiedContainers) {
      setContainersUpdated(true);
    }
  }, [modifiedContainers]);

  if (!containersUpdated) {
    return null;
  }

  return <ContainerPage containers={modifiedContainers} {...otherProps} />;
}

AlgoliaPageDataWrapper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  containers: PropTypes.array,
};

export default AlgoliaPageDataWrapper;
