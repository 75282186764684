import xdk, { device } from '@accedo/xdk-core';

const checkLegacyDevice = () => {
  const deviceYear = xdk.system.getDeviceYear();
  const { platform } = device;

  document.documentElement.setAttribute('data-device', `${platform}_${deviceYear}`);
};

export default checkLegacyDevice;
