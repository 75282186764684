import React, { useCallback, useEffect, useContext } from 'react';

import { focusManager } from '@accedo/vdkweb-navigation';
import * as xdk from '@accedo/xdk-core';
import { vKey } from '@accedo/xdk-virtual-key';
import T from 'prop-types';
import { useRoutes, useLocation } from 'react-router-dom';

import ErrorPopup from '#/components/ErrorPopup/ErrorPopup';
import TVHeader from '#/components/Header/TVHeader';
import { ROUTES } from '#/config/constants';
import routes from '#/config/routes';
import { ConfigContext } from '#/context/ConfigContext';
import useGetHistoryStack from '#/hooks/history/useGetHistoryStack';
import useHistoryBack from '#/hooks/history/useHistoryBack';
import useHistoryPush from '#/hooks/history/useHistoryPush';
import useResetStack from '#/hooks/history/useResetStack';
import useAppConfig from '#/hooks/useAppConfig';
import useKeyHandler from '#/hooks/useKeyHandler';

import styles from './app.scss';

const LANDING_PATHS = ['/']; // TODO: dev review this with your CMS config

const isCurrentPathHome = (pathname) => LANDING_PATHS.includes(pathname);

function App({ platform: xdkPlatform }) {
  const historyPush = useHistoryPush();
  const historyBack = useHistoryBack();
  const { pathname } = useLocation();
  const getHistoryStack = useGetHistoryStack();
  const resetStack = useResetStack();
  const { config: { routesMapping = [] } = {} } = useAppConfig();
  const configContext = useContext(ConfigContext);
  const { kidsMode } = configContext;

  const exitAction = useCallback(() => {
    try {
      xdk.device.system.exit();
    } catch (e) {
      console.warn(e);
    }
  }, []);

  const keyHandler = useCallback(
    ({ id }) => {
      const { UP, DOWN, LEFT, RIGHT, OK, BACK, EXIT } = vKey;
      const KEY_EVENT_PREFIX = 'device:vkey:';
      const isHistoryEmpty = !getHistoryStack().length;
      const isHome = isCurrentPathHome(pathname);

      // if the current component handles the pressed key
      // the deafult handler does nothing
      switch (id) {
        case UP.id:
        case DOWN.id:
        case LEFT.id:
        case RIGHT.id: {
          const direction = `next${id.substring(KEY_EVENT_PREFIX.length)}`;
          focusManager.navigateFocus(direction);
          break;
        }
        case OK.id:
          focusManager.click();
          break;
        case BACK.id:
          if (isHistoryEmpty) {
            if (!isHome && !kidsMode) {
              historyPush({ path: ROUTES.home });
              resetStack();
            } else {
              exitAction();
            }
          } else {
            historyBack();
          }
          break;
        case EXIT.id:
          exitAction();
          break;
        default:
          break;
      }
    },
    [getHistoryStack, pathname, exitAction, kidsMode, historyPush, resetStack, historyBack],
  );
  useKeyHandler(keyHandler);

  useEffect(() => {
    const appBody = document.getElementsByTagName('body')[0];
    appBody.style.removeProperty('background-color');
  }, []);

  const routes_ = useRoutes(routes(xdkPlatform, routesMapping));

  return (
    <div className={styles.viewport}>
      <div className={styles.wrapper}>
        <ErrorPopup />
        <TVHeader />
        <div className={styles.content}>{routes_}</div>
      </div>
    </div>
  );
}

App.propTypes = {
  platform: T.string,
};

export default App;
