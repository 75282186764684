/**
 * @module services/episode
 * @description
 * Service to handle the fetch of episodes
 */

import fallbackEpisode from '#/__mocks__/fallbackEpisode';
import { PROVIDER_TYPE } from '#/config/constants';
import Episode from '#/models/episode/episode';
import ovp from '#/providers/ovp';
import { getPlaylist } from '#/services/ovp';
import { validDateEntry } from '#/utils/helpers';

import fetcher from '../../helpers/fetcher';
// eslint-disable-next-line jest/no-mocks-import -- TODO: Automatically surpressed error. Resolve when you encounter this file!
/* eslint-disable-next-line jest/no-mocks-import -- TODO: Automatically surpressed error. Resolve when you encounter this file!, TODO: Automatically surpressed error. Resolve when you encounter this file! */
// eslint-disable-next-line jest/no-mocks-import

/**
 * Cache TV Shows
 * @type {Object}
 */
const tvShows = {};

/**
 * Get the TV Show from a ID
 * @param {Object} rawEpisode Raw Episode
 * @returns {Promise<Object>} TV Show
 */
const getTvShow = async (rawEpisode) => {
  const { metadata } = rawEpisode;
  const showId = metadata.find((item) => item.name === 'VOD$tvShowId');
  if (tvShows[showId.value]) {
    return tvShows[showId.value];
  }

  const tvShowPayload = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-show-${showId.value}`,
    fetchFn: () => ovp.getTvShowById(showId.value),
  });

  const { credits, categories, images, tvSeasonCount, description } = tvShowPayload;

  tvShows[showId.value] = {
    credits,
    categories,
    images,
    tvSeasonCount,
    tvShowDescription: description,
  };

  return tvShows[showId.value];
};

/**
 * Get episodes from a season
 * @param {String|number} id Season Id
 * @returns {Promise<Array<Episode>>} Episodes
 */
const getEpisodes = async (id) => {
  const rawEpisodes = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-season-episodes-${id} `,
    fetchFn: () => ovp.getTvSeasonEpisodesById(id),
  });

  const episodes = await Promise.all(
    rawEpisodes.entries.map(async (rawEpisode) => {
      const rawTvShow = await getTvShow(rawEpisode);

      const fullRawEpisode = {
        ...rawTvShow,
        ...rawEpisode,
      };

      return Episode(fullRawEpisode);
    }),
  );
  episodes.sort((episodeA, episodeB) => episodeA.episodeNumber - episodeB.episodeNumber);
  return episodes;
};

const getFallbackEpisodes = (numberOfEpisodes) => {
  const fallbackEpisodes = [];
  for (let i = 0; i < numberOfEpisodes; i += 1) {
    fallbackEpisode.id = `${Math.random()}-${fallbackEpisode.id}`;
    fallbackEpisodes.push(Episode(fallbackEpisode));
  }
  return fallbackEpisodes;
};

/**
 * Get an episode
 * @param {String|number} id Episode Id
 * @returns {Promise<Episode>} Episode
 */
const getEpisode = async (id) => {
  const rawEpisode = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-episode-${id}`,
    fetchFn: () => ovp.getEpisodeById(id),
  });
  if (!rawEpisode) {
    return null;
  }
  const rawTvShow = await getTvShow(rawEpisode);
  const fullRawEpisode = {
    ...rawEpisode,
    ...rawTvShow,
  };

  return Episode(fullRawEpisode);
};

const getCurrentSeasonIndex = (episode, seasons) => {
  const currentEpisodeSeasonId = episode.playlistId;
  const seasonIds = seasons.map((seasonItem) => seasonItem.playlistId);

  return seasonIds.indexOf(currentEpisodeSeasonId);
};

const getCurrentEpisodeIndex = (episode, episodes) => {
  const currentEpisodeNumber = episode.custom_fields?.episode_number;
  const episodeNumbers = episodes.map((episodeItem) => episodeItem.custom_fields?.episode_number);

  return episodeNumbers.indexOf(currentEpisodeNumber);
};

const getPrevEpisodeFromPrevSeason = async (episode, seasons, brightcoveProxyUrl) => {
  const currentSeasonIndex = getCurrentSeasonIndex(episode, seasons);
  if (currentSeasonIndex > 0) {
    const previousSeason = seasons[currentSeasonIndex - 1];
    const previousSeasonEpisodes = await getPlaylist({
      seasonId: previousSeason.playlistId,
      brightcoveProxyUrl,
      showId: episode.showId,
    });
    previousSeasonEpisodes.filter((item) => validDateEntry(item.schedule).valid);
    return previousSeasonEpisodes[previousSeasonEpisodes.length - 1];
  }
  return null;
};

const getPrevEpisode = (episode, episodes, seasons, brightcoveProxyUrl) => {
  const currentEpisodeIndex = getCurrentEpisodeIndex(episode, episodes);
  if (currentEpisodeIndex > 0) {
    return episodes[currentEpisodeIndex - 1];
  }
  return getPrevEpisodeFromPrevSeason(episode, seasons, brightcoveProxyUrl);
};

const getEpisodesFromPrevSeason = async (episode, seasons, brightcoveProxyUrl) => {
  const currentSeasonIndex = seasons.findIndex((season) => season.playlistId === episode.playlistId);
  const prevSeason = seasons[currentSeasonIndex - 1] ? seasons[currentSeasonIndex - 1] : seasons[0];
  const prevSeasonEpisodes = await getPlaylist({
    seasonId: prevSeason.playlistId,
    brightcoveProxyUrl,
    showId: episode.showId,
  });

  return prevSeasonEpisodes.filter((item) => validDateEntry(item.schedule).valid);
};

const getPrevEpisodes = (episode, episodes, seasons, brightcoveProxyUrl) => {
  const currentEpisodeIndex = getCurrentEpisodeIndex(episode, episodes);
  if (currentEpisodeIndex > 0) {
    return episodes;
  }
  return getEpisodesFromPrevSeason(episode, seasons, brightcoveProxyUrl);
};

const getNextEpisodeFromNextSeason = async (episode, seasons, brightcoveProxyUrl) => {
  const currentSeasonIndex = getCurrentSeasonIndex(episode, seasons);
  if (currentSeasonIndex < seasons.length - 1) {
    const nextSeason = seasons[currentSeasonIndex + 1];
    const nextSeasonEpisodes = await getPlaylist({
      seasonId: nextSeason.playlistId,
      brightcoveProxyUrl,
      showId: episode.showId,
    });
    nextSeasonEpisodes.filter((item) => validDateEntry(item.schedule).valid);
    return nextSeasonEpisodes[0];
  }
  return null;
};

const getNextEpisode = (episode, episodes, seasons, brightcoveProxyUrl) => {
  const currentEpisodeIndex = getCurrentEpisodeIndex(episode, episodes);
  if (currentEpisodeIndex < episodes.length - 1) {
    return episodes[currentEpisodeIndex + 1];
  }
  return getNextEpisodeFromNextSeason(episode, seasons, brightcoveProxyUrl);
};

const getEpisodesFromNextSeason = async (episode, seasons, brightcoveProxyUrl) => {
  const currentSeasonIndex = seasons.findIndex((season) => season.playlistId === episode.playlistId);
  const nextSeason = seasons[currentSeasonIndex + 1] ? seasons[currentSeasonIndex + 1] : seasons[0];
  const nextSeasonEpisodes = await getPlaylist({
    seasonId: nextSeason.playlistId,
    brightcoveProxyUrl,
    showId: episode.showId,
  });

  return nextSeasonEpisodes.filter((item) => validDateEntry(item.schedule).valid);
};

const getNextEpisodes = (episode, episodes, seasons, brightcoveProxyUrl) => {
  const currentEpisodeIndex = getCurrentEpisodeIndex(episode, episodes);
  if (currentEpisodeIndex < episodes.length - 1) {
    return episodes;
  }
  return getEpisodesFromNextSeason(episode, seasons, brightcoveProxyUrl);
};

export {
  getEpisode,
  getEpisodes,
  getNextEpisode,
  getPrevEpisode,
  getFallbackEpisodes,
  getNextEpisodes,
  getPrevEpisodes,
};
