import React, { useMemo, useContext } from 'react';

import PropTypes from 'prop-types';

import {
  ACCEDO_CONTROL_CONTAINER_TEMPLATES,
  CONTAINER_ID_TEMPLATE_MAP,
  CONTAINER_ITEM_TYPES,
  DEFAULT_TEMPLATE,
  ROUTES,
  CONTENT_TYPE,
} from '#/config/constants';
import { AuthContext } from '#/context/AuthContext';
import useHistoryPush from '#/hooks/history/useHistoryPush';
import useAppConfig from '#/hooks/useAppConfig';
import { getItemsByQuery } from '#/services/ovp';
import logger from '#/utils/logger';

import { CONTAINER_ID_TO_COMPONENT_MAP, determineItemTypeByTemplate, determineAligment } from './templatesMapper';

const getItemOnClickFn =
  ({ historyPush }) =>
  (item) => {
    const {
      type,
      viewAll,
      query,
      displayText,
      _meta: { id },
      contentType,
      actiondata,
      action,
    } = item;
    const isContinueWatching = type === CONTAINER_ITEM_TYPES.Bookmark;
    const isLive = type === CONTAINER_ITEM_TYPES.Live;
    const detailPaths = {
      [CONTENT_TYPE.TV_SHOW]: `${ROUTES.showDetails}/${id}`,
      [CONTENT_TYPE.MOVIE]: `${ROUTES.movieDetails}/${id}`,
    };
    let path = detailPaths[contentType];
    if (!path) {
      path = action ? `/${action}` : '';
      if (actiondata && action !== actiondata) {
        path += `/${actiondata}`;
      }
    }

    if (isLive) {
      const { curProgram } = item;
      logger.debug('Redirect to: player');
      historyPush({
        path: ROUTES.player,
        state: {
          asset: curProgram,
          isLive: true,
          videoUrl: curProgram.videoUrl,
        },
      });
    } else if (isContinueWatching) {
      logger.debug('Redirect to: player');
      historyPush({
        path: ROUTES.player,
        state: {
          asset: item,
          resumeTime: item.resumeTime,
          episodes: item?.seasonEpisodes,
          seasons: item?.seriesSeasons,
          videoUrl: item.videoUrl,
        },
      });
    } else if (viewAll) {
      // view all
      historyPush({
        path: ROUTES.viewAll,
        state: {
          query,
          displayText,
          pageTitle: displayText,
        },
      });
    } else {
      logger.debug(`Redirect to: ${id}`);
      historyPush({
        path,
        state: {
          data: item,
        },
      });
    }
  };

function Shelf({ contextData, items: defaultItems, query, template, config, nav, removeEmptyContainers }) {
  const { config: appConfig = {} } = useAppConfig();

  const historyPush = useHistoryPush();

  if (!CONTAINER_ID_TEMPLATE_MAP[template]) {
    logger.warn(`Template "${template}" is not supported`);
  }

  const containerId = CONTAINER_ID_TEMPLATE_MAP[template] || CONTAINER_ID_TEMPLATE_MAP[DEFAULT_TEMPLATE];

  const ShelfComponent = CONTAINER_ID_TO_COMPONENT_MAP[containerId] || CONTAINER_ID_TO_COMPONENT_MAP.Default;

  const itemType = determineItemTypeByTemplate(template);
  const aligment = determineAligment(template);

  const authContext = useContext(AuthContext);
  const userId = authContext.getUser()?.userId;

  const dataSource = useMemo(() => {
    let totalNumber;
    const hasViewAll = template === ACCEDO_CONTROL_CONTAINER_TEMPLATES.carouselViewAllPortrait;

    // The userId is needed to get the Jump recommendations
    const contextDataUpdated = { ...contextData, userId };

    return {
      hasData: async () => !!totalNumber,
      isPaginationAllowed: async () => !hasViewAll, // no pagination allowed for this template
      getTotalNumber: async () => totalNumber,
      getData: async (
        page,
        // eslint-disable-next-line default-param-last
        itemsPerPage = appConfig.ovpPageSize,
        lastIndex,
      ) => {
        const { items, total } = await getItemsByQuery({
          query,
          template,
          contextData: contextDataUpdated,
          pageNumber: page,
          itemsPerPage,
        });
        totalNumber = total;
        if (hasViewAll) {
          // we include a new item in the position of the lastIndex from the ContentGrid and push the rest in the list
          // ie: ['0', '1', '2', '3'] => ['0', '1', 'viewall', '2',]
          const startIndex = lastIndex ? lastIndex - 1 : items.length - 1;
          const itemToPush = {
            viewAll: true,
            query,
            displayText: config.displayText,
            id: `${template}-${Math.random()}`,
          };
          items?.splice(startIndex, 0, itemToPush);
          // Limit the totalNumber to the position of the viewAll item to avoid Navigation issues
          totalNumber = lastIndex + 1;
        }

        if (!items?.length && page <= 1) {
          removeEmptyContainers();
        }

        return {
          data: items,
          total: totalNumber,
        };
      },
    };
  }, [template, userId, contextData, appConfig.ovpPageSize, query, config.displayText, removeEmptyContainers]);

  return (
    <ShelfComponent
      ds={defaultItems.length ? null : dataSource}
      items={defaultItems.length ? defaultItems : null}
      itemType={itemType}
      gridAlignment={aligment}
      config={config}
      onClick={getItemOnClickFn({
        historyPush,
      })}
      nav={nav}
      query={config.query}
    />
  );
}

Shelf.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  config: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  contextData: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  nav: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  items: PropTypes.array,
  query: PropTypes.string,
  template: PropTypes.string,
  removeEmptyContainers: PropTypes.func,
};

export default Shelf;
