import React from 'react';

import categoryItemTheme from './categoryItem.scss';
import AssetItem from '../AssetItem/AssetItem';

function CategoryItem(props) {
  return <AssetItem {...props} theme={categoryItemTheme} />;
}

export default CategoryItem;
