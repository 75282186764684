import React from 'react';

import { withFocus } from '@accedo/vdkweb-navigation';
import PropTypes from 'prop-types';

import ImageWithPreload from '#/components/ImageWithPreload/ImageWithPreload';
import { ITEM_TYPES, IMAGE_TYPE } from '#/config/constants';
import useBadgeTheme from '#/hooks/useBadgeTheme';
import useItemStyle from '#/hooks/useItemStyle';
import { getTranslation } from '#/services/i18n';

import assetItemTheme from './assetItem.scss';

const CONTAINER_MARGIN = 10;

function OrigAssetItem({ containerStyle, data, displayType, height, label, nav, onClick, style, theme, type, width }) {
  let typeItem;
  switch (type) {
    case ITEM_TYPES.Wide:
    case ITEM_TYPES.GridLandscape:
      typeItem = type;
      break;

    case ITEM_TYPES.SearchEpisode:
      typeItem = IMAGE_TYPE.BACKDROP;
      break;

    case ITEM_TYPES.SearchMovie:
    default:
      typeItem = IMAGE_TYPE.POSTER;
      break;
  }

  const { itemStyle } = useItemStyle({
    data,
    width,
    height,
    type: `${typeItem}`,
  });

  const { viewAll, displayText } = data;
  const typeClassName = theme[type] || '';

  const backgroundClassName = `${theme.background} ${nav.isFocused ? theme.backgroundFocused : ''} ${
    viewAll ? theme.viewAllBackground : ''
  }`;

  const wrapperStyle = {
    ...containerStyle,
    ...(width ? { width } : {}),
    ...(height ? { height } : {}),
    ...(displayType ? { display: displayType } : { display: 'inline-block' }),
    margin: CONTAINER_MARGIN,
  };

  const imageHeightNumber = containerStyle && +containerStyle.height.replace('vw', '');
  const finalImageHeight = `${imageHeightNumber}vw`;

  const { badgeStyle } = useBadgeTheme({ data: data.customBadgeColour });

  return (
    <div style={wrapperStyle}>
      {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events -- TODO: Automatically surpressed error. Resolve when you encounter this file! */}
      <div className={backgroundClassName} style={style} onMouseOver={nav.onMouseOver} onMouseOut={nav.onMouseOut}>
        <ImageWithPreload
          src={data?.thumbnailImage?.fileUrl || itemStyle.imageUrl}
          height={finalImageHeight}
          width={containerStyle?.width}
          // Change the default placeholder
          viewAll={viewAll || data.type === 'live' || false}
        />

        {data.customBadgeLabel && Object.keys(badgeStyle).length && (
          <div
            style={{
              color: badgeStyle.color,
              backgroundColor: badgeStyle.backgroundColor,
              border: badgeStyle.border,
            }}
            className={theme.badge}
          >
            {data.customBadgeLabel}
          </div>
        )}

        {viewAll ? (
          <div className={theme.viewAll}>
            <div>{getTranslation('viewAll')}</div>
            <div>{displayText}</div>
          </div>
        ) : (
          <div className={`${theme.contentContainer} ${typeClassName}`}>
            {data.progress && (
              <div className={theme.progress}>
                <span style={{ width: `${data.progress}%` }} />
              </div>
            )}
          </div>
        )}

        <button className={theme.clickArea} aria-label={label} onClick={onClick} />
      </div>
    </div>
  );
}

OrigAssetItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  containerStyle: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  data: PropTypes.object.isRequired,
  displayType: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  height: PropTypes.any,
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  nav: PropTypes.object,
  onClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  style: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  theme: PropTypes.object,
  type: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  width: PropTypes.any,
};

OrigAssetItem.defaultProps = {
  theme: assetItemTheme,
  label: '',
  nav: {},
};

const AssetItem = withFocus(OrigAssetItem);
// eslint-disable-next-line no-undef
if (__TEST__) {
  AssetItem._test = {
    OrigAssetItem,
  };
}

export default AssetItem;
