import React from 'react';

import Helmet from 'react-helmet';

import Page from '#/components/Page/Page';
import { getTranslation } from '#/services/i18n';

import style from './viewDoesNotExistInTheApp.scss';

function ViewDoesNotExistInTheApp() {
  return (
    <Page>
      <div className={style.container}>
        <Helmet title="View does not exist in the app" />
        <div className={style.title}>{getTranslation('viewDoesNotExistInTheAppTitle')}</div>

        <div className={style.description}>
          <div>{getTranslation('viewDoesNotExistInTheAppDescription')}</div>
        </div>
      </div>
    </Page>
  );
}

export default ViewDoesNotExistInTheApp;
