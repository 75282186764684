export const SettingsEnum = {
  locale: 'locale',
} as const;

export type Settingskeys = keyof typeof SettingsEnum;

// eslint-disable-next-line @typescript-eslint/ban-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
export type Setting = {};

export type LocaleSetting = {
  code: string;
  dir: string;
} & Setting;

export type Settings = {
  [SettingsEnum.locale]: LocaleSetting;
};
