import React, { useState, createContext } from 'react';

import PropTypes from 'prop-types';

/**
 * HistoryContext used for reading information
 * HistorySetContext used for setting information
 * The combination of both context allow us to store a custom
 * history stack.
 * prevState: object to store the information of the leaving page,
 * stack: array to store the pile of routes.
 */
const HistoryContext = createContext();
const HistorySetContext = createContext();

function HistoryProvider({ children }) {
  const [state, setState] = useState({
    stack: [],
  });

  return (
    <HistoryContext.Provider value={state}>
      <HistorySetContext.Provider value={setState}>{children}</HistorySetContext.Provider>
    </HistoryContext.Provider>
  );
}

HistoryProvider.propTypes = {
  children: PropTypes.node,
};

export { HistoryContext, HistorySetContext, HistoryProvider };
