import { useState, useEffect } from 'react';

import cookie from 'react-cookie';

import useAppConfig from '#/hooks/useAppConfig';
import { SESSION_KEY } from '#/providers/shared/control/config';
import search from '#/services/algolia/algolia';

const useSetContainerItems = (containers) => {
  const { config = {} } = useAppConfig();
  const sessionKey = cookie.load(SESSION_KEY);
  const [state, setState] = useState(null);
  const ready = sessionKey;

  useEffect(() => {
    const data = async () => {
      const all = await Promise.all(
        containers
          // eslint-disable-next-line consistent-return
          .map(async (container) => {
            if (!container.items || container.items.length) {
              return container;
            }
            if (container.genreBasedContent.length) {
              const filter = container.genreBasedContent.map((id) => `primaryGenre:"${id}"`).join(' OR ');
              try {
                const res = await search({
                  algoliaApiKey: config.algoliaApiKey,
                  algoliaApplicationId: config.algoliaApplicationId,
                  genreBasedContentFilter: filter,
                  hitsPerPage: container.maxNumberOfContentToShow,
                });

                return {
                  ...container,
                  items: res.hits,
                };
              } catch (e) {
                console.error(e);
                return container;
              }
            }
            if (container.advanceAlgoliaQuery) {
              try {
                const res = await search({
                  algoliaApiKey: config.algoliaApiKey,
                  algoliaApplicationId: config.algoliaApplicationId,
                  customQuery: container.advanceAlgoliaQuery,
                });

                return {
                  ...container,
                  items: res.hits,
                };
              } catch (e) {
                console.error(e);
                return container;
              }
            }
            return container;
          }),
      );
      setState(all);
    };

    if (ready) {
      data();
    }
  }, [config, containers, ready]);

  return state;
};

export default useSetContainerItems;
