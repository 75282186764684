// This is a header for TV only.
import React, { useEffect } from 'react';

import { focusManager } from '@accedo/vdkweb-navigation';
import { FocusDiv } from '@accedo/vdkweb-tv-ui';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Logo from '#/components/Logo/Logo';
import Menu from '#/components/Menu/Menu';
import useAppConfig from '#/hooks/useAppConfig';
import useI18n from '#/hooks/useI18n';
import useMenu from '#/hooks/useMenu';
import usePage from '#/hooks/usePage';
import { R720p } from '#/theme/variables/breakpoints';
import getResolution from '#/utils/getResolution';
import { navIdMap } from '#/utils/navigationHelper';

import styles from './tvHeader.scss';

const NAV_IDS = navIdMap.MENU.HEADER;

const nav = {
  container: {
    id: NAV_IDS.CONTAINER,
    forwardFocus: NAV_IDS.MENU,
    useLastFocus: false,
  },
  menu: {
    id: NAV_IDS.MENU,
    parent: NAV_IDS.CONTAINER,
    forwardFocus: `${NAV_IDS.MENU_ITEM}0`,
    itemId: NAV_IDS.MENU_ITEM,
  },
};
function TVHeader({ items }) {
  const { getCurrentPageId } = usePage();
  const { config = {} } = useAppConfig();
  const { currentLocale = {} } = useI18n();
  const { isMenuVisible } = useMenu();
  const focusCurrentPage = () => {
    const currentPageId = getCurrentPageId();
    // eslint-disable-next-line no-unused-expressions -- TODO: Automatically surpressed error. Resolve when you encounter this file!
    currentPageId && focusManager.changeFocus(getCurrentPageId());
  };
  const { dir } = currentLocale;
  const { width } = getResolution();

  const containerClass = classNames(styles.container, {
    [styles.isVisible]: isMenuVisible(),
  });

  const imageClass = classNames(styles.img, styles[dir]);

  const customStyle = {
    ...(R720p >= width ? { paddingTop: '1rem', paddingBottom: '1rem' } : { padding: '1.5rem' }),
  };

  useEffect(() => {
    focusManager.changeFocus(nav.menu.id);
  }, []);

  if (!items && !config.menuId) {
    return null;
  }

  return (
    <header dir={dir}>
      <FocusDiv
        style={customStyle}
        className={containerClass}
        nav={{
          ...nav.container,
          internal: {
            nextdown: focusCurrentPage,
          },
        }}
        dir={dir}
      >
        <Logo src={config.appLogo} className={imageClass} />
        <Menu entryId={config.menuId} items={items} nav={nav.menu} />
      </FocusDiv>
    </header>
  );
}

TVHeader.propTypes = {
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default TVHeader;
