import xdk, { device } from '@accedo/xdk-core';

import packageJson from '#/../package.json';
// eslint-disable-next-line import/order -- TODO: Automatically surpressed error. Resolve when you encounter this file!
import AnalyticsAsset from '#/models/AnalyticsAsset';
/**
 * @module services/analytics
 * @description
 This service is used to keep track of the different events of our application in a centralised way.
The service will depend on external javascript modules, and providers libraries loaded from the HTML template.

This is mandatory, as the analytics libraries must be loaded even before React or any other library.

Involved files
```
/src
    /static
        /index.ejs              -> Template. We can place the scripts of the analytics providers here to load them on
    /services
        /analytics
            index.js
            analytics.js
    /provider
      /analytics
        /{provider} -> Each provider __must__ export one function as default. It will be added to the analyticsProvider object to iterate over it
          index.js
          {provider}.js

/webpack
    /local
        /dev-config.js
```

Description
* `/webpack/webpack.common.js` This file contains the [HtmlWebpackPlugin](https://webpack.js.org/plugins/html-webpack-plugin/) from webpack that allow us to pass variables to the template (index.ejs) file. i.e Google Tag Manager identifier
* `/src/static/index.ejs` EJS Template used by the HtmlWebpackPlugin. We can modify it to include new libraries. This allow us to initialise the analytics libraries __before__ react is initialised
* `/src/services/analytics/analytics.js` Analytics service. Load the module and exposed methods to send analytics. We must modify this file to include or remove providers
* `/src/services/analytics/provider/provider.js` Analytics providers. They must export one function with the same name than the file (by convention) that sends the event to the provider
 *
 */
import dummy from '#/providers/analytics/dummy';
import gtag from '#/providers/analytics/gtag';
import logger from '#/utils/logger';

/**
 * Analytics providers
 */
const analyticsProviders = { gtag, dummy };

/**
 * Default Properties to be used in all the events as defined in
 * https://accedobroadband.jira.com/wiki/spaces/VDK/pages/2329674845/Service+Build+Elevate+Analytics+Service#Default-Properties
 *
 */
const defaultProps = {
  appName: packageJson.title,
  appVersion: packageJson.version,
  platformManufacturer: '',
  platformModel: '',
  platformOs: '',
  platformOsVersion: '',
  platformOsVersionNumber: '',
  platformUUID: '',
  platformDeviceId: '',
  platformDevice: '',
  platformType: 'CTV',
  screenResolution: '',
  userAgent: navigator ? navigator.userAgent : 'no-ua',
  userId: '',
  sessionId: '',
};

export const init = async () => {
  const resolution = xdk.system.getAppResolution();
  const { width, height } = resolution || {};
  defaultProps.platformManufacturer = device.platform;
  defaultProps.platformModel = xdk.system.getModel();
  defaultProps.platformOs = device.platform;
  defaultProps.platformOsVersion = xdk.system.getFirmwareVersion();
  defaultProps.platformUUID = await xdk.system.getUniqueId();
  defaultProps.screenResolution = `${width}x${height}`;
};

/**
 * Send Event method. This method is a placeholder to call the different 'native' lib methods to trigger the analytics.
 * It iterates over all the providers on the analyticsProviders object, filters the desired providers (if any) and call
 * the function with the object containing the event and the payload
 *
 * @param {string} event Event name. Needed in most of the libraries
 * @param {object} opts Event options. We can
 * @param {string[]} filter If present, it only send the events to the desired analytic providers. i.e ['gtm', 'mux']
 * @return {boolean} true if everything is ok, false if not
 */
const sendEvent = (event, opts, filter) => {
  const providers = [];
  try {
    if (filter && Array.isArray(filter) && filter.length > 0) {
      providers.push(...Object.keys(analyticsProviders).filter((key) => filter.includes(key)));
    } else {
      providers.push(...Object.keys(analyticsProviders));
    }
    providers.forEach((provider) => {
      analyticsProviders[provider]({
        name: event,
        params: { ...defaultProps, ...opts },
      });
    });
  } catch (e) {
    logger.error(e);
    return false;
  }

  return true;
};

/** **********************************************  Application  Actions *************************************************** */
/**
 * Send the boot event to all the analytic agents
 * @return {boolean} true if the event is triggered .
 */
export const startUsage = () => sendEvent('start');

/**
 * Send the boot event to all the analytic agents
 * @return {boolean} true if the event is triggered .
 */
export const stopUsage = () => sendEvent('stop');

/** **********************************************  App  Lifecycle *************************************************** */

/**
 * Send the boot event to all the analytic agents
 * @return {boolean} true if the event is triggered .
 */
export const boot = () => sendEvent('boot');

/**
 * Send the exit event to all the analytic agents
 * @return {boolean} true if the event is triggered .
 */
export const exit = () => sendEvent('exit');

/** **********************************************  Auth analytics *************************************************** */

/**
 * Set the user into the analytics service and trigger the event actions fo the user login using user/password
 * @param {object} user user model for the application.
 * @return {object} same user that we pass to the function.
 */
export const userLoggedIn = (user) => {
  defaultProps.userId = user.userId;
  return sendEvent('action_login', {}, ['dummy']);
};

/**
 * Trigger the event actions fo the user login failure
 * @param {object} user user model for the application.
 * @return {object} same user that we pass to the function.
 */
export const userLogInError = ({ username }) => sendEvent('login_error', { user: username }, ['dummy']);

/**
 * Set the user into the analytics service and trigger the event actions fo the device pairing
 * @param {object} user user model for the application.
 * @return {object} same user that we pass to the function.
 */
export const devicePaired = (user) => {
  defaultProps.userId = user.userId;
  return sendEvent('pairing');
};

/**
 * User successfully loggs out from the app
 * @return {boolean} true if sendEvent is ok
 */
export const userLoggedOut = () => {
  sendEvent('action_logout');
  defaultProps.userId = '';
};

/**
 * User request a password reset
 * @return {boolean} true if sendEvent is ok
 */
export const passwordReset = () => {
  sendEvent('action_passwordReset');
};

/** **********************************************  New event analytics *************************************************** */

/**
 * Track the screen view (When a user enter a page)
 * @param {object} opts Object with the screen
 * @param {String} opts.screenTitle Title of the page
 * @param {String} opts.screenType Type of the view (page|details|search|login|epg|...)
 * @return {boolean} true if sendEvent is ok
 */
export const screenView = (opts) => sendEvent('screen_view', { ...opts, device: device.platform });

/**
 * Track the item click action
 * @param {Item} item Item
 * @param {String} componentTitle Title of the component of the asset item
 * @return {boolean} true if sendEvent is ok
 */
export const actionClickItem = (item, componentTitle) => {
  const analyticsAsset = AnalyticsAsset(item);

  return sendEvent('action_click_item', { ...analyticsAsset, componentTitle });
};

/**
 * Track the menu item click action
 * @param {object} opts Object
 * @param {String} opts.menuTitle Title of the menu item
 * @return {boolean} true if sendEvent is ok
 */
export const actionClickMenu = (opts) => sendEvent('action_click_menu', opts);

/**
 * Track a search in the application
 * @param {object} opts Object
 * @param {String} opts.searchQuery query done
 * @return {boolean} true if sendEvent is ok
 */
export const actionSearch = (opts) => sendEvent('action_search', opts);

/**
 * Track a favourites add action
 * @param {Item} item Item Object
 * @return {boolean} true if sendEvent is ok
 */
export const favoritesAdd = (item) => {
  const analyticsItem = AnalyticsAsset(item);

  return sendEvent('action_favoritesAdd', analyticsItem);
};

/**
 * Track a favourites remove action
 * @param {Item} item Item Object
 * @return {boolean} true if sendEvent is ok
 */
export const favoritesRemove = (item) => {
  const analyticsItem = AnalyticsAsset(item);

  return sendEvent('action_favoritesRemove', analyticsItem);
};

/** **********************************************  Player analytics *************************************************** */
/**
 * Track the Playback play event
 * @param {object} opts Object with the content to play
 * @return {boolean} true if sendEvent is ok
 */
export const playbackRequested = (opts) => sendEvent('playback_requested', opts);
/**
 * Track the Playback play event
 * @param {object} opts Object with the content to play
 * @return {boolean} true if sendEvent is ok
 */
export const playbackPlay = (opts) => sendEvent('playback_play', opts);

/**
 * Track the Playback pause event
 * @param {object} opts Object with the content to play
 * @return {boolean} true if sendEvent is ok
 */
export const playbackPause = (opts) => sendEvent('playback_pause', opts);

/**
 * Track the Playback seek (when it starts) event
 * @param {object} opts Object with the content to play
 * @return {boolean} true if sendEvent is ok
 */
export const playbackSeekStart = (opts) => sendEvent('playback_seekStart', opts);

/**
 * Track the Playback seek (when it starts) event
 * @param {object} opts Object with the content to play
 * @return {boolean} true if sendEvent is ok
 */
export const playbackSeekComplete = (opts) => sendEvent('playback_seekComplete', opts);

/**
 * Track the Playback Forward event
 * @param {object} opts Object with the content to play
 * @return {boolean} true if sendEvent is ok
 */
export const playbackFF = (opts) =>
  sendEvent('action_playerControl', {
    ...opts,
    controlType: 'GoForward',
  });

/**
 * Track the Playback Rewind/Backward event
 * @param {object} opts Object with the content to play
 * @return {boolean} true if sendEvent is ok
 */
export const playbackRW = (opts) =>
  sendEvent('action_playerControl', {
    ...opts,
    controlType: 'GoBackward',
  });

/**
 * Track the Playback End event
 * @param {object} opts Object with the content to play
 * @return {boolean} true if sendEvent is ok
 */
export const playbackEnded = (opts) => sendEvent('playback_complete', opts);

/**
 * Track the Playback Stop event
 * @param {object} opts Object with the content to play
 * @return {boolean} true if sendEvent is ok
 */
export const playbackStop = (opts) => sendEvent('playback_stop', opts);

/**
 * Track the Playback Error event
 * @param {object} opts Object with the content to play
 * @return {boolean} true if sendEvent is ok
 */
export const playbackError = (opts) => sendEvent('playback_error', opts);

/**
 * Track the Playback Prev event
 * @param {object} opts Object with the content to play
 * @return {boolean} true if sendEvent is ok
 */
export const playbackPrev = (opts) =>
  sendEvent('action_playerControl', {
    ...opts,
    controlType: 'Previous',
  });

/**
 * Track the Playback Next event
 * @param {object} opts Object with the content to play
 * @return {boolean} true if sendEvent is ok
 */
export const playbackNext = (opts) =>
  sendEvent('action_playerControl', {
    ...opts,
    controlType: 'Next',
  });

/**
 * Track the Playback Select Subtitle event
 * @param {object} opts Object with the content to play
 * @return {boolean} true if sendEvent is ok
 */
export const playbackSelectSubtitle = (opts) =>
  sendEvent('action_playerControl', {
    ...opts,
    controlType: 'Subtitle',
  });

/**
 * Track the Playback Hearbeat event
 * @param {object} opts Object with the content
 * @return {boolean} true if sendEvent is ok
 */
export const playbackHeartbeat = (event, opts) => {
  sendEvent(event, opts);
};
