import React from 'react';

import { withFocus } from '@accedo/vdkweb-navigation';
import PropTypes from 'prop-types';

import ImageWithPreload from '#/components/ImageWithPreload/ImageWithPreload';
import { RoundButton } from '#/components/RoundButton/RoundButton';
import useAppConfig from '#/hooks/useAppConfig';
import useItemStyle from '#/hooks/useItemStyle';
import { getTranslation } from '#/services/i18n';
import defaultLocalTheme from '#/theme/defaultTheme.json';
import vw from '#/utils/vw';

import heroItemTheme from './heroItem.scss';

function HeroItem({ onClick, theme = heroItemTheme, nav = {}, data, containerStyle, buttonText, height, width }) {
  const { config = {} } = useAppConfig();
  const defaultTheme = config.theme || defaultLocalTheme;
  const backgroundClassName = `${theme.background} ${nav.isFocused ? theme.backgroundFocused : ''}`;
  const { itemStyle } = useItemStyle({ data, width, height, type: 'hero' });

  return (
    <div style={{ ...containerStyle }}>
      {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events -- TODO: Automatically surpressed error. Resolve when you encounter this file! */}
      <div className={backgroundClassName} onMouseOver={nav.onMouseOver} onMouseOut={nav.onMouseOut}>
        <ImageWithPreload src={itemStyle.imageUrl} />

        <div className={theme.contentContainer}>
          <RoundButton
            className={theme.button}
            onClick={onClick}
            displayText={buttonText || getTranslation('watchNowButton')}
            nav={nav}
          />

          {data.synopsis && <div className={theme.synopsis}>{data.synopsis}</div>}
          {data.title && <div className={theme.title}>{data.title}</div>}

          {data.customBadgeLabel && (
            <div
              style={{
                color: defaultTheme.colorOnAccent,
                backgroundColor: 'transparent',
                border: `${vw(2)} solid ${defaultTheme.colorOnAccent}`,
              }}
              className={theme.badge}
            >
              {data.customBadgeLabel}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

HeroItem.propTypes = {
  buttonText: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  data: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  containerStyle: PropTypes.any,
  height: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  nav: PropTypes.object,
  onClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  theme: PropTypes.object,
  width: PropTypes.string,
};

export default withFocus(HeroItem);
