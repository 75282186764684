import React from 'react';

import { withFocus } from '@accedo/vdkweb-navigation';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import roundButtonTheme from './roundButton.scss';

// eslint-disable-next-line valid-jsdoc
/**
 * Component that represent a Focusable round-styled Button
 */
function RoundButton({
  className,
  onClick,
  theme = roundButtonTheme,
  displayText,
  isFocused,
  isSelected,
  nav = {},
  style,
  Icon = null,
  iconClassName,
  iconFirst = false,
}) {
  const focused = isFocused || nav.isFocused;

  const buttonClassName = classNames(theme.button, {
    [theme.selected]: isSelected,
    [theme.focused]: focused,
  });

  return (
    <div
      className={className}
      onMouseOver={nav.onMouseOver}
      onMouseOut={nav.onMouseOut}
      onFocus={() => {}}
      onBlur={() => {}}
    >
      <button onClick={onClick} className={buttonClassName} style={style}>
        {iconFirst ? (
          <span>
            {Icon && <Icon className={iconClassName} />} {displayText}
          </span>
        ) : (
          <span>
            {displayText} {Icon && <Icon className={iconClassName} />}
          </span>
        )}
      </button>
    </div>
  );
}

RoundButton.propTypes = {
  /** Module class to apply component */
  className: PropTypes.string,
  /** Text to display in the button */
  displayText: PropTypes.string,
  /** If focused style should apply */
  isFocused: PropTypes.bool,
  /** If seleted style should apply */
  isSelected: PropTypes.bool,
  /** nav object as defined in @accedo/vdkweb-navigation */
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  nav: PropTypes.object,
  /** function to handle action on the item */
  onClick: PropTypes.func,
  /** style object */
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  style: PropTypes.object,
  /** theme object */
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  theme: PropTypes.object,
  /** Icon/Image to use along with the textin the Button */
  Icon: PropTypes.elementType,
  /** Module class to apply to the Icon if applicable */
  iconClassName: PropTypes.string,
  iconFirst: PropTypes.bool,
};

const FocusRoundButton = withFocus(RoundButton);

export { RoundButton, FocusRoundButton };
