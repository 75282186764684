/**
 * @module models/CMS
 */

/**
 * This model represents the Config.
 *
 * vikiElevateConfig representation
 * @typedef {Object} VikiElevateConfig
 * @property {String} title The App's title
 * @property {String} ovpUrl The OVP url
 * @property {Boolean} copyright The copyright message
 * @property {String} status The application status
 * @property {String} menuId The main menu ID in the CMS
 * @property {String} appLogo The app logo url
 * @property {String} defaultLocale The default locale code
 * @property {String} dictionary The app dictionary
 * @property {Number} ovpPageSize The default value for the Page Size for OVP requests
 * @property {String} routesMapping The app Route Mapper
 */

/**
 * VikiElevateConfig Model
 * @param {Object} rawConfig Config
 * @returns {VikiElevateConfig} config - The {@link VikiElevateConfig} representation
 */
const config = (rawConfig) => {
  const {
    assets,
    copyright,
    title,
    status,
    mainMenuEntryId,
    defaultLocale,
    dictionary,
    ovpPageSize,
    routesMapping,
    webForgotPasswordUrl,
    webSignupURL,
    brightcoveApi,
    algoliaApiKey,
    algoliaApplicationId,
    moreLikeThisResultSize,
    algoliaSearchResultSize,
    searchMinimumLetterThreshold,
    autoplayCountdown,
    autoplayThreshold,
    epgEndpoint,
    cognitoEndpointUrl,
  } = rawConfig;

  const ovpUrl = 'https://accedoovp20x.ocs.ps.accedo.tv';

  /**
   * Get the App title
   * @returns {String} title
   */
  const getTitle = () => title;
  /**
   * Get the ovp url
   * @returns {String} accedoOvpUrl
   */
  const getOvpUrl = () => ovpUrl;
  /**
   * Get the copyright message
   * @returns {String} copyright
   */
  const getCopyRight = () => copyright;
  /**
   * Get the app status
   * @returns {String} status
   */
  const getStatus = () => status;
  /**
   * Get the app status
   * @returns {String} status
   */
  const getMenuId = () => mainMenuEntryId;
  /**
   * Get the app status
   * @returns {String} status
   */
  // eslint-disable-next-line consistent-return -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  const getAppLogo = () => {
    if (assets) {
      return assets.appLogo;
    }
  };
  // eslint-disable-next-line consistent-return
  const getSignUpQrCode = () => {
    if (assets) {
      return assets.signUpQrCode;
    }
  };
  // eslint-disable-next-line consistent-return
  const getForgotPasswordQrCode = () => {
    if (assets) {
      return assets.forgotPasswordQrCode;
    }
  };
  /**
   * Get the app default locale
   * @returns {String} defaultLocale
   */
  const getDefaultLocale = () => defaultLocale;
  /**
   * Get the app dictionary
   * @returns {String} dictionary
   */
  const getDictionary = () => dictionary;

  /**
   * Get the OVP page size for queries
   * @returns {number} ovpPageSize
   */
  const getOvpPageSize = () => ovpPageSize;

  /**
   * Get the Route Mapper Entry id
   * @returns {string} routesMapping
   */
  const getRoutesMapping = () => routesMapping;

  /**
   * Get the web forgot password URL
   * @returns {string} webForgotPasswordUrl
   */
  const getForgotPasswordUrl = () => webForgotPasswordUrl;

  /**
   * Get the web sign up URL
   * @returns {string} webSignupURL
   */
  const getSignUpUrl = () => webSignupURL;

  /**
   * Get the brightcove API details
   * @returns {object} brightcoveApi
   */
  const getBrightcoveApi = () => brightcoveApi;

  /**
   * Get the algoliaApiKey
   * @returns {string} algoliaApiKey
   */
  const getAlgoliaApiKey = () => algoliaApiKey;

  /**
   * Get the algoliaApplicationId
   * @returns {string} algoliaApplicationId
   */
  const getAlgoliaApplicationId = () => algoliaApplicationId;

  /**
   * Get the moreLikeThisResultSize
   * @returns {string} moreLikeThisResultSize
   */
  const getMoreLikeThisResultSize = () => moreLikeThisResultSize;

  /**
   * Get the algoliaSearchResultSize
   * @returns {string} algoliaSearchResultSize
   */
  const getAlgoliaSearchResultSize = () => algoliaSearchResultSize;

  /**
   * Get the searchMinimumLetterThreshold
   * @returns {string} searchMinimumLetterThreshold
   */
  const getSearchMinimumLetterThreshold = () => searchMinimumLetterThreshold;

  const getAutoplayCountdown = () => autoplayCountdown;

  const getAutoplayThreshold = () => autoplayThreshold;

  const getEpgEndpoint = () => epgEndpoint;

  const getUserPoolId = () => cognitoEndpointUrl?.userpoolId;

  const getClientId = () => cognitoEndpointUrl?.clientId;

  return {
    ovpUrl: getOvpUrl(),
    ovpPageSize: getOvpPageSize(),
    copyright: getCopyRight(),
    status: getStatus(),
    title: getTitle(),
    menuId: getMenuId(),
    appLogo: getAppLogo(),
    signUpQrCode: getSignUpQrCode(),
    forgotPasswordQrCode: getForgotPasswordQrCode(),
    defaultLocale: getDefaultLocale(),
    dictionary: getDictionary(),
    routesMapping: getRoutesMapping(),
    forgotPasswordUrl: getForgotPasswordUrl(),
    signupUrl: getSignUpUrl(),
    brightcoveApi: getBrightcoveApi(),
    algoliaApiKey: getAlgoliaApiKey(),
    algoliaApplicationId: getAlgoliaApplicationId(),
    moreLikeThisResultSize: getMoreLikeThisResultSize(),
    algoliaSearchResultSize: getAlgoliaSearchResultSize(),
    searchMinimumLetterThreshold: getSearchMinimumLetterThreshold(),
    autoplayCountdown: getAutoplayCountdown(),
    autoplayThreshold: getAutoplayThreshold(),
    epgEndpoint: getEpgEndpoint(),
    userpoolId: getUserPoolId(),
    clientId: getClientId(),
  };
};

export default config;
