import React from 'react';

import Layout from './Layout';
import { Direction } from './addNavToItems';

function HorizontalLayout(props) {
  return <Layout {...props} direction={Direction.Horizontal} />;
}

export default HorizontalLayout;
