import { PROVIDER_TYPE } from '#/config/constants';
import Genre from '#/models/genre/genre';
import cms from '#/providers/cms';

import fetcher from '../../helpers/fetcher';

/**
 * @module services/genre
 * @description
 * Service to handle the fetch of genre data
 */

/**
 * Genre Services
 * @returns {Promise<Array<Object>>} Genre
 */
const getGenres = async () => {
  const rawGenres = await fetcher({
    cacheId: `${PROVIDER_TYPE.cms}-genres`,
    fetchFn: () => cms.getGenres(),
  });

  return rawGenres && Genre(rawGenres);
};
// eslint-disable-next-line import/prefer-default-export -- TODO: Automatically surpressed error. Resolve when you encounter this file!
export { getGenres };
