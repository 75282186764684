/**
 * @module providers/i18n/control
 */

import { accedoOneClientInstance } from '#/providers/shared/control/control';
import { getConfiguration } from '#/services/cms';

import localProvider from '../local/local';

const DEFAULT_LOCALE = 'en';

/**
 * Returns the default locale code of the application, when no selected
 *
 * @returns {Promise<String>} default locale code
 */
const getDefaultLocaleCode = async () => {
  try {
    const cmsConfiguration = await getConfiguration();
    const { defaultLocale = DEFAULT_LOCALE } = cmsConfiguration;

    return defaultLocale;
  } catch (e) {
    console.warn(`Unable to getDefaultLocaleCode from Control, returning default. Error: ${e}`);
    return DEFAULT_LOCALE;
  }
};

/**
 * Returns all the available dictionaries/locales of the application
 *
 * @returns {Promise<String[]>} All the application local codes
 */
const getLocales = async () => {
  let localesResponse = {};
  try {
    localesResponse = await accedoOneClientInstance.getAvailableLocales();
  } catch (e) {
    console.warn(`Unable to get Locales from Control, returning default. Error: ${e}`);
  }
  return localesResponse?.locales || [];
};

/**
 * Returns an specific dictionary based on a locale code
 *
 * @param {String} localeCode locale code to get the Locale/Dictionary from
 *
 * @returns {Promise<Object>} locale associated
 */
const getLocale = async (localeCode) => {
  let dictionary;
  let dictionaryEntry = {};
  try {
    const cmsConfiguration = await getConfiguration();
    dictionary = cmsConfiguration.dictionary;
  } catch (e) {
    console.warn(`Unable to get configuration from Control, returning default. Error: ${e}`);
  }
  try {
    dictionaryEntry = await accedoOneClientInstance.getEntryById(dictionary, {
      locale: localeCode,
    });
  } catch (e) {
    console.warn(`Unable to get Dictionary from Control, returning from local. Error: ${e}`);
    dictionaryEntry = await localProvider.getLocale(localeCode);
  }
  return dictionaryEntry;
};
export default {
  getDefaultLocaleCode,
  getLocales,
  getLocale,
};
