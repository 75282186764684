import React from 'react';

import PropTypes from 'prop-types';

import theme from './continueWatchingItem.scss';
import AssetItem from '../AssetItem/AssetItem';

function ContinueWatchingItem(props) {
  const { data, width, height } = props;

  data.images = [{ url: props.data.coverUrl }];

  if (data.assetType === 'episode') {
    data.title = data.showTitle;
  }

  const itemStyle = {
    ...(width ? { width } : {}),
    ...(height ? { height } : {}),
  };

  return (
    <div className={theme.continueWatchingItem} style={itemStyle}>
      <AssetItem {...props} theme={theme} />
    </div>
  );
}

ContinueWatchingItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  data: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  height: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  width: PropTypes.any,
};

export default ContinueWatchingItem;
