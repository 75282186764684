import { LocaleSetting, SettingsEnum } from '#/models/setting/setting';
import { get, set } from '#/providers/settings';

/**
 * Get locale user settings
 * @returns {Promise<LocaleSetting>} User locale settings
 */
const getLocale = () => get<LocaleSetting>(SettingsEnum.locale);

/**
 * Set/add a locale setting
 * @param {LocaleSetting} value User locale settings
 * @returns {void}
 */
const setLocale = (value: LocaleSetting) => set<LocaleSetting>(SettingsEnum.locale, value);

export default { getLocale, setLocale };
