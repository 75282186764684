import React from 'react';

import Layout from './Layout';
import { Direction } from './addNavToItems';

function VerticalLayout(props) {
  return <Layout {...props} direction={Direction.Vertical} />;
}

export default VerticalLayout;
