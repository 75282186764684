/**
 * @module services
 */

import CacheRequests from './helpers/Cache';

const invalidateCache = () => {
  const cache = new CacheRequests();
  cache.invalidate();
};

// eslint-disable-next-line import/prefer-default-export -- TODO: Automatically surpressed error. Resolve when you encounter this file!
export { invalidateCache };
