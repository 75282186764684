import React, { useCallback, useEffect, useRef, useState } from 'react';

import { focusManager } from '@accedo/vdkweb-navigation';
// eslint-disable-next-line import/no-extraneous-dependencies -- TODO: Automatically surpressed error. Resolve when you encounter this file!
import { throttle as _throttle } from 'lodash';
import PropTypes from 'prop-types';

import { usePointer } from '#/context/PointerContext';
import vw from '#/utils/vw';

let animationRef = null;

export const DEFAULT_THROTTLE_MS = 250;
export const DEFAULT_TRANSITION_S = 0.3;
export const DEFAULT_TRANSITION = `ease-out all ${DEFAULT_TRANSITION_S}s`;

const useRunThrottled = (throttleMs) => {
  const { current: compRef } = useRef({});

  if (!compRef.runThrottled || compRef.runThrottledMs !== throttleMs) {
    compRef.runThrottled = _throttle((fn) => fn(), throttleMs);
    compRef.runThrottledMs = throttleMs;
  }

  return compRef.runThrottled;
};

function VerticalScroll({
  children,
  navIds,
  onScroll = () => {},
  pageTopFunction,
  style,
  width = 1920,
  height = 1080,
  initial = 0,
  margin = 0,
  throttleMs = DEFAULT_THROTTLE_MS,
  transition = DEFAULT_TRANSITION,
  hideMenu,
  showMenu,
  menuVisible = false,
  forceScrollMargin,
}) {
  const ref = useRef({});
  const runThrottled = useRunThrottled(throttleMs);
  const { pointerEnabled } = usePointer();
  const [state, setState] = useState({
    position: initial,
    offset: 0,
    height: -1,
    index: 0,
    navId: navIds[0],
  });

  // ref to allow accessing value of latest prop inside effects without adding prop as dependency for effect
  const pageTopFunctionRef = useRef();
  pageTopFunctionRef.current = pageTopFunction;
  const pointerEnabledRef = useRef();
  pointerEnabledRef.current = pointerEnabled;

  const scrollToIndex = useCallback(
    (index) => {
      const componentId = navIds[index];
      const { position, offset, height: areaHeight, bottom } = pageTopFunctionRef.current(componentId) || {};
      if (!Number.isNaN(position) && typeof position === 'number') {
        setState({
          position,
          height: Number(areaHeight) || -1,
          bottom,
          offset: Number(offset) || 0,
          index,
          navId: componentId,
        });
        if (position >= 0 && menuVisible && showMenu) {
          showMenu();
        } else if (position < 0 && hideMenu) {
          hideMenu();
        }
      }
    },
    [navIds, showMenu, hideMenu, menuVisible],
  );

  const handleTrailBuilt = useCallback(() => {
    // scrolling is not automatically triggered if pointer is enabled to avoid unintended scroll when moving pointer
    if (!pointerEnabledRef.current) {
      const trail = focusManager.getTrail();

      if (trail?.length > 0 && (navIds?.length > 2 || forceScrollMargin)) {
        let itemIndex = -1;

        for (let i = 0; i < trail.length; i += 1) {
          const trailItem = trail[i];
          itemIndex = navIds.indexOf(trailItem);

          if (itemIndex !== -1) {
            scrollToIndex(itemIndex);
            break;
          }
        }
      }
    }
  }, [navIds, forceScrollMargin, scrollToIndex]);

  const handleTrailBuiltRef = useRef();
  handleTrailBuiltRef.current = handleTrailBuilt;
  useEffect(() => {
    runThrottled(() => {
      cancelAnimationFrame(animationRef);
      animationRef = global.requestAnimationFrame(() => {
        if (!ref.current) {
          return;
        }

        const transform = `translateY(${state.position + state.offset + (state.position === 0 ? 0 : margin)}px)`;
        ref.current.style.transform = transform;
        ref.current.style.webkitTransform = transform;

        onScroll({ itemIndex: state.index, navId: state.navId });
      });
    });
  }, [state, margin, onScroll, runThrottled]);

  useEffect(() => {
    const listener = (...args) => handleTrailBuiltRef?.current?.(...args);

    const unlisten = focusManager.listenToTrailBuilt(listener);

    return unlisten;
  }, []);

  // Some navId might have same position. This will scroll to next position

  return (
    <div
      style={{
        width: vw(width),
        height: vw(height),
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'relative',
          transition,
          ...style,
        }}
        ref={ref}
      >
        {children}
      </div>
    </div>
  );
}

VerticalScroll.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  initial: PropTypes.number,
  margin: PropTypes.number,
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  navIds: PropTypes.any,
  onScroll: PropTypes.func,
  pageTopFunction: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  style: PropTypes.object,
  throttleMs: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  transition: PropTypes.any,
  hideMenu: PropTypes.func,
  showMenu: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  menuVisible: PropTypes.bool,
  forceScrollMargin: PropTypes.bool,
};

export default VerticalScroll;
