/**
 * @module services/info
 *
 * @description
 * This service provides content for each area of Info section
 * provided by this template: About, Terms, Help and Contact areas.
 */

/**
 * Get About content
 * @returns {Promise<Object>} Content for About
 */
const getAbout = async () =>
  new Promise((resolve) => {
    resolve({
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut quis purus nec felis vulputate pretium. Fusce felis nibh,
      volutpat at diam ut, ullamcorper varius lorem. Ut sed posuere dui. Mauris vel pulvinar magna, et ultricies purus. Suspendisse pharetra
      felis sit amet fermentum lacinia. Aenean iaculis sapien a hendrerit commodo. Phasellus lacinia pellentesque lectus, eget aliquet nisl suscipit a.
      Donec sodales quis eros sit amet consectetur. Duis congue auctor metus, a pulvinar nibh placerat et. Maecenas eu lectus et dui imperdiet semper.`,
    });
  });

/**
 * Get Terms content
 * @returns {Promise<Object>} Content for Terms
 */
const getTerms = async () =>
  new Promise((resolve) => {
    resolve({
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin maximus quis libero cursus cursus.
      Vivamus eu lacinia dui. Nam cursus urna et augue efficitur finibus. Ut ac congue ipsum. Suspendisse laoreet aliquet fermentum.
      Nunc sollicitudin nisi vitae metus dapibus condimentum. Suspendisse eget gravida tellus. Phasellus eget odio vel nibh fermentum pretium at quis nibh.
      In tristique sapien vitae urna fringilla sollicitudin. Proin hendrerit quis magna vel dictum. Sed luctus enim vel nisi porttitor pulvinar.
      Duis pharetra fringilla porttitor. Ut at volutpat elit. \n Mauris ut risus elit. Suspendisse varius diam lorem, tempor consequat augue faucibus nec.
      Praesent maximus sem in nibh tempor dapibus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
      Proin finibus vitae felis id cursus. Morbi volutpat rhoncus hendrerit. Etiam velit velit, eleifend dignissim facilisis nec, facilisis ut nunc.
      Quisque blandit pellentesque viverra. Mauris at risus in enim rutrum rutrum. Sed consectetur, lorem non vehicula dapibus, dui metus euismod nunc,
      at eleifend nisl dui et odio. Mauris lectus nisl, aliquet sit amet lobortis sit amet, tincidunt non dui. Nam consequat nibh dictum, fringilla ex et,
      semper lacus. Nunc euismod turpis ac felis accumsan, in aliquam tortor porttitor. Nunc sit amet metus non sapien dictum tincidunt. Maecenas rutrum mi nec
      nauris porta lobortis. \n Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In mollis est ac enim efficitur sodales.
      Sed tempus urna sed mi ultrices consectetur. Vestibulum nunc tellus, feugiat non elit at, varius tincidunt libero. Mauris sed gravida nisl, sed viverra urna.
      Integer tincidunt tortor sit amet justo suscipit congue. Curabitur vitae risus tellus. Etiam a bibendum felis, condimentum vulputate eros. Aliquam turpis lacus,
      fringilla vitae lectus at, ultricies eleifend quam.`,
    });
  });

/**
 * Get Help content
 * @returns {Promise<Object>} Content for Help
 */
const getHelp = async () =>
  new Promise((resolve) => {
    resolve({
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin maximus quis libero cursus cursus. Vivamus eu lacinia dui.
      Nam cursus urna et augue efficitur finibus. Ut ac congue ipsum. Suspendisse laoreet aliquet fermentum. Nunc sollicitudin nisi vitae metus dapibus condimentum.
      Suspendisse eget gravida tellus. Phasellus eget odio vel nibh fermentum pretium at quis nibh. In tristique sapien vitae urna fringilla sollicitudin.
      Proin hendrerit quis magna vel dictum. Sed luctus enim vel nisi porttitor pulvinar. Duis pharetra fringilla porttitor. Ut at volutpat elit. \n Mauris ut risus elit.
      Suspendisse varius diam lorem, tempor consequat augue faucibus nec. Praesent maximus sem in nibh tempor dapibus. Orci varius natoque penatibus et magnis dis
      parturient montes, nascetur ridiculus mus. Proin finibus vitae felis id cursus. Morbi volutpat rhoncus hendrerit. Etiam velit velit, eleifend dignissim facilisis nec,
      facilisis ut nunc. Quisque blandit pellentesque viverra. Mauris at risus in enim rutrum rutrum. Sed consectetur, lorem non vehicula dapibus, dui metus euismod nunc,
      at eleifend nisl dui et odio. Mauris lectus nisl, aliquet sit amet lobortis sit amet, tincidunt non dui. Nam consequat nibh dictum, fringilla ex et, semper lacus.
      Nunc euismod turpis ac felis accumsan, in aliquam tortor porttitor. Nunc sit amet metus non sapien dictum tincidunt. Maecenas rutrum mi nec mauris porta lobortis. \n
      Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In mollis est ac enim efficitur sodales. Sed tempus urna sed mi ultrices
      consectetur. Vestibulum nunc tellus, feugiat non elit at, varius tincidunt libero. Mauris sed gravida nisl, sed viverra urna. Integer tincidunt tortor sit amet justo
      suscipit congue. Curabitur vitae risus tellus. Etiam a bibendum felis, condimentum vulputate eros. Aliquam turpis lacus, fringilla vitae lectus at,
      ultricies eleifend quam.`,
    });
  });

/**
 * Get Contact content
 * @returns {Promise<Object>} Content for Contact
 */
const getContact = async () =>
  new Promise((resolve) => {
    resolve({
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam et odio et mi pharetra feugiat facilisis id velit. Fusce elementum vestibulum massa
      in aliquet. Praesent id tincidunt est. In volutpat sagittis augue. Mauris egestas maximus urna at mollis. Curabitur non nulla rhoncus, malesuada velit non,
      lacinia magna. Praesent ut sem in lectus efficitur commodo at id risus. Phasellus accumsan ex eu leo posuere, id condimentum augue mollis. Suspendisse justo diam,
      rhoncus ut ipsum vitae, venenatis porta risus. Donec venenatis purus urna, eu pretium odio dictum a. Suspendisse potenti. \n Quisque sit amet rhoncus diam, at auctor
      tortor. Curabitur sed mattis ante. Aliquam non lacinia diam, id varius velit. Donec ac tempor arcu, efficitur consequat sapien. Morbi faucibus porta sapien, eget
      sodales metus blandit in. Cras eu vestibulum quam, eu ultricies massa. Suspendisse aliquet turpis in quam finibus porttitor. Aliquam auctor mauris sit amet dolor
      tempor, non lacinia libero efficitur. Nulla tincidunt felis urna, sagittis mattis erat placerat quis. Sed libero tellus, varius ac lectus vitae, ultrices rhoncus tortor.
      Aenean suscipit leo sagittis lorem interdum tempor. \n Vestibulum rhoncus, erat tristique pharetra auctor, neque tellus laoreet massa, dignissim porta odio nunc at libero.
      Suspendisse lorem nibh, congue vitae ipsum quis, euismod hendrerit dui. Ut id tempus leo. Donec a est ut augue lacinia feugiat. Nullam semper elementum mi, ac mollis risus pellentesque nec.
      Quisque pharetra vestibulum eros, quis viverra ligula ultricies eu. Morbi in viverra ex. Phasellus sodales, diam eu semper sodales, elit urna ultrices neque,
      suscipit hendrerit nunc est ac odio. Aenean elementum neque at ullamcorper blandit. \n Praesent semper tempus mauris, ut facilisis justo blandit sit amet.
      Sed sed dignissim augue, vel auctor ante. Aliquam accumsan sed purus malesuada rutrum. Ut ut scelerisque tortor. Donec rutrum eu mauris quis rutrum.
      Sed fermentum eu mauris sed pulvinar. Pellentesque dignissim sodales libero hendrerit dictum. Aliquam orci leo, vestibulum molestie quam non, porttitor
      convallis nulla. Vestibulum in enim eu justo aliquet interdum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse placerat auctor nunc,
      vitae ultricies elit. Duis a neque eget lorem condimentum commodo. Duis sit amet rutrum urna, a convallis dolor. Interdum et malesuada fames ac ante ipsum primis
      in faucibus. \n Morbi vel rutrum mi. Integer ac est rutrum, maximus enim ut, viverra est. Morbi consequat eu quam sed dignissim. Pellentesque accumsan volutpat
      sapien, a molestie risus venenatis vitae. Nunc egestas odio metus, nec accumsan nulla ornare eget. Duis et nisi sem. Etiam at aliquam neque. Ut sed nunc efficitur,
      pulvinar diam ut, congue urna.`,
    });
  });

export { getAbout, getTerms, getHelp, getContact };
