import React from 'react';

import PropTypes from 'prop-types';

import defaultSrc from '#/static/images/vialogo.svg';

import styles from './logo.scss';

function Logo({ src = defaultSrc, className: propClassName }) {
  const className = styles.logo;

  return <img src={src} className={`${className} ${propClassName}`} alt="Logo" />;
}

Logo.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
};

export default React.memo(Logo);
