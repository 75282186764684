import { useState, useEffect, useCallback, useMemo } from 'react';

import { CONTAINER_ITEM_TYPES } from '#/config/constants';
import Bookmark from '#/models/Bookmark/bookmark';
import { bookmarkService } from '#/services/userData';

/**
 * @param dataRef {Object} React ref to avoid re-definitions of callbacks
 * @param dataRef.progress {number} The progress of the playback.
 * @param dataRef.duration {number} The duration of the asset.
 * @param dataRef.asset {object} The asset object.
 * @returns {Object}
 * @returns {Array} userBookmarks
 * @returns {Function} getUserBookmarks
 * @returns {Function} setBookmark
 * @returns {Function} removeBookmark
 * @returns {Function} getBookmarkById
 * @returns {Function} clearBookmark
 * @returns {Function} isBookmark
 */

const useBookmarks = ({ dataRef, isAuthenticated, userName }) => {
  const {
    getContinueWatchingItems,
    getBookmark,
    addBookmark,
    removeBookmark,
    clearBookmark,
    isBookmark,
    removeLatestEpisode,
  } = useMemo(() => bookmarkService(userName), [userName]);

  const [userBookmarks, setUserBookmarks] = useState();

  const getUserBookmarks = useCallback(async () => {
    let bookmarks = [];
    if (isAuthenticated) {
      bookmarks = await getContinueWatchingItems();
    }

    return bookmarks;
  }, [isAuthenticated, getContinueWatchingItems]);

  // eslint-disable-next-line consistent-return -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  const setBookmark = useCallback(async () => {
    if (!isAuthenticated || !dataRef) {
      return [];
    }
    const { progress, duration, asset, nextAsset } = dataRef.current;
    if (progress > 0) {
      const progressPercentage = (progress * 100) / duration;
      let bookmark;
      if (progressPercentage >= 95) {
        if (asset.watchLatest || !nextAsset) {
          await removeBookmark(asset.id);
          await removeLatestEpisode(asset.showId);
          // eslint-disable-next-line consistent-return
          return;
        }
        // set bookmark to next episode
        bookmark = Bookmark({
          ...nextAsset,
          resumeTime: 0,
          assetType: CONTAINER_ITEM_TYPES.Episode,
        });
        await removeBookmark(asset.id);
      } else {
        bookmark = Bookmark({
          ...asset,
          resumeTime: progress,
        });
      }
      addBookmark(bookmark);
    }
  }, [isAuthenticated, dataRef, addBookmark, removeBookmark, removeLatestEpisode]);

  const getBookmarkById = useCallback(
    ({ id: assetId }) => {
      if (!isAuthenticated) {
        return null;
      }
      return getBookmark(assetId);
    },
    [isAuthenticated, getBookmark],
  );

  useEffect(() => {
    const loadBookmarks = async () => {
      setUserBookmarks(await getUserBookmarks());
    };
    if (!userBookmarks) {
      loadBookmarks();
    }
  }, [userBookmarks, getUserBookmarks]);

  return {
    userBookmarks,
    getUserBookmarks,
    setBookmark,
    removeBookmark,
    getBookmarkById,
    clearBookmark,
    isBookmark,
  };
};

export default useBookmarks;
