import React from 'react';

import { withFocus } from '@accedo/vdkweb-navigation';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies -- TODO: Automatically surpressed error. Resolve when you encounter this file!
import Ellipsis from 'react-ellipsis-pjs';

import ImageWithPreload from '#/components/ImageWithPreload/ImageWithPreload';
import { getTranslation } from '#/services/i18n';
import { validDateDaysLeft } from '#/utils/helpers';

import seasonEpisodeTheme from './seasonEpisode.scss';
/**
 * SeasonEpisode Component
 * @param {Object} props Component Props
 * @param {Function} props.onClick Callback function to dispatch when
 * @param {Object} props.theme CSS theme
 * @param {Object} props.data The content to be displayed
 * @returns {React.ReactComponentElement} Component
 */

/** Variable required to get the image's height in vw */
const TITLE_CONTAINER_HEIGHT = 5.2;

function SeasonEpisode({ containerStyle, onClick, data, nav, theme = seasonEpisodeTheme }) {
  const {
    title,
    images: {
      thumbnail: { src: imageSrc },
    },
    custom_fields: { episode_number: episodeNumber, te_reo_level_list: teReoLevel, parentalrating: parentalRating },
    duration,
    name,
    description,
    text_tracks: textTracks,
    schedule,
  } = data;

  const className = nav.isFocused ? `${theme.episode} ${theme.focused}` : theme.episode;

  const containerClassName = nav.isFocused
    ? `${theme.episodeContentContainer} ${theme.focused}`
    : theme.episodeContentContainer;

  const imageHeightNumber = containerStyle && +containerStyle.height.replace('vw', '');
  const finalImageHeight = `${imageHeightNumber - TITLE_CONTAINER_HEIGHT}vw`;
  const durationConverted = duration ? getTranslation('minutes', { value: Math.floor(duration / 60000) }) : '';
  const subtitle = [
    episodeNumber ? getTranslation('episodeWithNumber', { episodeNumber }) : '',
    parentalRating,
    durationConverted,
    teReoLevel,
  ]
    .filter(Boolean)
    .join(' • ');

  const textTracksString = textTracks
    // eslint-disable-next-line array-callback-return, consistent-return
    .map((track) => {
      if (track.kind === 'subtitles') {
        if (track.srclang === 'en-NZ') {
          return 'English';
        }
        if (track.srclang === 'mi') {
          return 'Maori';
        }
      }
    })
    .filter(Boolean)
    .join(', ');
  const daysLeft = validDateDaysLeft(schedule);

  return (
    <div style={{ ...containerStyle }}>
      {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus -- TODO: Automatically surpressed error. Resolve when you encounter this file! */}
      <div
        className={className}
        onClick={onClick}
        onMouseEnter={nav.onMouseOver}
        onMouseLeave={nav.onMouseOut}
        role="button"
      >
        <ImageWithPreload
          className={theme.coverImage}
          src={imageSrc}
          alt={title}
          height={finalImageHeight}
          width={containerStyle.width}
        />

        <div className={containerClassName}>
          {subtitle && <div className={theme.subtitle}>{subtitle}</div>}
          {daysLeft && <div className={theme.subtitle}>{`${daysLeft}`}</div>}
          {name && <div className={theme.title}>{name}</div>}

          {description && (
            <div className={theme.description}>
              <Ellipsis lines={5} text={description} />
            </div>
          )}

          {textTracksString && (
            <div className={theme.textTracks}>
              <strong>{getTranslation('subtitles')}</strong>: {textTracksString}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

SeasonEpisode.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  containerStyle: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  data: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  nav: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  theme: PropTypes.object,
};

export default withFocus(SeasonEpisode);
