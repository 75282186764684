/**
 * @module models/CMS
 */

/**
 * This model represents the Season.
 *
 * Season representation
 * @typedef {Object} Season
 * @property {String} route route string
 * @property {String} template route template from the list defined in config/constant (PAGE_TEMPLATES)
 * @property {String} id CMD page ID
 */

/**
 * Season Model
 * @param {Object} rawSeason Season
 * @returns {Season} Season
 */
// eslint-disable-next-line consistent-return
const seasonModel = (rawSeason) => {
  if (rawSeason) {
    const { playlistId, title } = rawSeason;

    return {
      playlistId,
      title,
    };
  }
};

export default seasonModel;
