import React, { useEffect, useCallback, useContext, useRef } from 'react';

import { focusManager } from '@accedo/vdkweb-navigation';
import PropTypes from 'prop-types';
import cookie from 'react-cookie';

import Popup from '#/components/Popup/Popup';
import SplashScreen from '#/components/SplashScreen/SplashScreen';
import { AuthContext } from '#/context/AuthContext';
import { ConfigContext } from '#/context/ConfigContext';
import { GenreContext } from '#/context/GenreContext';
import { PageProvider } from '#/context/PageContext';
import PointerContextProvider from '#/context/PointerContext';
import useApiError from '#/hooks/useApiError';
import useAppConfig from '#/hooks/useAppConfig';
import useI18n from '#/hooks/useI18n';
import useNetworkStatus from '#/hooks/useNetworkStatus';
import { SESSION_KEY } from '#/providers/shared/control/config';
import { getGenres } from '#/services/cms';
import { getTranslation } from '#/services/i18n';
import defaultLocalTheme from '#/theme/defaultTheme.json';
import setCssVariables from '#/utils/setCssVariables';

import styles from './tvApp.scss';

const MAINTENANCE = 'maintenance';

// The entry point of your application. This is where
// you can control rendering based on received application
// status or configurations.
function TVAppContent({ platform, children }) {
  const context = useContext(AuthContext);

  const { config = {} } = useAppConfig();
  const configContext = useContext(ConfigContext);

  const { currentLocale = {} } = useI18n();
  const { code: localeCode } = currentLocale;
  const { kidsMode } = configContext;

  const { networkStatus } = useNetworkStatus();
  const { addError, removeError } = useApiError();
  const { setGenresList } = useContext(GenreContext);
  const sessionKey = cookie.load(SESSION_KEY);
  const networkTimeoutId = useRef(null);

  /**
   * to avoid style problems
   */
  useEffect(() => {
    setCssVariables(config.theme || defaultLocalTheme);
  }, [config]);

  const prevFocusForNetworkStatus = useRef();

  const onStartUp = useCallback(
    async () => context.checkAuthStatusCognito(config.userpoolId, config.clientId),
    [config.clientId, config.userpoolId, context],
  );

  const addNetworkError = useCallback(
    (title, description) => {
      addError({
        title,
        description,
        acceptText: getTranslation('retry'),
        onAccept: () => {
          addNetworkError(' ', getTranslation('tryingToReconnect'));
          clearTimeout(networkTimeoutId.current);
          networkTimeoutId.current = setTimeout(() => {
            addNetworkError(getTranslation('noInternetErrorTitle'), getTranslation('noInternetErrorDescription'));
          }, 2000);
        },
      });
    },
    [addError],
  );

  useEffect(() => {
    if (networkStatus === false) {
      prevFocusForNetworkStatus.current = focusManager.getCurrentFocus();
      addNetworkError(getTranslation('noInternetErrorTitle'), getTranslation('noInternetErrorDescription'));
    } else {
      if (networkTimeoutId.current) {
        clearTimeout(networkTimeoutId.current);
      }
      removeError();
      if (prevFocusForNetworkStatus && prevFocusForNetworkStatus.current) {
        focusManager.changeFocus(prevFocusForNetworkStatus.current);
      }
    }
  }, [networkStatus, addError, removeError, addNetworkError]);

  useEffect(() => {
    if (config.clientId && config.userpoolId) {
      onStartUp();
    }
  }, [config.clientId, config.userpoolId, onStartUp]);

  useEffect(() => {
    const getGenresList = async () => {
      const genres = await getGenres();
      setGenresList(genres);
    };
    if (!sessionKey || !Object.keys(config).length) {
      return;
    }
    getGenresList();
  }, [setGenresList, sessionKey, config]);

  useEffect(() => {
    if (config.status === 'Active') {
      focusManager.setPersistTrail(true);
    }
  }, [config]);

  // Remove the splash screen
  useEffect(() => {
    if (config.status === 'Active' && Object.keys(config).length > 0) {
      const splash = document.getElementById('splash');
      splash?.remove?.();
    }
  }, [config, localeCode]);

  if (config?.errorLoading) {
    focusManager.changeFocus('ERROR_POPUP');
    return (
      <Popup
        nav={{
          id: 'ERROR_POPUP',
        }}
        isOpen
        title={getTranslation('loadErrorTitle')}
        content={<span>{getTranslation('loadErrorMessage')}</span>}
        onAccept={() => {
          window.location.reload();
        }}
        theme={styles}
        acceptText={getTranslation('okButton')}
      />
    );
  }

  // If we haven't yet received an application status, we'll
  // simply return an empty div.
  if (!localeCode) {
    return <div className={styles.rootTransparent} />;
  }

  // We require the configuration to have been
  // provided before we render the actual app content.
  // simply return an empty div.
  if (!Object.keys(config).length) {
    return <div className={kidsMode ? styles.root : styles.rootTransparent} />;
  }

  // If we're in maintenance mode, we'll display
  // the given message or fall back to a default one.
  if (config.status === MAINTENANCE) {
    return <SplashScreen status={SplashScreen.status.MAINTENANCE} />;
  }

  // TODO: uncomment if you want to make the login blocking the app
  // if (this.context.isCheckingAuth) {
  //   return (
  //     <SplashScreen status={SplashScreen.status.NO_CONDATA_LOADINGFIG} />
  //   );
  // }

  // At this point we have both an active status and
  // a configuration object. Let's render the app!
  return (
    <PageProvider>
      <PointerContextProvider>
        <div className={`${styles.root} ${platform}`}>{children}</div>
      </PointerContextProvider>
    </PageProvider>
  );
}

TVAppContent.propTypes = {
  platform: PropTypes.string,
  children: PropTypes.node,
};

export default TVAppContent;
