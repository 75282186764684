import useAppConfig from '#/hooks/useAppConfig';
import defaultLocalTheme from '#/theme/defaultTheme.json';

const useBadgeTheme = ({ data, tileBadge = false }) => {
  const { config = {} } = useAppConfig();
  const defaultTheme = config.theme || defaultLocalTheme;
  if (tileBadge) {
    return {};
  }
  const badgeStyle = {};

  switch (data) {
    case 'Red':
      badgeStyle.color = defaultTheme.textPrimary;
      badgeStyle.backgroundColor = defaultTheme.customBadgeColourRed;
      break;
    case 'Blue':
      badgeStyle.color = defaultTheme.textPrimary;
      badgeStyle.backgroundColor = defaultTheme.customBadgeColourBlue;
      break;
    default:
  }
  return { badgeStyle };
};

export default useBadgeTheme;
