/**
 * @module services/routes
 * @description
 * Service to handle the fetch of Routes to do the mapping
 */

import { PROVIDER_TYPE } from '#/config/constants';
import routeModel from '#/models/route';
import cms from '#/providers/cms';

import fetcher from '../../helpers/fetcher';

const getRoutesMapping = async () => {
  const routesMapping = [];
  const routesMappingResponse = await fetcher({
    cacheId: `${PROVIDER_TYPE.cms}-routeMapping`,
    fetchFn: () => cms.getRoutesMapping(),
  });
  routesMappingResponse?.forEach((route) => {
    routesMapping.push(routeModel(route));
  });

  return routesMapping;
};

// eslint-disable-next-line import/prefer-default-export -- TODO: Automatically surpressed error. Resolve when you encounter this file!
export { getRoutesMapping };
