import winston from 'winston';

import config from '#/config';

const { transports, level } = config.app.logger;

const logger = winston.createLogger({ level });

transports.forEach((transport) => {
  if (!transport.enabled) {
    return;
  }

  logger.add(new winston.transports[transport.name](transport.options));
});

// Export the prepared logger instance
export default logger;
