import React, { useEffect } from 'react';

import { focusManager } from '@accedo/vdkweb-navigation';

import Popup from '#/components/Popup/Popup';
import useNetworkStatus from '#/hooks/useNetworkStatus';
import { getTranslation } from '#/services/i18n';

const POPUP_ID = 'NETWORK_ERROR_POPUP';

const appReload = () => {
  // clear browser history
  if (window.history.replaceState) {
    window.history.replaceState(null, '', window.location.origin);
  }
  // reload page, the url only contains origin, no query parameters
  window.location.replace(window.location.origin);
};

function NetworkError() {
  const { networkStatus } = useNetworkStatus();
  useEffect(() => {
    focusManager.changeFocus(POPUP_ID);
  }, []);
  useEffect(() => {
    if (networkStatus === true) {
      appReload();
    }
  }, [networkStatus]);
  return (
    <Popup
      nav={{
        id: POPUP_ID,
      }}
      isOpen
      title={getTranslation('noInternetErrorTitle')}
      content={<span>{getTranslation('noInternetErrorDescription')}</span>}
      onAccept={appReload}
      acceptText={getTranslation('okButton')}
    />
  );
}

export default NetworkError;
