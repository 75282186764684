import { CONTAINER_ITEM_TYPES } from '#/config/constants';
/**
 * @module models/bookmark
 */

/**
 * For Movies and Episodes
 * @typedef {Object} Bookmark
 * @property {String} id Bookmark item ID
 * @property {String} title Bookmark title
 * @property {String} type Bookmark type
 * @property {String} assetType Bookmark type
 * @property {String} description Bookmark type
 * @property {String} category Bookmark category
 * @property {String} categories Bookmark categories
 * @property {number} progress Bookmark current progress
 * @property {String} metadata Bookmark metadata
 * @property {number} resumeTime Bookmark resume time
 * @property {string} duration Media duration
 * @property {String} videoUrl Array of the images of the Bookmark item
 * @property {number} publishedDate Bookmark publishedDate
 * @property {String} backgroundUrl Bookmark backgroundUrl
 * @property {String} coverUrl Bookmark coverUrl
 * -> The following properties is only applied for MOVIE
 * @property {Array<Image>} images Movie images
 * @property {String} link Link to the movie details page
 * @property {number} categoryId Movie category ID associated
 * -> The following properties is only applied for EPISODE
 * @property {Array<Episode> | undefined} episodes Season episodes
 * @property {number | undefined} episodeNumber Episode number
 * @property {String | undefined} episodeTitle Episode title
 * @property {Array<Season> | undefined} seasons TVShow seasons of the current episode
 * @property {number | undefined} seasonNumber Current season number
 * @property {String | undefined} seasonId SeasonID of the current episode
 * @property {String | undefined} showId TVShow id of the current episode
 * @property {String | undefined} showTitle TVShow title of the current episode
 * @property {String | undefined} tvShowDescription TVShow description of the current episode
 */

/**
 * @description
 * Bookmark Model
 * @param {Object} rawBookmark Bookmark
 * @returns {Bookmark} Bookmark
 */
const Bookmark = (rawBookmark) => {
  const { resumeTime, id, assetType, showId, playlistId } = rawBookmark;

  /**
   * Get the time in milliseconds for resuming playback.
   * @returns {number} resumeTime
   */
  const getResumeTime = () => resumeTime; // milliseconds

  /**
   * Get item type.
   * @returns {string} Type
   */
  const getType = () => CONTAINER_ITEM_TYPES.Bookmark;

  /**
   * Gets the asset type Movie | Episode
   * @returns {string} The asset type
   */
  const getAssetType = () => assetType;

  /**
   * Gets the asset id
   * @returns {string} The asset id
   */
  const getAssetId = () => id;

  /**
   * Gets the show id
   * @returns {string} The show id
   */
  const getShowId = () => showId;

  /**
   * Gets the playlist Id
   * @returns {string} The playlist Id
   */
  const getPlaylistId = () => playlistId;

  return {
    assetType: getAssetType(),
    resumeTime: getResumeTime(),
    type: getType(),
    id: getAssetId(),
    showId: getShowId(),
    playlistId: getPlaylistId(),
  };
};

export default Bookmark;
