/**
 * @module services/seaons
 * @description
 * Service to handle the fetch of season data
 */

import { PROVIDER_TYPE } from '#/config/constants';
import seasonModel from '#/models/seasonCms/season';
import cms from '#/providers/cms';

import fetcher from '../../helpers/fetcher';

const getEntries = async (id) => {
  const seasonMapping = [];
  const rawSeasons = await fetcher({
    cacheId: `${PROVIDER_TYPE.cms}-show-seasons-${id}`,
    fetchFn: () => cms.getEntries(id),
  });

  rawSeasons?.forEach((season) => {
    seasonMapping.push(seasonModel(season));
  });

  return seasonMapping;
};

// eslint-disable-next-line import/prefer-default-export -- TODO: Automatically surpressed error. Resolve when you encounter this file!
export { getEntries };
