import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { getProtocolRelativePathUrl } from '#/utils/url';

import style from './AssetBackground.scss';

/**
 * AssetBackground Component
 * @param {Object} props Component Props
 * @param {String} props.backgroundUrl Background Image
 * @returns {React.ReactComponentElement} AssetBackground Component
 */
function AssetBackground({ backgroundUrl, playerShown }) {
  return (
    <div
      className={classNames(style.container, {
        [style.hide]: playerShown,
      })}
    >
      <img src={getProtocolRelativePathUrl(backgroundUrl)} alt="" />
    </div>
  );
}

AssetBackground.propTypes = {
  backgroundUrl: PropTypes.string,
  playerShown: PropTypes.bool,
};

export default AssetBackground;
