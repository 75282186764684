import accedoOne from '@accedo/accedo-one';
import cookie from 'react-cookie';

import controlConfig, { DEVICE_ID, SESSION_KEY, SIXTY_YEARS_IN_MS } from './config';

const getControlClient = (config) => {
  if (!controlConfig?.appKey && !config?.accedoOne?.appKey) {
    console.error('No Accedo One settings available..');
  }

  let controlSettings = config;
  // eslint-disable-next-line no-undef
  if (__CLIENT__) {
    controlSettings = Object.assign(controlSettings, {
      browserInfoProvider: () => ({
        deviceId: cookie.load(DEVICE_ID),
        sessionKey: cookie.load(SESSION_KEY),
      }),
      onDeviceIdGenerated: (id) => {
        cookie.save(DEVICE_ID, id, { path: '/', maxAge: SIXTY_YEARS_IN_MS });
      },
      onSessionKeyChanged: (key) => {
        cookie.save(SESSION_KEY, key, {
          path: '/',
          maxAge: SIXTY_YEARS_IN_MS,
        });
      },
    });
  }
  return accedoOne(controlSettings);
};

// eslint-disable-next-line import/prefer-default-export -- TODO: Automatically surpressed error. Resolve when you encounter this file!
export const accedoOneClientInstance = getControlClient(controlConfig);
