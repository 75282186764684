/**
 * @module models/CMS
 */
/**
 * This model represents the Menu.
 *
 * vikiElevateMenu representation
 * @typedef {Object} VikiElevateMenu
 * @property {String} title title (on CMS) property
 * @property {String} displaytext menu text property
 * @property {Boolean} isglobal Whether the menu is a global menu or not
 * @property {VikimapMenuItem[]} items Menu items
 */

import menuItem from './menuItem';

/**
 * VikiElevateMenu Model
 * @param {Object} rawMenu Menu
 * @returns {VikiElevateMenu} menu - The {@link VikiElevateMenu} representation
 */
const menu = (rawMenu) => {
  const { title, displaytext, isglobal, items = [] } = rawMenu;
  /**
   * Get the item's CMS title
   * @returns {String} title
   */
  const getTitle = () => title;
  /**
   * Get the item's Display Text
   * @returns {String} display text
   */
  const getDisplayText = () => displaytext;
  /**
   * Get the item's displayText
   * @returns {String} displaytext
   */
  const getItems = () => items.map((item) => menuItem(item));
  /**
   * Get the item's displayText
   * @returns {String} displaytext
   */
  const getIsGlobal = () => isglobal;

  return {
    title: getTitle(),
    displayText: getDisplayText(),
    isglobal: getIsGlobal(),
    items: getItems(),
  };
};

export default menu;
