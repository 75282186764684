import React from 'react';

import PropTypes from 'prop-types';

import ContentGrid from '#/components/ContentGrid/ContentGrid';
import HeroItem from '#/components/Item/HeroItem/HeroItem';
import useI18n from '#/hooks/useI18n';
import { actionClickItem } from '#/services/analytics';

import heroBannerTheme from './heroBanner.scss';

const itemWidth = 1920;
const itemHeight = 1080;

function HeroShelf({ onClick, items, config = {}, nav = {}, ds }) {
  const { currentLocale = {} } = useI18n();
  const { dir } = currentLocale;

  return (
    <ContentGrid
      data={items}
      ds={ds}
      dir={dir}
      keyProperty="id"
      itemWidth={itemWidth}
      itemHeight={itemHeight}
      horizontal
      headPadding={0}
      tailPadding={0}
      spacing={0}
      width={1920}
      loop
      theme={heroBannerTheme}
      onClick={(source) => {
        actionClickItem(source.data, config.displayText);
        onClick(source.data);
      }}
      DisplayComponent={HeroItem}
      displayComponentProps={{ height: itemHeight }}
      nav={{
        ...nav,
        id: config.id || nav.id,
      }}
      initialState={{
        id: items?.[0]?.id,
        position: 0,
      }}
      showDots
    />
  );
}

HeroShelf.propTypes = {
  onClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  ds: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  items: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  config: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  nav: PropTypes.object,
};

export default HeroShelf;
