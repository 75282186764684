/**
 * @module services/page
 * @description
 * Service to handle the fetch of menu items
 */

import { PROVIDER_TYPE } from '#/config/constants';
import pageModel from '#/models/page';
import cms from '#/providers/cms';

import fetcher from '../../helpers/fetcher';

const getPageLayout = async ({ id, locale }) => {
  const rawPage = await fetcher({
    cacheId: `${PROVIDER_TYPE.cms}-${id}${locale ? `-${locale}` : ''}`,
    fetchFn: () => cms.getPageLayout({ id, options: { locale } }),
  });

  const page = pageModel(rawPage);

  return page;
};

// eslint-disable-next-line import/prefer-default-export -- TODO: Automatically surpressed error. Resolve when you encounter this file!
export { getPageLayout };
