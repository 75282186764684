import { useContext, useCallback } from 'react';

import { ConfigContext, ConfigSetcontext } from '#/context/ConfigContext';
import { getConfiguration, getDefaultTheme, getRoutesMapping } from '#/services/cms';

let configRequestPending = false;

const useAppConfig = () => {
  const configContext = useContext(ConfigContext);
  const setConfigContext = useContext(ConfigSetcontext);

  if (setConfigContext === undefined || configContext === undefined) {
    throw new Error('useAppConfig must be used within a ConfigProvider');
  }

  const getConfigData = useCallback(
    async (kidsMode) => {
      if (configRequestPending) {
        return;
      }
      configRequestPending = true;
      let appConfig = {};
      try {
        appConfig = await getConfiguration(kidsMode);
        const theme = await getDefaultTheme();
        const routesMapping = await getRoutesMapping();
        appConfig.theme = theme;
        appConfig.routesMapping = routesMapping;
        configRequestPending = false;
        setConfigContext({ config: appConfig });
        // eslint-disable-next-line consistent-return -- TODO: Automatically surpressed error. Resolve when you encounter this file!
        return appConfig;
      } catch (error) {
        const config = { errorLoading: true };
        setConfigContext({ config });
        // eslint-disable-next-line consistent-return -- TODO: Automatically surpressed error. Resolve when you encounter this file!
        return config;
      }
    },
    [setConfigContext],
  );

  const getConfig = useCallback(() => {
    const { config, kidsMode } = configContext;
    if (!config) {
      getConfigData(kidsMode);
      return {};
    }
    return config;
  }, [getConfigData, configContext]);

  return {
    config: getConfig(),
  };
};

export default useAppConfig;
