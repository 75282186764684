/**
 * @module services/config
 * @description
 * Service to handle the fetch of config file from the CMS.
 * @return {VikiElevateConfig} The modelised config file.
 */

import { PROVIDER_TYPE } from '#/config/constants';
import configModel from '#/models/config';
import cms from '#/providers/cms';

import fetcher from '../../helpers/fetcher';

const getConfiguration = async (kidsMode) => {
  const rawConfig = await fetcher({
    cacheId: kidsMode ? '' : `${PROVIDER_TYPE.cms}-configuration`,
    fetchFn: () => cms.getConfiguration(kidsMode),
  });
  const config = configModel(rawConfig);

  return config;
};

// eslint-disable-next-line import/prefer-default-export -- TODO: Automatically surpressed error. Resolve when you encounter this file!
export { getConfiguration };
