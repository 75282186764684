import React from 'react';

import PropTypes from 'prop-types';

import useI18n from '#/hooks/useI18n';

import styles from './skeletons.scss';

const ITEM_TYPE = {
  PORTRAIT: 'portrait',
  LANDSCAPE: 'landscape',
};

// GridSkeleton returns a grid of movie or show items to simulate that is trying to load elements.
// Depending of itemType will get different CSS styling.
function GridSkeleton({
  isCarousel,
  itemType = ITEM_TYPE.LANDSCAPE,
  withTitle,
  withSpacing,
  smallItems,
  // The skeleton is a little different for a movie or a show view
  isShow = false,
  isMyContent = false,
}) {
  const { currentLocale = {} } = useI18n();
  const { dir } = currentLocale;

  const orientation = itemType === ITEM_TYPE.PORTRAIT ? ITEM_TYPE.PORTRAIT : ITEM_TYPE.LANDSCAPE;

  let itemsCount;
  if (isCarousel) {
    itemsCount = orientation === ITEM_TYPE.PORTRAIT ? 5 : 3;
  } else if (!isCarousel && (isShow || isMyContent)) {
    itemsCount = 4;
  } else {
    itemsCount = 5;
  }

  const seasons = 3;
  const baseColorStyle = styles.baseColor;
  const style = `${styles.itemSkeleton} ${styles[`${orientation}Skeleton`]}`;
  const itemStyle = smallItems ? `${style} ${styles.smallSkeleton}` : style;

  const containerStyle = `${withTitle ? styles.gridSkeleton : ''} ${withSpacing ? styles.spacingGrid : ''}`;

  const titleSkeletonStyle =
    withTitle && !isShow && !isMyContent ? `${styles.titleSkeleton}` : `${styles.titleSkeletonLowMargin}`;

  const posterListStyle =
    !isCarousel && !isShow && !isMyContent ? `${styles.posterList}` : `${styles.posterListContentLeft}`;

  return (
    <div className={`${containerStyle} ${styles[dir]}`} dir={dir}>
      {withTitle && !isShow && <div className={`${titleSkeletonStyle} ${baseColorStyle}`} />}

      {!isCarousel && !isShow && (
        <div className={posterListStyle}>
          {[...Array(itemsCount)].map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key -- TODO: Automatically surpressed error. Resolve when you encounter this file!
            <div key={`gridItem${index}${item}`} className={`${itemStyle} ${baseColorStyle}`} />
          ))}
        </div>
      )}

      {!isCarousel && isShow && (
        <div className={`${styles.showContainer}`}>
          <div className={`${styles.seasonsContainer}`}>
            <div className={`${styles.text} ${baseColorStyle}`} />

            <div className={`${styles.seasonsList}`}>
              {[...Array(seasons)].map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key -- TODO: Automatically surpressed error. Resolve when you encounter this file!
                <div key={`season${index}${item}`} className={`${styles.seasonItem} ${baseColorStyle}`} />
              ))}
            </div>
          </div>

          <div className={`${styles.showPosterList}`}>
            {[...Array(itemsCount)].map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key -- TODO: Automatically surpressed error. Resolve when you encounter this file!
              <div key={`gridItem${index}${item}`} className={`${itemStyle} ${baseColorStyle}`} />
            ))}
          </div>
        </div>
      )}

      {isCarousel && (
        <div>
          {[...Array(itemsCount)].map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key -- TODO: Automatically surpressed error. Resolve when you encounter this file!
            <div key={`gridItem${index}${item}`} className={`${itemStyle} ${baseColorStyle}`} />
          ))}
        </div>
      )}
    </div>
  );
}

GridSkeleton.propTypes = {
  itemType: PropTypes.string,
  isCarousel: PropTypes.bool,
  smallItems: PropTypes.bool,
  withTitle: PropTypes.bool,
  withSpacing: PropTypes.bool,
  // Get the skeleton for a movie or a show detail view
  isShow: PropTypes.bool,
  // Get the skeleton for My content view
  isMyContent: PropTypes.bool,
};

GridSkeleton.item_type = ITEM_TYPE;

export default GridSkeleton;
