import * as vikimap from '@accedo/vdkweb-vikimap';
import dayjs from 'dayjs';
import { DateTime } from 'luxon';
import cookie from 'react-cookie';

import { getChannelData, getPromises, getPrograms } from '#/providers/epg';
// eslint-disable-next-line import/order -- TODO: Automatically surpressed error. Resolve when you encounter this file!
import config from '#/config';

/**
 * @module providers/cms/control
 * @description
 * Provider CMS implementation from Accedo Control
 */

import controlConfig, {
  APPLICATION_KEY,
  DEVICE_ID,
  SESSION_KEY,
  SIXTY_YEARS_IN_MS,
} from '#/providers/shared/control/config';
import { accedoOneClientInstance } from '#/providers/shared/control/control';

const appConfig = config.app;
let accedoControlService = null;
let configuration = null;
let menu = null;
let menuLocaleCode = null;
const domain = process.env.REACT_APP_CONTROL_DOMAIN;
const params = `preview=${process.env.REACT_APP_CONTROL_PREVIEW}&locale=${process.env.REACT_APP_CONTROL_LOCALE}${
  process.env.REACT_APP_CONTROL_GID && `&gid=${process.env.REACT_APP_CONTROL_GID}`
}&offset=0&size=50`;

const CONFIG_KEYS_TOCHECK = ['defaultLocale', 'dictionary', 'mainMenuEntryId'];

const checkEmptyValue = (configKey, metadata) => {
  if (metadata[configKey] === undefined || metadata[configKey] === '') {
    if (appConfig[configKey] === undefined || appConfig[configKey] === '') {
      console.error(
        `[debug] Config Key ${configKey} is not set up, please review your local and remote config to add it`,
      );
      return;
    }
    // eslint-disable-next-line no-param-reassign -- TODO: Automatically surpressed error. Resolve when you encounter this file!
    metadata[configKey] = appConfig[configKey];
  }
};

const checkEmptyValues = (metadata) => {
  CONFIG_KEYS_TOCHECK.forEach((configKey) => {
    checkEmptyValue(configKey, metadata);
  });

  return metadata;
};

/**
 *
 * Fetch the Configuration CMS content
 *
 * @returns {Configuration} The Configuration
 */
const getConfiguration = async (kidsMode) => {
  if (configuration && !kidsMode) {
    return configuration;
  }
  const metadata = await accedoOneClientInstance.getAllMetadata();
  const assets = await accedoOneClientInstance.getAllAssets();
  const status = await accedoOneClientInstance.getApplicationStatus();

  checkEmptyValues(metadata, appConfig); // It will use local config if available when not on remote
  configuration = {
    ...appConfig,
    ...status,
    ...metadata,
    assets,
  };
  return configuration;
};

/**
 *
 * Fetch the Menu CMS content
 *
 * @param {String} id the Menu id
 * @param {any} options the Control options as defined in the Control documentation
 *
 * @returns {Menu} The Menu
 */
const getMenu = async ({ id, options, kidsMode }) => {
  if (!kidsMode && menu && options?.locale === menuLocaleCode) {
    return menu;
  }
  menu = await accedoControlService.getMenu(id, options);
  menuLocaleCode = options?.locale || configuration?.defaultLocale;
  return menu;
};

const getRoutesMapping = async () => {
  const { routesMapping: routesMappingId, mainMenuEntryId } = await getConfiguration();
  let routesMapping;
  if (routesMappingId) {
    routesMapping = await accedoControlService.getItem(routesMappingId);
    routesMapping.mappings = await accedoControlService.getItemsByIds(routesMapping.mappings);
    const pageIds = routesMapping.mappings.map((map) => map.page);
    const pageResponses = await accedoControlService.getItemsByIds(pageIds);
    routesMapping.mappings.forEach((mapItem) => {
      const pageResponse = pageResponses.find((pageResponseItem) => pageResponseItem.id === mapItem.page);
      // eslint-disable-next-line no-param-reassign -- TODO: Automatically surpressed error. Resolve when you encounter this file!
      mapItem.page = pageResponse;
    });
  }
  if (mainMenuEntryId) {
    const menuResponse = await getMenu({ id: mainMenuEntryId });
    menuResponse.items.forEach((menuItem) => {
      routesMapping.mappings.push(menuItem);
    });
  }
  return routesMapping?.mappings || [];
};

/**
 *
 * Fetch the Page CMS content
 *
 * @param {String} id the Page id
 * @param {any} options the Control options as defined in the Control documentation
 *
 * @returns {Page} The page
 */
const getPageLayout = async ({ id, options }) => accedoControlService.getPage(id, options);

const getDefaultTheme = async () => {
  const { defaultTheme } = await getConfiguration();
  let theme;
  if (defaultTheme) {
    theme = await accedoControlService.getItem(defaultTheme);
  }
  return theme;
};

/**
 *
 * Fetch the Page Theme CMS content
 *
 * @param {String} id the Page id
 * @param {any} options the Control options as defined in the Control documentation
 *
 * @returns {Page} The theme
 */
const getThemeByPage = async ({ id, options }) =>
  accedoOneClientInstance.getEntryById(id, options).then((page) => {
    if (!page.theme) {
      return;
    }
    // eslint-disable-next-line consistent-return -- TODO: Automatically surpressed error. Resolve when you encounter this file!
    return accedoOneClientInstance.getEntryById(page.theme, options);
  });

export const cleanAccedoOneSessionInClient = () => {
  const appKey = cookie.load(APPLICATION_KEY);

  if (appKey !== controlConfig.appKey && controlConfig.appKey) {
    cookie.remove(APPLICATION_KEY);
    cookie.remove(DEVICE_ID);
    cookie.remove(SESSION_KEY);

    cookie.save(APPLICATION_KEY, controlConfig.appKey, {
      path: '/',
      maxAge: SIXTY_YEARS_IN_MS,
    });
  }
};

const getGenres = async () => {
  const data = await fetch(`${domain}/content/entries?typeAlias=genre&${params}`, {
    headers: { 'X-Session': cookie.load(SESSION_KEY) },
  });

  return data.json();
};

const getEntries = async (id) => {
  if (!id) {
    return Promise.resolve(null);
  }
  const seasonEntries = await accedoControlService.getEntriesByIds([id]);

  return seasonEntries;
};

export const resetAccedoOneService = () => {
  if (accedoControlService) {
    accedoOneClientInstance.config.gid = 'kids';
    accedoControlService = vikimap.getAccedoOneService(accedoOneClientInstance);
  }
};

const getTvListings = async ({ startTime, endTime, count, offset, epgApiEndpoint }) => {
  const dayObject = dayjs.unix(startTime / 1000);
  const startOfDayEpochMs = dayObject.startOf('day').valueOf();
  const startFormatted = DateTime.fromMillis(startOfDayEpochMs).toUTC().toISO();
  const endFormatted = DateTime.fromMillis(endTime).toUTC().toISO();

  const channels = await getChannelData();

  const promises = getPromises({
    channels,
    offset,
    count,
    startFormatted,
    endFormatted,
    epgApiEndpoint,
  });

  const results = await Promise.all(promises);

  // eslint-disable-next-line consistent-return
  return {
    entries: results.map((result) => {
      const channelData = channels.entries.find((channel) => channel.bcChannelId === result.bcChannelId);

      return {
        ...(channelData || {}),
        ...result,
        programs: getPrograms({
          result,
          channelData,
          startTime,
          endTime,
          endFormatted,
        }),
      };
    }),
  };
};

export default () => {
  if (!accedoControlService) {
    accedoControlService = vikimap.getAccedoOneService(accedoOneClientInstance);
  }

  return {
    getMenu,
    getRoutesMapping,
    getConfiguration,
    getPageLayout,
    getDefaultTheme,
    getThemeByPage,
    getGenres,
    getEntries,
    getTvListings,
  };
};
