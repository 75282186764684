/**
 * This Context provides methods to be used in your views. It also
 * share the state of the user, providing `isAuthenticated` flag
 * to indicate if there is a logged in user.
 */
import React, { useState, useMemo, useCallback, createContext } from 'react';

import PropTypes from 'prop-types';

import { invalidateCache } from '#/services';
import {
  validateUser,
  loginByPairingCode,
  getPairingCode as getPairingCodeService,
  forgotPassword as forgotPasswordService,
  loginCognito,
  logoutCognito,
} from '#/services/idp';
import { getSession } from '#/services/idp/cognitoAuth';

/**
 * Login by credentials definition
 * @typedef {Function} LoginByCredentials
 * @property {String} username
 * @property {String} password
 */

/**
 * Login by pairing code
 * @typedef {Function} LoginByPairingCode
 */

/**
 * Definition of login alternatives used by this template
 * @typedef {Object} LoginAlternatives
 * @property {LoginByCredentials} byCredentials
 * @property {LoginByPairingCode} byPairingCode
 */

const AuthContext = createContext({
  /**
   * Indicate if there is an authenticated user
   * @type {Boolean}
   */
  isAuthenticated: false,
  /**
   * Login alternatives used by this template
   * @returns {LoginAlternatives} Login alternatives
   */
  login: () => {},
  /**
   * Logout user
   * @returns {Promise<Boolean>} Logout success
   */
  logout: () => {},

  /**
   * Get the current logged user
   * @returns {User} Logged user
   */
  getUser: () => {},

  /**
   * Get a code to pair the device with a specific user account
   * @param {String} deviceId Device to pair
   * @returns {Promise<String>} Pairing code
   */
  getPairingCode: async (deviceId) => Promise.resolve(deviceId),
});

/**
 * Auth Context Provider
 * @param {Object} props Context Props
 * @param {React.ReactNode} props.children Context Props
 * @returns {React.Context} Auth Context
 */
function AuthContextProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loggedUser, setLoggedUser] = useState({
    username: null,
  });

  /**
   * Get the current logged user
   * @returns {User} Current logged user
   */
  const getUser = useCallback(() => loggedUser, [loggedUser]);

  /**
   * Login alternatives used by this template
   * @property {Function} byCredentials
   * @returns {LoginAlternatives} Login alternatives
   */
  const login = useCallback(() => {
    /**
     * Login user by credentials
     * @param {String} username Username
     * @param {String} password Password
     * @returns {Promise<User>} Loggedin User
     */
    const byCredentials = async (username, password, userpoolId, clientId) =>
      loginCognito({ email: username, password }, { userpoolId, clientId }).then((user) => {
        setLoggedUser(user);
        setIsAuthenticated(true);
        invalidateCache();
      });

    /**
     * Login user by pairing code
     * @param {String} deviceId Device to pair
     * @param {AbortController} abortController Controller to abort the request if needed
     * @returns {Promise<void>} Void
     */
    const byPairingCode = async (deviceId, abortController) =>
      loginByPairingCode(deviceId, abortController).then((user) => {
        if (user) {
          setLoggedUser(user);
          setIsAuthenticated(true);
          invalidateCache();
        }
      });

    return { byCredentials, byPairingCode };
  }, []);

  /**
   * Get a code to pair the device with a specific user account
   * @param {String} deviceId Device to pair
   * @returns {Promise<String>} Pairing code
   */
  const getPairingCode = useCallback(
    async (deviceId) => getPairingCodeService(deviceId).then((result) => result.code),
    [],
  );

  /**
   * Logout user
   * @returns {Promise<Boolean>} Logout success
   */
  const logout = useCallback(async (userpoolId, clientId) => {
    logoutCognito({ userpoolId, clientId });
    setLoggedUser(null);
    setIsAuthenticated(false);
    invalidateCache();
  }, []);

  /**
   * resetPassword user
   * @param {String} username Username
   * @returns {Promise<String>} ResetPassword success
   */
  const resetPassword = useCallback(async (username) => {
    const emailSent = forgotPasswordService(username);

    return Promise.resolve(emailSent);
  }, []);

  /**
   * Check if a user is logged in and update the app state
   * @returns {Promise<Object>} User
   */
  const checkAuthStatus = useCallback(async () => {
    try {
      const user = await validateUser();
      if (user) {
        setLoggedUser(user);
        setIsAuthenticated(true);
      }
      return user;
    } catch (e) {
      logout();
      return {};
    }
  }, [logout]);

  /**
   * Check if a user is logged in and update the app state
   * @returns {Promise<Object>} User
   */
  const checkAuthStatusCognito = useCallback(async (userpoolId, clientId) => {
    try {
      const session = await getSession({ userpoolId, clientId });
      setIsAuthenticated(session);
      return {};
    } catch (e) {
      setIsAuthenticated(false);
      return {};
    }
  }, []);

  const contextValue = useMemo(
    () => ({
      login,
      logout,
      getUser,
      isAuthenticated,
      getPairingCode,
      checkAuthStatus,
      resetPassword,
      checkAuthStatusCognito,
    }),
    [login, logout, getUser, isAuthenticated, getPairingCode, checkAuthStatus, resetPassword, checkAuthStatusCognito],
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

export { AuthContext, AuthContextProvider };

AuthContextProvider.propTypes = {
  children: PropTypes.node,
};
