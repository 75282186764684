import { useCallback, useContext } from 'react';

import { HistoryContext, HistorySetContext } from '#/context/AppHistoryContext';

const useResetStack = () => {
  const historyContext = useContext(HistoryContext);
  const setContext = useContext(HistorySetContext);
  if (historyContext === undefined || setContext === undefined) {
    throw new Error('useResetStack must be used within a HistoryProvider');
  }
  return useCallback(
    () =>
      setContext({
        ...historyContext,
        stack: [],
      }),
    [setContext, historyContext],
  );
};

export default useResetStack;
