const navIdMap = {
  TEST_PAGE: 'TEST_PAGE',
  EPG_PAGE: 'EPG_PAGE',
  MENU: {
    HEADER: {
      CONTAINER: 'HEADER_CONTAINER',
      MENU: 'HEADER_MENU',
      MENU_ITEM: 'MENU_',
      USER_CONTROL: 'USER_CONTROL',
      SEARCH_BUTTON: 'SEARCH_BUTTON',
      SETTING_BUTTON: 'SETTING_BUTTON',
      MY_CONTENT_BUTTON: 'MENU_primary-item-my-content',
    },
    FOOTER: {
      MENU: 'FOOTER_MENU',
    },
  },
  PAGE: {
    ABOUT: {
      ACTION_BUTTON: 'ACTION_BUTTON',
      CONTAINER: 'CONTAINER',
      PAGE: 'PAGE',
    },
    CONTAINER: {
      PAGE: 'CONTAINER_PAGE',
      LAYOUT: 'CONTAINER_LAYOUT',
    },
    DETAIL: {
      ASSET_ACTIONS: {
        CONTAINER: 'ACTIONS_CONTAINER',
        PLAY_BUTTON: 'DETAIL_PLAY_BUTTON',
        LOGIN_BUTTON: 'DETAIL_LOGIN_BUTTON',
        SUBSCRIBE_BUTTON: 'DETAIL_SUBSCRIBE_BUTTON',
        FAVOURITE_BUTTON: 'DETAIL_FAVOURITE_BUTTON',
        RESUME_BUTTON: 'DETAIL_RESUME_BUTTON',
        NO_VIDEO_BUTTON: 'NO_VIDEO_BUTTON',
        PLAY_TRAILER_BUTTON: 'PLAY_TRAILER_BUTTON',
      },
      MORE_LIKE_THIS: {
        CAROUSEL_CONTAINER: 'CAROUSEL_CONTAINER',
        CAROUSEL: 'CAROUSEL',
      },
    },
    SHOWS: {
      PAGE: 'SHOWS_PAGE',
      SHOWS_CAROUSEL_CONTAINER: 'SHOWS_CAROUSEL_CONTAINER',
      SHOWS_CAROUSEL: 'SHOWS_CAROUSEL',
    },
    FAVOURITES: {
      PAGE: 'FAVOURITES_PAGE',
      CAROUSEL_ENTRY: 'FAVOURITES_CAROUSEL_ENTRY',
      CONTAINER: 'FAVOURITES_CONTAINER',
    },
    EPISODE_DETAILS: {
      PAGE: 'EPISODE_DETAILS_PAGE',
      INFO: 'EPISODE_DETAILS_INFO',
      SEASON_EPISODES: 'EPISODE_DETAILS_SEASON_EPISODES',
      EPISODES_CAROUSEL_CONTAINER: 'EPISODE_DETAILS_EPISODES_CAROUSEL_CONTAINER',
      EPISODES_CAROUSEL: 'EPISODE_DETAILS_EPISODES_CAROUSEL',
      SEASONS_BUTTON: 'EPISODE_DETAILS_SEASONS_BUTTON',
      SEASON_LIST: 'EPISODE_DETAILS_SEASON_LIST',
      CAROUSEL: 'EPISODE_DETAILS_CAROUSEL',
      EPISODE_DETAIL_POPUP_PAGE: 'EPISODE_DETAIL_POPUP_PAGE',
      EPISODE_DETAIL_POPUP: 'EPISODE_DETAIL_POPUP',
    },
    MOVIE_DETAILS: {
      PAGE: 'MOVIE_DETAILS_PAGE',
      INFO: 'MOVIE_DETAILS_CONTAINER',
      MOVIES_CAROUSEL_CONTAINER: 'MOVIE_DETAILS_MOVIES_CAROUSEL_CONTAINER',
      CAROUSEL: 'MOVIE_DETAILS_CAROUSEL',
      MOVIE_DETAIL_POPUP_PAGE: 'MOVIE_DETAIL_POPUP_PAGE',
      MOVIE_DETAIL_POPUP: 'MOVIE_DETAIL_POPUP',
      MOVIE_RESUME_POPUP: 'MOVIE_RESUME_POPUP',
    },
    LIST: {
      PAGE: 'LIST_PAGE',
      LIST_GRID_CONTAINER: 'LIST_GRID_CONTAINER',
      LIST_GRID: 'LIST_GRID',
      FILTER_LIST: 'FILTER_LIST',
      FILTER_LIST_WRAPPER: 'FILTER_LIST_WRAPPER',
      FILTER_BUTTON: 'FILTER_BUTTON',
      SORT_BUTTON: 'SORT_BUTTON',
      SORT_LIST: 'SORT_LIST',
      SORT_LIST_WRAPPER: 'SORT_LIST_WRAPPER',
      SELECTOR_LIST: 'SELECTOR_LIST',
      SELECTOR_LIST_WRAPPER: 'SELECTOR_LIST_WRAPPER',
      SELECTOR_BUTTON: 'SELECTOR_BUTTON',
    },
    LIVE: {
      PAGE: 'LIVE_PAGE',
      MINIGUIDE_CONTAINER: 'MINI_GUIDE',
      BTN_CONTAINER: 'BTN_CONTAINER',
      BTN_WATCHING: 'WATCHING_BUTTON',
      BTN_WATCH_NOW: 'WATCH_NOW_BUTTON',
      BTN_FULL_GUIDE: 'FULL_GUIDE_BUTTON',
      BTN_FAV_CHANNEL: 'FAVOURITE_CHANNEL',
      ENTITLEMENT_POPUP: 'MINI_GUIDE_ENTITLEMENT_POPUP',
    },
    SEARCH: {
      PAGE: 'SEARCH_PAGE',
      CONTAINER: 'SEARCH_CONTAINER',
      KEYBOARD: 'SEARCH_KEYBOARD',
      KEYBOARD_CONTAINER: 'SEARCH_KEYBOARD_CONTAINER',
      SIDE_CONTAINER: 'SEARCH_SIDE_CONTAINER',
      TRENDS_CAROUSEL: 'SEARCH_TRENDS_CAROUSEL',
      RESULTS: 'SEARCH_RESULTS',
    },
    PROFILE: {
      PAGE: 'PROFILE_PAGE',
      CONTAINER: 'PROFILE_CONTAINER',
      INFODIV: 'INFO_PANEL',
      PROFILE: 'PROFILE_TAB',
      LOGOUT: 'LOGOUT_TAB',
      LOGOUT_POPUP: 'LOGOUT_POPUP',
      LANGUAGE: 'LANGUAGE_TAB',
      KIDS_MODE: 'KIDS_MODE_TAB',
      ABOUT: 'ABOUT_TAB',
      HELP: 'HELP_TAB',
      LOGIN: 'LOGIN_BUTTON',
      LANGUAGE_BUTTONS: {
        ENGLISH: 'ENGLISH_LANGUAGE_BUTTON',
        MAORI: 'MAORI_LANGUAGE_BUTTON',
      },
      KIDS_MODE_BUTTONS: {
        ON: 'ON_KIDS_MODE_BUTTON',
        OFF: 'OFF_KIDS_MODE_BUTTON',
      },
      SCROLLABLEDIV: 'DIV--TEXT',
    },
    INFO: {
      PAGE: 'INFO_PAGE',
      CONTAINER: 'INFO_CONTAINER',
      ABOUT: 'ABOUT',
      TERMS: 'TERMS',
      HELP: 'HELP',
      CONTACT: 'CONTACT',
      LANGUAGE: 'LANGUAGE',
      LANGUAGE_GRID: 'LANGUAGE_GRID',
      SCROLLABLEDIV: 'DIV--TEXT',
    },
    EPG: {
      PAGE: 'EPG_PAGE',
      PROGRAM_GUIDE: 'PROGRAM_GUIDE',
      LOGIN_POPUP: 'EPG_LOGIN_POPUP',
      ENTITLEMENT_POPUP: 'EPG_ENTITLEMENT_POPUP',
      PROGRAM_PREVIEW: 'EPG_PROGRAM_PREVIEW',
      DAY_SELECTOR: 'DAY_SELECTOR',
    },
    PLAYER: {
      PAGE: 'PLAYER_PAGE',
      CONTROL: 'PLAYER_CONTROL',
      PLAY_PAUSE: 'PLAYER_PLAY_PAUSE',
      RW: 'PLAYER_RW',
      FF: 'PLAYER_FF',
      PREV: 'PLAYER_PREV',
      NEXT: 'PLAYER_NEXT',
      CC: 'PLAYER_CC',
      AUDIO: 'PLAYER_AUDIO',
      RESUME_POPUP: 'PLAYER_RESUME_POPUP',
      ERROR_POPUP: 'ERROR_POPUP',
      AUTO_PLAY: 'AUTO_PLAY',
    },
    AUTOPLAY: {
      CONTAINER: 'AUTO_PLAY_CONTAINER',
      WATCH_CREDITS: 'WATCH_CREDITS_BUTTON',
      PLAY_NEXT_EPIOSDE: 'PLAY_NEXT_EPISODE_BUTTON',
    },
    CATEGORY: {
      PAGE: 'CATEGORY_PAGE',
    },
  },
  PLAYER: {
    CONTROL: 'PLAYER_CONTROL',
    PLAY_PAUSE: 'PLAYER_PLAY_PAUSE',
    RW: 'PLAYER_RW',
    FF: 'PLAYER_FF',
    PREV: 'PLAYER_PREV',
    NEXT: 'PLAYER_NEXT',
    CC: 'PLAYER_CC',
    RESUME_POPUP: 'PLAYER_RESUME_POPUP',
  },
  POPUP: {
    CANCEL_POPUP: 'CANCEL_POPUP',
    OK_POPUP: 'OK_POPUP',
    GLOBAL_ERROR_POPUP_PAGE: 'GLOBAL_ERROR_POPUP_PAGE',
    GLOBAL_ERROR_POPUP: 'GLOBAL_ERROR_POPUP',
  },
  LOGIN: {
    PAGE: 'LOGIN_PAGE',
    CONTAINER: 'KEYBOARD_CONTAINER',
    KEYBOARD: 'KEYBOARD',
    USERNAME: 'USERNAME_BUTTON',
    PASSWORD: 'PASSWORD_BUTTON',
    LOGIN: 'LOGIN_BUTTON',
    PAIRCODE: 'PAIRCODE_BUTTON',
    SKIP: 'SKIP_BUTTON',
    FORGOT: 'FORGOT_BUTTON',
    CANCEL: 'CANCEL_BUTTON',
    CLOSEPOPUP: 'CLOSE_BUTTON',
    SIGN_UP_BUTTON: 'SIGN_UP_BUTTON',
    POPUP_LOGIN: 'POPUP_LOGIN',
    POPUP_SKIP_FOR_NOW: 'POPUP_SKIP_FOR_NOW',
  },
};

const getIds = (containers) => {
  const containerNavIds = [];
  containers.forEach((container) => {
    if (container.containerNavIds) {
      container.containerNavIds.forEach((subContainerNavId) => containerNavIds.push(subContainerNavId));
    } else {
      containerNavIds.push(container.id);
    }
  });
  return containerNavIds;
};

const getFirstAvailableContainer = (arrayWithContainers, arrayWithEmptyContainersIds) => {
  const finalResult = [];
  for (
    let i = 0;
    i < arrayWithContainers.length;
    // eslint-disable-next-line no-plusplus
    i++
  ) {
    if (!arrayWithEmptyContainersIds?.includes(arrayWithContainers[i].id)) {
      finalResult.push(arrayWithContainers[i]);
      break;
    }
  }

  return finalResult[0];
};
// It puts the focus on the first available element after the containers array has changed
const changeItemFocused = (containersData, emptyContainers, authContext, userBookmarks, continueWatching) => {
  const containersLengthWithoutContinueWatching = containersData.length - 1;
  const isAuthenticated = Boolean(authContext.isAuthenticated);
  const containersDataWithoutContinueWatching = [];
  const hasUserBookmarksItems = Boolean(userBookmarks?.length);
  let firstElementFocused = {};
  let firstContainer;

  containersData.forEach((container) => {
    if (container.template !== continueWatching) {
      containersDataWithoutContinueWatching.push(container);
    }
  });

  if (containersLengthWithoutContinueWatching > emptyContainers.length && !isAuthenticated) {
    firstContainer = getFirstAvailableContainer(containersDataWithoutContinueWatching, emptyContainers);
    firstElementFocused = firstContainer;
  } else if (isAuthenticated && containersData.length > emptyContainers.length) {
    if (hasUserBookmarksItems) {
      firstContainer = getFirstAvailableContainer(containersData, emptyContainers);
      firstElementFocused = firstContainer;
    } else {
      firstContainer = getFirstAvailableContainer(containersDataWithoutContinueWatching, emptyContainers);
      firstElementFocused = firstContainer;
    }
  }
  // the first container has a different structure depend if is a detail view or not
  const firstElementFocusedID = firstElementFocused?.id || firstElementFocused?.containerNavIds?.[0];

  return firstElementFocusedID;
};

export { navIdMap, getIds, changeItemFocused };
