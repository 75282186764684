import React, { useEffect } from 'react';

import { focusManager } from '@accedo/vdkweb-navigation';

import Popup from '#/components/Popup/Popup';
import useHistoryBack from '#/hooks/history/useHistoryBack';
import useApiError from '#/hooks/useApiError';
import { getTranslation } from '#/services/i18n';
import { navIdMap } from '#/utils/navigationHelper';

const NAV_IDS = navIdMap;

function ErrorPopup() {
  const { error, removeError, errorDetails = {} } = useApiError();
  const historyBack = useHistoryBack();
  const title = errorDetails?.title || getTranslation('loadErrorTitle');
  const description = errorDetails?.description || getTranslation('loadErrorMessage');
  const acceptText = errorDetails?.acceptText || '';
  const defaultOnAccept = () => {
    removeError();
    historyBack();
  };
  const onAccept = errorDetails.onAccept || defaultOnAccept;

  useEffect(() => {
    if (error) {
      focusManager.changeFocus(NAV_IDS.POPUP.GLOBAL_ERROR_POPUP);
    }
  }, [error, errorDetails]);

  if (!error) {
    return <div />;
  }

  return (
    <Popup
      nav={{
        id: NAV_IDS.POPUP.GLOBAL_ERROR_POPUP,
      }}
      title={<span>{title}</span>}
      content={<span>{description}</span>}
      isOpen={error}
      onAccept={onAccept}
      acceptText={acceptText}
    />
  );
}

export default ErrorPopup;
