/**
 * The client side entry where the app is being rendered
 * to the selected DOM element.
 *
 * Allowing for hot module replacement in DEVELOPMENT mode.
 */

// eslint-disable-next-line import/no-import-module-exports -- TODO: Automatically surpressed error. Resolve when you encounter this file!
import React from 'react';

// eslint-disable-next-line import/no-import-module-exports -- TODO: Automatically surpressed error. Resolve when you encounter this file!
import ReactDOM from 'react-dom';

// Import XDK4 Preflight loader for side effect only here.
// i.e. the client load the file, evaluate it, and eventually
// load xdk.config.js into the XDK4 config store.
// eslint-disable-next-line import/no-import-module-exports -- TODO: Automatically surpressed error. Resolve when you encounter this file!
import './preflight';
// eslint-disable-next-line import/no-import-module-exports -- TODO: Automatically surpressed error. Resolve when you encounter this file!
import App from '#/containers/App/App';

// The app render function which will mount the application's
// component tree to the DOM node with id 'root'.
const render = () => {
  const mountNode = document.getElementById('root');

  ReactDOM.render(<App />, mountNode);
};

// If we're in dev mode we'll allow for hot reloading of the
// application whenever any module below our 'routes' module is changing.
if (__LOCAL__ && module.hot) {
  module.hot.accept('#/config/routes', render);
}

// Render the component tree to the DOM
// unless it is a test run.
if (__TEST__) {
  module.exports = render;
} else {
  render();
}
