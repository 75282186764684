import React from 'react';

import { withFocus } from '@accedo/vdkweb-navigation';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { SearchIcon, UserIcon, SettingsIcon } from '#/components/Icons/Icons';

import style from './menuItem.scss';

const icons = {
  faSearch: ({ className }) => <SearchIcon className={className} />,
  faInfoCircle: ({ className }) => <SettingsIcon className={`${className} ${style.settings}`} />,
  faProfile: ({ className }) => <UserIcon className={`${className} ${style.user}`} />,
  faSettings: ({ className }) => <SettingsIcon className={`${className} ${style.settings}`} />,
};

/**
 * MenuItem Component
 * @param {Object} props Component Props
 * @param {Function} props.onClick Callback function to dispatch when
 * @param {Object} props.data The content to be displayed
 * @returns {React.ReactComponentElement} Component
 */
function MenuItem({ onClick, data, nav }) {
  const { pathname } = useLocation();
  const { faIcon, image, itemGroup } = data;
  const userImg = image ? <img className={style.profilePicture} src={image} alt="" /> : null;
  const isLoginButton = faIcon === 'faUser';
  const className =
    itemGroup === 'primary' || isLoginButton ? `${style.item} ${style.primary}` : `${style.item} ${style.secondary}`;

  const itemIcon = faIcon && icons[faIcon] ? icons[faIcon]({ className: `${className} ${style.icon}` }) : null;

  const itemContent = userImg || itemIcon || data.displayText;

  const lastSelectedItemStyle = pathname === data.route && !itemIcon ? style.lastSelectedButton : '';

  const itemClassName = `${className} ${nav.isFocused ? style.itemFocused : ''} ${
    isLoginButton ? style.loginButton : ''
  } ${lastSelectedItemStyle}`;

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events -- TODO: Automatically surpressed error. Resolve when you encounter this file!
    <div className={itemClassName} onMouseOver={nav.onMouseOver} onMouseOut={nav.onMouseOut}>
      <button
        // eslint-disable-next-line react/no-unknown-property
        nav={nav}
        onClick={onClick}
        // eslint-disable-next-line react/no-unknown-property
        theme={{
          buttonFocused: style.buttonFocused,
        }}
      >
        {itemContent}
      </button>
    </div>
  );
}

MenuItem.propTypes = {
  onClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  data: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  nav: PropTypes.object,
};

export default withFocus(MenuItem);
