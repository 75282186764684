import React, { useState, useCallback, useMemo, createContext } from 'react';

import PropTypes from 'prop-types';

export const APIErrorContext = createContext({
  error: null,
  errorDetails: {},
  addError: () => {},
  removeError: () => {},
});

function APIErrorProvider({ children }) {
  const [error, setError] = useState(false);
  const [errorDetails, setErrorDetails] = useState({});

  const removeError = useCallback(() => {
    setError(false);
    setErrorDetails({});
  }, []);

  const addError = useCallback((errorDetailObj = {}) => {
    setErrorDetails({ ...errorDetailObj });
    setError(true);
  }, []);

  const contextValue = useMemo(
    () => ({
      error,
      addError,
      removeError,
      errorDetails,
    }),
    [error, addError, removeError, errorDetails],
  );

  return <APIErrorContext.Provider value={contextValue}>{children}</APIErrorContext.Provider>;
}

APIErrorProvider.propTypes = {
  children: PropTypes.node,
};

export default APIErrorProvider;
