import React from 'react';

import { withFocus } from '@accedo/vdkweb-navigation';
import PropTypes from 'prop-types';

import categoryItemTheme from './elevateCategoryItem.scss';

function ElevateCategoryItem({ containerStyle, data, nav, width, height, onClick }) {
  const wrapperClassName = `${categoryItemTheme.wrapper} ${nav.isFocused ? categoryItemTheme.focused : ''}`;

  const wrapperStyle = {
    ...containerStyle,
    ...(width ? { width } : {}),
    ...(height ? { height } : {}),
  };

  return (
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus, jsx-a11y/mouse-events-have-key-events -- TODO: Automatically surpressed error. Resolve when you encounter this file!
    <div
      className={wrapperClassName}
      style={wrapperStyle}
      onMouseOver={nav.onMouseOver}
      onMouseOut={nav.onMouseOut}
      onClick={onClick}
      role="button"
    >
      <div className={categoryItemTheme.title}>{data.title}</div>
    </div>
  );
}

ElevateCategoryItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  containerStyle: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  data: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  height: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  width: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Automatically surpressed error. Resolve when you encounter this file!
  nav: PropTypes.object,
  onClick: PropTypes.func,
};

export default withFocus(ElevateCategoryItem);
