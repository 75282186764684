import { ITEM_SIZE } from '#/components/Item/itemHelper';
import { DETAIL_ROUTES } from '#/config/constants';
import getResolution from '#/utils/getResolution';

import { getCloudinaryImage } from '../utils';

/**
 * @module models/OVP
 */

/**
 * Item's size.
 * It's used to get the image's template Url with the correct size
 * @type {String}
 */
const ITEM_TYPE_PORTRAIT_SIZE = ITEM_SIZE.portrait;
const ITEM_TYPE_LANDSCAPE_SIZE = ITEM_SIZE.landscape;
const ITEM_TYPE_SQUEARE_SIZE = ITEM_SIZE.square;

/**
 * Full image size
 */
const { width: imageFullWidth } = getResolution();
/**
 *  Images type with their resolutions
 */
const imagesMap = {
  backdrop: {
    type: 'backdrop',
    width: ITEM_TYPE_LANDSCAPE_SIZE.width,
    height: ITEM_TYPE_LANDSCAPE_SIZE.height,
  },
  thumbnail: {
    type: 'thumbnail',
    width: 500,
    height: 282,
  },
  banner: {
    type: 'banner',
    width: 1120,
    height: 430,
  },
  hero: {
    type: 'backdrop',
    width: imageFullWidth,
    height: 864,
  },
  cover: {
    type: 'cover',
    width: ITEM_TYPE_PORTRAIT_SIZE.width,
    height: 530,
  },
  poster: {
    type: 'poster',
    width: ITEM_TYPE_PORTRAIT_SIZE.width,
    height: ITEM_TYPE_PORTRAIT_SIZE.height,
  },
  backdropClean: {
    type: 'backdrop_clean',
    width: ITEM_TYPE_LANDSCAPE_SIZE.width,
    height: ITEM_TYPE_LANDSCAPE_SIZE.height,
  },
  posterClean: {
    type: 'poster_clean',
    width: ITEM_TYPE_PORTRAIT_SIZE.width,
    height: ITEM_TYPE_PORTRAIT_SIZE.height,
  },
  logo: {
    type: 'logo',
    width: ITEM_TYPE_SQUEARE_SIZE.width,
    height: ITEM_TYPE_SQUEARE_SIZE.height,
  },
};

/**
 * @typedef {Object} Credit
 * @property {String} role Credit Role
 * @property {Array<String>} names Credit Names
 */

/**
 * @typedef {Object} Image
 * @property {String} type Type of image ("backdrop"|"poster"|"logo"|"thumbnail"|"cover"|"banner")
 * @property {String} url image url
 * @property {String} height image height
 * @property {String} width image width
 */

/**
 * Amount of stars to display as credits
 * @type {Number}
 */
const CREDITS_LENGTH = 5;

/**
 * @typedef {Object} Item
 * @property {String} id Item ID
 * @property {String} categoryId Item Category ID
 * @property {String} coverUrl Item Cover Image URL
 * @property {String} metadata Item Metadata
 * @property {String} title Item Title
 * @property {String} description Item Description
 * @property {Array<Credit>} credits Item Credits
 * @property {String} duration Item Duration
 * @property {String} videoUrl Item Video URL
 * @property {Array<Image>} images Array of the images of the content
 * @property {String} displayText Text to display for the Item
 * @property {String} category The primary vategory
 * @property {String} link Route to use in the application to navigate to its detail
 * @property {String} type Type of the content from "movie, "episode"
 * @property {Number} seasonCount the season number for the episode
 * @property {String} episodeCount the episode number in the season for the episode
 * @property {String} template Item template to present the Item on the carousel
 * @property {String} publishedDate Publication date
 * @property {String} categories List of categories separated by comma
 *
 */

/**
 * Item Model
 * @param {Object} rawItem ITem
 * @returns {Item} Item
 */
const Item = (rawItem) => {
  const {
    title,
    displaytext,
    description,
    id,
    type,
    credits,
    categories,
    images,
    publishedDate,
    contents = [{}],
    tvSeasonCount,
    episodeCount,
    template = '',
  } = rawItem;

  /**
   * Get the Item's id
   * @returns {String} ID
   */
  const getId = () => id;

  const getTypeFromOtherValues = () => {
    if (tvSeasonCount !== undefined || episodeCount !== undefined) {
      return 'episode';
    }
    return 'category';
  };

  const getType = () => type || getTypeFromOtherValues();

  /**
   * Get the first Item's category id
   * @returns {String} Category ID
   */
  const getPrimaryCategoryId = () => {
    if (!categories || categories.length === 0) {
      return null;
    }
    const [primaryCategory] = categories;

    return primaryCategory.id;
  };

  /**
   * Get the Item's categories
   * @returns {String} Categories
   */
  const getCategories = () => categories.map((category) => category.title).join(', ');

  /**
   * Get the Item's primary category
   * @returns {String} Category
   */
  const getPrimaryCategory = () => {
    if (!categories || categories.length === 0) {
      return null;
    }
    const [category] = categories;

    return category.title;
  };

  /**
   * Get the image resolution depending on the image type
   * @param {String} imageType getImages
   * @returns {Object} image size
   */
  const getImageTemplateUrlSize = (imageType) => {
    let imageSize = {};

    Object.keys(imagesMap).forEach((imgType) => {
      if (imageType === imgType) {
        imageSize = imagesMap[imgType];
      }
    });

    return imageSize;
  };

  /**
   * Get the first Item's cover url
   * @returns {String} Cover URL
   */
  const getCoverUrl = () => {
    if (!images || images.length === 0) {
      return null;
    }
    const [coverImage] = images;

    coverImage.url = getCloudinaryImage({
      imgUrl: coverImage.templateUrl,
      // Doing the image's template URL transform
      imgWidth: getImageTemplateUrlSize(coverImage.type).width,
      width: coverImage.width,
    });

    return coverImage.url;
  };

  /**
   * Get the publication date
   * @returns {String} Publication date
   */
  const getPublishedDate = () => publishedDate;

  /**
   * Get the Item's year production
   * @returns {Number} Year Production
   */
  const getYear = () => {
    const date = new Date();
    date.setTime(publishedDate);
    return date.getFullYear();
  };

  /**
   * Get the Item's duration
   * @returns {String} Duration
   */
  const getDuration = () => {
    const { duration } = contents[0];

    return `${Math.floor(duration / 1000 / 60)} mins`;
  };

  /**
   * Get the Item's metadata
   * @returns {String} Metadata
   */
  const getMetadata = () => {
    const metadata = `${getYear()} | ${getDuration()} | ${getCategories()}`;

    return metadata;
  };

  /**
   * Get the Item's title
   * @returns {String} Title
   */
  const getTitle = () => title;

  /**
   * Get the Item's display text
   * @returns {String} displayText
   */
  const getDisplayText = () => displaytext;

  /**
   * Get the Item's description
   * @returns {String} Description
   */
  const getDescription = () => description;

  /**
   * Get the Item's credits
   * @returns {Array<Object>} Credits
   */
  const getCredits = () => {
    if (!credits || credits.length === 0) {
      return null;
    }
    const [director, ...actors] = credits.slice(0, CREDITS_LENGTH);
    const itemCredtis = [];
    itemCredtis.push({ role: 'Director', names: [director.name] });
    itemCredtis.push({ role: 'Cast', names: actors.map((actor) => actor.name) });
    return itemCredtis;
  };

  /**
   * Get the Item's video url. Hardcoded to our hosted video
   * @returns {String} Video URL
   */
  const getVideoUrl = () =>
    // const { url } = contents[0];
    '//d3qnhznroa8hr5.cloudfront.net/videos/bbb_multiaudio.mp4'; // multiaudio

  const getImages = () => {
    if (!images || images.length === 0) {
      return null;
    }
    const mappedImages = Object.keys(imagesMap).map((imagesMapKey) => {
      const imageToMap = imagesMap[imagesMapKey];
      const filterImages = images.filter((image) => image.type === imageToMap.type);
      if (!filterImages || filterImages.length === 0) {
        return undefined;
      }
      const img = { ...filterImages[0] };

      // Doing the image's template URL transform
      const imageSize = getImageTemplateUrlSize(imagesMapKey);

      img.url = getCloudinaryImage({
        imgUrl: img.templateUrl,
        imgWidth: imageSize.width,
        width: imageToMap.width,
      });
      img.type = imagesMapKey;
      return img;
    });

    return mappedImages.filter((mappedImage) => mappedImage?.type);
  };

  const getEpisodeCount = () => episodeCount;

  const getSeasonsCount = () => tvSeasonCount;

  const getTemplate = () => template;

  const getLink = () => {
    const localType = getType();
    const path = localType ? DETAIL_ROUTES[localType] : DETAIL_ROUTES.tvshow;
    const link = path.replace(':item-id:', id);

    return link;
  };

  return {
    id: getId(),
    images: getImages(),
    title: getTitle(),
    displayText: getDisplayText(),
    categoryId: getPrimaryCategoryId(),
    category: getPrimaryCategory(),
    coverUrl: getCoverUrl(),
    link: getLink(),
    metadata: getMetadata(),
    description: getDescription(),
    credits: getCredits(),
    duration: getDuration(),
    videoUrl: getVideoUrl(),
    type: getType(),
    seasonCount: getSeasonsCount(),
    episodeCount: getEpisodeCount(),
    template: getTemplate(),
    publishedDate: getPublishedDate(),
    categories: getCategories(),
  };
};

export default Item;
